
import frisServer, { IFrisBaseResponse } from '../utils/frisServer'
import { AxiosResponse } from 'axios'
import store from '@/store/index'
import { IFrisListResponse, IFrisListData } from './api'
export interface IFrisDoctorData {
  userId: string
  roleId: number | null
  postSmallType: number | null
  organizationId: number | null
  name: string
}

export interface IFrisDoctorListResponse extends IFrisBaseResponse {
  doctor: IFrisDoctorData[]
}

export function fetchDoctorList (roleId?: number, organizationId?: string): Promise<AxiosResponse<IFrisDoctorListResponse>> {
  return frisServer({
    url: '/sys/user/getDoctor',
    method: 'post',
    data: {
      organizationId: organizationId || store.getters['app/getCurrentOrganizationId'],
      roleId
    }
  })
}

export interface IFrisTherapistListResponse extends IFrisBaseResponse {
  therapist: IFrisDoctorData[]
}

export function fetchTherapistList (data?: any): Promise<AxiosResponse<IFrisTherapistListResponse>> {
  return frisServer({
    url: '/sys/user/getTherapist',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}

export interface IFrisSmallTypeData {
  id: number
  name: string
  typeKey?: string
  num?: number
}

export interface IFrisSmallTypeListResponse extends IFrisBaseResponse {
  list: IFrisSmallTypeData[]
}

export function fetchPostSmallTypeList (): Promise<AxiosResponse<IFrisSmallTypeListResponse>> {
  return frisServer({
    url: '/sys/postsmalltype/selectSmallTypeComboBox',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId']
    }
  })
}

export interface IFrisPartData {
  createTime: unknown
  createUser: unknown
  id: number | string
  name: string
  organizationId: number
  sort: unknown
  status: number
  updateTime: string
  updateUser: unknown
}

export function fetchPartList (organizationId?: string): Promise<AxiosResponse<IFrisListResponse<IFrisPartData>>> {
  return frisServer({
    url: '/sys/part/list',
    method: 'post',
    data: {
      limit: 999999,
      organizationId: organizationId || store.getters['app/getCurrentOrganizationId'],
      page: 1
    }
  })
}

export interface IFrisOfficeData {
  addresss: unknown
  createTime: string
  createUser: string
  hisOfficeId: unknown
  id: number
  level: number
  name: string
  organizationId: number
  pid: number
  ppid: number
  pppid: number
  principal: unknown
  remark: unknown
  sort: unknown
  status: number
  type: unknown
  updateTime: string
  updateUser: unknown
}

export function fetchOfficeList (organizationId?: string): Promise<AxiosResponse<IFrisListResponse<IFrisOfficeData>>> {
  return frisServer({
    url: '/sys/office/list',
    method: 'post',
    data: {
      limit: 999999,
      organizationId: organizationId || store.getters['app/getCurrentOrganizationId'],
      page: 1
    }
  })
}


export interface IFrisOfficeTypeData {
  createTime: unknown
  createUser: unknown
  id: number
  name: string
  sort: unknown
  status: unknown
  updateTime: unknown
  updateUser: unknown
}

export function fetchOfficeTypeList (): Promise<AxiosResponse<IFrisListResponse<IFrisOfficeTypeData>>> {
  return frisServer({
    url: '/sys/officetpye/list',
    method: 'post',
    data: {
      page: 1,
      limit: 999999,
      organizationId: store.getters['app/getCurrentOrganizationId']
    }
  })
}

export interface IFrisUnitData {
  createTime: string
  createUser: unknown
  hisId: unknown
  id: string
  name: string
  organizationId: number
  sort: unknown
  status: unknown
  updateTime: string
  updateUser: unknown
}

export function fetchUnitList (organizationId?: string): Promise<AxiosResponse<IFrisListResponse<IFrisUnitData>>> {
  return frisServer({
    url: '/sys/unit/list',
    method: 'post',
    data: {
      limit: 999999,
      organizationId: organizationId || store.getters['app/getCurrentOrganizationId'],
      page: 1
    }
  })
}

export interface IFrisCategoryCommonData {
  id: number
  name: string
}

export function fetchProfessionList (organizationId?: string): Promise<AxiosResponse<IFrisListData<IFrisCategoryCommonData>>> {
  return frisServer({
    url: '/sys/profession/selectProfessionList',
    method: 'post',
    data: {
      organizationId: organizationId || store.getters['app/getCurrentOrganizationId'],
    }
  })
}

export function fetchRelationList (organizationId?: string): Promise<AxiosResponse<IFrisListData<IFrisCategoryCommonData>>> {
  return frisServer({
    url: '/sys/relation/selectRelationList',
    method: 'post',
    data: {
      organizationId: organizationId || store.getters['app/getCurrentOrganizationId'],
    }
  })
}

export interface IFrisProjectTemplateData {
  code: string
  content: string
  contentType: string
  count: number
  duration: number
  equipmentId: string
  equipmentName: string
  id: number | string
  isDelete: unknown
  money: number
  name: string
  organizationId: number
  postSmallType: number
  relevance: string
  shortLong: number
  time: string
  total: number
  totalAmount: number
  type: number
  unit: number
  unitName: unknown
  unitPrice: number
}

export interface IFrisProjectTemplateResponse {
  list: IFrisProjectTemplateData[]
}

export function fetchProjectTemplateList (organizationId?: string): Promise<AxiosResponse<IFrisProjectTemplateResponse>> {
  return frisServer({
    url: '/sys/projecttemplate/select',
    method: 'post',
    data: {
      organizationId: organizationId || store.getters['app/getCurrentOrganizationId'],
    }
  })
}

export function fetchProjectLinkageList (projectTemplateId?: string): Promise<AxiosResponse> {
  return frisServer({
    url: '/sys/comboprojectequipment/listBox',
    method: 'post',
    data: {
      projectTemplateId: projectTemplateId,
      organizationId: store.getters['app/getCurrentOrganizationId'],
    }
  })
}

export interface IFrisMethodData {
  content: string
  id: number
  name: string
  organizationId: number
  projectId: number
  projectName: string
}

export function fetchMethodList (): Promise<AxiosResponse<IFrisListResponse<IFrisMethodData>>> {
  return frisServer({
    url: '/sys/method/list',
    method: 'post',
    data: {
      limit: 999999,
      // organizationId: store.getters['app/getCurrentOrganizationId'],
      page: 1
    },
    timeout: 1000 * 300
  })
}


export interface IDepartmentOfficeListData {
  createTime: unknown
  createUser: unknown
  id: number
  name: string
  organizationId: number
  parentId: unknown
  sort: unknown
  status: unknown
  updateTime: unknown
  updateUser: unknown
}

export interface IDepartmentOfficeList extends IFrisBaseResponse {
  list: IDepartmentOfficeListData[]
}

export function departmentOfficeList (params: any): Promise<AxiosResponse<IDepartmentOfficeList>> {
  return frisServer({
    url: '/sys/office/list',
    method: 'post',
    data: {
      ...params
    }
  })
}

export function departmentList (params: any): Promise<AxiosResponse<IDepartmentOfficeList>> {
  return frisServer({
    url: '/sys/department/list',
    method: 'post',
    data: {
      ...params
    }
  })
}

export function dutyList (params: any): Promise<AxiosResponse<IDepartmentOfficeList>> {
  return frisServer({
    url: '/sys/duty/list',
    method: 'post',
    data: {
      ...params
    }
  })
}

export interface IFrisRankData {
  createTime: unknown
  createUser: unknown
  id: number
  name: string
  organizationId?: number
  parentId?: unknown
  sort: unknown
  status: unknown
  updateTime: unknown
  updateUser: unknown
}

export function fetchRankList (): Promise<AxiosResponse<IFrisListResponse<IFrisRankData>>> {
  return frisServer({
    url: '/sys/rank/list',
    method: 'post',
    data: {
      limit: 999999,
      organizationId: store.getters['app/getCurrentOrganizationId'],
      page: 1
    }
  })
}

export function fetchDepartmentList (): Promise<AxiosResponse<IFrisListResponse<IFrisRankData>>> {
  return frisServer({
    url: '/sys/department/list',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      page: 1,
      limit: 999999
    }
  })
}

export interface IFrisOrganizationData {
  code: unknown
  createTime: unknown
  createUser: unknown
  id: number
  name: string
  needSync: number
  sort: unknown
  status: unknown
  updateTime: unknown
  updateUser: unknown
}
export interface IFrisOrganizationList {
  page: number
  limit: number
}
export function fetchOrganizationList (params?: Partial<IFrisOrganizationList>): Promise<AxiosResponse<IFrisListResponse<IFrisOrganizationData>>> {
  return frisServer({
    url: '/sys/organization/list',
    method: 'get',
    params: {
      page: 1,
      limit: 999999,
      ...params
    }
  })
}

export function fetchPostTypeList (): Promise<AxiosResponse<IFrisListResponse<IFrisRankData>>> {
  return frisServer({
    url: '/sys/posttype/list',
    method: 'post',
    data: {
      limit: 999999,
      organizationId: store.getters['app/getCurrentOrganizationId'],
      page: 1
    }
  })
}

export function fetchDutyList (): Promise<AxiosResponse<IFrisListResponse<IFrisRankData>>> {
  return frisServer({
    url: '/sys/duty/list',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      page: 1,
      limit: 999999
    }
  })
}

export interface IFrisRoleData {
  createTime: string
  createUserId: string
  menuIdList: unknown
  remark: string
  organizationId?: number
  roleId: number
  roleName: string
  rupsRoleId: unknown
}



export function fetchRoleList (myInfo?: number): Promise<AxiosResponse<IFrisListResponse<IFrisRoleData>>> {
  return frisServer({
    url: `/sys/role/select`,
    method: 'get',
    params: {
      page: 1,
      limit: 999999,
      myInfo
    }
  })
}


export function posttypeList (params: any): Promise<AxiosResponse<IDepartmentOfficeList>> {
  return frisServer({
    url: '/sys/posttype/list',
    method: 'post',
    data: {
      ...params
    }
  })
}

export function getOrganization (): Promise<AxiosResponse<IDepartmentOfficeList>> {
  return frisServer({
    url: '/sys/organization/list',
    method: 'get',
    data: {}
  })
}


export interface IFrisIndexInfoResponse extends IFrisBaseResponse {
  asCount: number
  createTime: string
  doneTotal: number
  msgCount: number
  nonprogrammable: number
  notSpecified: number
  undoneTotal: number
}

export function fetchHomeInfo (): Promise<AxiosResponse<IFrisIndexInfoResponse>> {
  return frisServer({
    url: `/sys/index/info/${store.getters['app/getCurrentOrganizationId']}`,
    method: 'get',
  })
}

export interface IFrisPunchPunchCardParams {
  userId?: string,
  organizationId?: number,
  positionId: number
}

export interface IFrisPunchPunchCardData {
  code: unknown
  createTime: string
}

export function fetchPunchPunchCard (data: IFrisPunchPunchCardParams): Promise<AxiosResponse<IFrisPunchPunchCardData>> {
  return frisServer({
    url: '/sys/punch/punchCard',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      userId: store.state.user.userId,
      ...data,
    }
  })
}

export interface IFrisPunchPunchCardParams {
  organizationId?: number,
  treatmentTeamId: string,
  name: string
}


export function fetchCheckincheck (data: IFrisPunchPunchCardParams): Promise<AxiosResponse<IFrisPunchPunchCardData>> {
  return frisServer({
    url: '/sys/checkin/check',
    method: 'put',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data,
    }
  })
}

export interface IFrisPerformanceDoctorListResponse extends IFrisBaseResponse {
  doctor: IFrisDoctorData[]
}
// 仅全部医生列表
export function fetchPerformanceDoctorList (): Promise<AxiosResponse<IFrisPerformanceDoctorListResponse>> {
  return frisServer({
    url: '/sys/doctorPerformance/doctorList',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId']
    }
  })
}

export interface IFrisPunchPositionListParams {
  organizationId?: number
  therapistId?: string
}

export interface IFrisPunchPositionListData {
  list: IFrisPunchPositionListDataList[]
}

export interface IFrisPunchPositionListDataList {
  id: number
  name: string
}

export function fetchPunchPositionList (params: IFrisPunchPositionListParams): Promise<AxiosResponse<IFrisPunchPositionListData>> {
  return frisServer({
    url: '/sys/punch/positionList',
    method: 'get',
    params: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      therapistId: store.state.user.userId,
      ...params,
    }
  })
}
export interface IFrisSelectTreatmentTeamListParams {
  organizationId?: number
}

export interface IFrisSelectTreatmentTeamListData {
  defaultTeam: IFrisPunchPositionListDataList
  teamList: IFrisSelectTreatmentTeamListDataList[]
}

export interface IFrisSelectTreatmentTeamListDataList {
  id: number
  name: string
}

export function fetchSelectTreatmentTeamList (data: IFrisSelectTreatmentTeamListParams): Promise<AxiosResponse<IFrisPunchPositionListData>> {
  return frisServer({
    url: '/sys/checkin/selectTreatmentTeamList',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data,
    }
  })
}

export interface IFrisTodayCheckData {
  id: string
  name: string
}

export function fetchTodayCheck (data: IFrisSelectTreatmentTeamListParams): Promise<AxiosResponse<IFrisTodayCheckData>> {
  return frisServer({
    url: '/sys/checkin/todayCheck',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data,
    }
  })
}

export interface IFrisNeedCheckInData {
  data: boolean
}

export function fetchNeedCheckIn (data: IFrisSelectTreatmentTeamListParams): Promise<AxiosResponse<IFrisNeedCheckInData>> {
  return frisServer({
    url: '/sys/checkin/needCheckIn',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data,
    }
  })
}

// 仅全部治疗师列表
export function fetchQueryTherapistList (): Promise<AxiosResponse<IFrisPerformanceDoctorListResponse>> {
  return frisServer({
    url: '/sys/therapistPerformance/queryTherapistList',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId']
    }
  })
}