import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-7d0ffa60"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "fr-radio"
};
const _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tag = _resolveComponent("el-tag");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.list && _ctx.list.length ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList(_ctx.list, item => {
    return _openBlock(), _createBlock(_component_el_tag, {
      key: item.value,
      class: _normalizeClass(['fr-radio-tag', _ctx.disabled && 'disabled']),
      type: item.value == _ctx.active ? '' : 'info',
      effect: item.value == _ctx.active ? 'dark' : 'plain',
      onClick: $event => _ctx.changeActive(item.value)
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label), 1)]),
      _: 2
    }, 1032, ["class", "type", "effect", "onClick"]);
  }), 128)) : (_openBlock(), _createElementBlock("span", _hoisted_2, "-"))]);
}