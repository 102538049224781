<template>
  <div class="put-on-records">
    <p class="AQ-top">
      <span>
        {{ todayYear }}
      </span>
      <span>
        上海傅利叶智能科技有限公司
      </span>
      <span>
        版权所有
      </span>
    </p>
    <p class="AQ-bottom">
      <span>
        沪ICP备
      </span>
      <span class="ICP-number">
        16009987
      </span>
      <span>
        号
      </span>
      <img
        class="national_emblem"
        src="@/assets/national_emblem.png"
        alt="国徽"
      >
      <span>
        沪公网安备
      </span>
      <span class="AnBei-number">
        31011502015345
      </span>
      <span>
        号
      </span>
    </p>
  </div>
</template>
<script>

import { ref, onMounted} from "vue"
import dayjs from 'dayjs'
import { useRouter } from 'vue-router'

export default {
  setup () {
    const todayYear = ref('')
    const router = useRouter()
    onMounted(() => {
      todayYear.value = dayjs().format('YYYY')
    })
    return {
      todayYear,
      router
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../styles/var.scss';
.put-on-records {
  width: 100%;
  height: 40px;
  padding-bottom: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 16px;
  line-height: 17px;
  text-align: center;
  font-size: $--font-size-extra-small;
  color: $--font-color-grey-5;
  .AQ-top {
    margin-bottom: 6px;
  }
  .AQ-bottom {
    display: flex;
    align-items: center;
    .ICP-number{
      margin: 0 0px 0 2px;
    }
    .national_emblem {
      width: 18px;
      margin: 0 5px 0 8px;
    }
    .AnBei-number {
      margin: 0 1px 0 4px;
    }
  }
  
}
</style>