
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SubmitBox',
  props: {
    title: {
      type: String,
    },
    subtitle:{
      type: String,
    },
    visible: {
      type: Boolean,
      default: false
    },
    destroyOnClose: {
      type: Boolean,
      default: false
    },
    warningVisible:{
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible', 'submit'],
  setup (props, context) {
    const hide = () => {
      context.emit('update:visible', false)
    }
    const submit = () => {
      context.emit('submit')
    }

    return {
      hide,
      submit,
    }
  }
})
