import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_fr_radio = _resolveComponent("fr-radio");

  const _component_fr_form_item = _resolveComponent("fr-form-item");

  const _component_form_item = _resolveComponent("form-item");

  return _openBlock(), _createBlock(_component_form_item, {
    props: _ctx.props
  }, {
    default: _withCtx(() => [_createVNode(_component_fr_form_item, {
      mode: _ctx.mode,
      content: _ctx.labelValue
    }, {
      default: _withCtx(() => [_createVNode(_component_fr_radio, _mergeProps({
        key: _ctx.list,
        active: _ctx.modelActive,
        list: _ctx.list
      }, { ..._ctx.props.radioProps,
        disabled: _ctx.isFormDisabled || _ctx.props.radioProps && _ctx.props.radioProps.disabled
      }, {
        "onUpdate:active": _ctx.updateActiveHandle
      }), null, 16, ["active", "list", "onUpdate:active"])]),
      _: 1
    }, 8, ["mode", "content"])]),
    _: 1
  }, 8, ["props"]);
}