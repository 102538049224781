import { getStrlen } from './util'
import dayjs from 'dayjs'

export function identityNumberTest (number: string) {
  if (!number || number.length <= 0) return false
  const code = number
  // 身份证号前两位代表区域
  const city = {
    '11': '北京',
    '12': '天津',
    '13': '河北',
    '14': '山西',
    '15': '内蒙古',
    '21': '辽宁',
    '22': '吉林',
    '23': '黑龙江 ',
    '31': '上海',
    '32': '江苏',
    '33': '浙江',
    '34': '安徽',
    '35': '福建',
    '36': '江西',
    '37': '山东',
    '41': '河南',
    '42': '湖北 ',
    '43': '湖南',
    '44': '广东',
    '45': '广西',
    '46': '海南',
    '50': '重庆',
    '51': '四川',
    '52': '贵州',
    '53': '云南',
    '54': '西藏 ',
    '61': '陕西',
    '62': '甘肃',
    '63': '青海',
    '64': '宁夏',
    '65': '新疆',
    '71': '台湾',
    '81': '香港',
    '82': '澳门',
    '91': '国外 ',
  }
  const idCardReg = /^[1-9]\d{5}(19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i // 身份证格式正则表达式
  let errorMessage = '' // 错误提示信息
  let isPass = true // 身份证验证是否通过（true通过、false未通过）

  // 如果身份证不满足格式正则表达式
  if (!code) {
    errorMessage = '请输入身份证号码'
    isPass = false
  }
  if (!code.match(idCardReg)) {
    errorMessage = '身份证规则不符合'
    isPass = false
  }
  if (!city[code.substr(0, 2) as keyof typeof city]) {
    // 区域数组中不包含需验证的身份证前两位
    errorMessage = '身份证地区有误'
    isPass = false
  }
  if (code.length === 18) {
    // 18位身份证需要验证最后一位校验位
    const _code = code.split('')
    // ∑(ai×Wi)(mod 11)
    // 加权因子
    const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
    // 校验位
    const parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]
    let sum = 0
    let ai = 0
    let wi = 0
    for (let i = 0; i < 17; i++) {
      ai = parseInt(_code[i])
      wi = factor[i]
      sum += ai * wi // 开始计算并相加
    }
		if (isNaN(sum)) {
			errorMessage = '证件号错误'
			isPass = false
		} else {
			const last = parity[sum % 11] // 求余
			if (last.toString() !== _code[17]) {
				errorMessage = '身份证最后一位验证不通过'
				isPass = false
			}
		}
  } else {
		errorMessage = '请输入18位有效身份证号'
		isPass = false
	}
  if (errorMessage) {
    console.log('errorMessage', errorMessage)
  }
  return isPass
}

export function isLaterThenDoday (date?: string | number | Date | dayjs.Dayjs | null | undefined) {
	const today = dayjs(new Date().getTime() + 24 * 3600 * 1000).format('YYYY/MM/DD')
	return dayjs(date).isBefore(today)
}

export function isLaterThenDodayValidate (rule: string, val: string, cb: any) {
	if (!val) {
		cb()
		return
	}
	const isPass = isLaterThenDoday(val)
	if (!isPass) {
		cb(new Error('日期不可晚于今天'))
	} else {
		cb()
	}
}

export function identityValidate (rule: string, val: string, cb: any) {
	if (!val) {
		cb()
		return
	}
	const isPass = identityNumberTest(val)
	if (!isPass) {
		cb(new Error('证件号错误'))
	} else {
		cb()
	}
}

export function nameValidate (rule: any, value: string, callback: any) {
	if (!value) {
		callback()
		return
	}
	const len = getStrlen(value)
	if (len > 64) {
		callback(new Error('最大64字符'))
	} else {
		const strLen = value.match(/[\u4E00-\u9FA5]/g)
		if (strLen && strLen.length > 21) {
			callback(new Error('最多21个汉字'))
		} else {
			callback()
		}
	}
}

export function freeClinicTextValidate (rule: any, value: string, callback: any) {
	if (!value) {
		callback()
		return
	}
	const len = getStrlen(value)
	if (len > 50) {
		callback(new Error('最大50字符'))
	} else {
		const strLen = value.match(/[\u4E00-\u9FA5]/g)
		if (strLen && strLen.length > 25) {
			callback(new Error('最多25个汉字'))
		} else {
			callback()
		}
	}
}

export function addressTextValidate (rule: any, value: string, callback: any) {
	if (!value) {
		callback()
		return
	}
	const len = getStrlen(value)
	if (len > 60) {
		callback(new Error('最大60字符'))
	} else {
		const strLen = value.match(/[\u4E00-\u9FA5]/g)
		if (strLen && strLen.length > 30) {
			callback(new Error('最多30个汉字'))
		} else {
			callback()
		}
	}
}

export function freeClinicTextareaValidate (rule: any, value: string, callback: any) {
	if (!value) {
		callback()
		return
	}
	const len = getStrlen(value)
	if (len > 100) {
		callback(new Error('最大100字符'))
	} else {
		const strLen = value.match(/[\u4E00-\u9FA5]/g)
		if (strLen && strLen.length > 50) {
			callback(new Error('最多50个汉字'))
		} else {
			callback()
		}
	}
}

export function remarkValidate (rule: any, value: string, callback: any) {
	if (!value) {
		callback()
		return
	}

	if (value.length > 20) {
		callback(new Error('最多20个字符'))
		} else {
			callback()
		}
}

export function leasePriceValidate (rule: any, value: string, callback: any) {
	if (!value) {
		callback()
	} else if (isNaN(+value)) {
		callback(new Error('请输入数字'))
	} else if (+value <= 0) {
		callback(new Error('请输入正数'))
	} else if (!/^\d+(\.\d{1,2})?$/.test(String(value))) {
		callback(new Error('数字，保留两位小数'))
	} else {
		const reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/
    if (reg.test(String(value)) && (Number(value) > 99999.99 || Number(value) === 0)) {
			callback(new Error('请输入0.01~99999.99之间的数字'))
    } else {
			callback()
		}
	}
}

export function priceValidate (rule: any, value: string, callback: any) {
	if (!value) {
		callback()
	} else if (isNaN(+value)) {
		callback(new Error('请输入数字'))
	} else if (+value <= 0) {
		callback(new Error('请输入正数'))
	} else if (!/^\d+(\.\d{1,2})?$/.test(String(value))) {
		callback(new Error('数字，保留两位小数'))
	} else {
		callback()
	}
}


export function memberPriceValidate (rule: any, value: string, callback: any) {
	if (!value) {
		callback()
	} else if (isNaN(+value)) {
		callback(new Error('请输入数字'))
	} else if (+value <= 0) {
		callback(new Error('请输入正数'))
	} else if (!/^\d+(\.\d{1,2})?$/.test(String(value))) {
		callback(new Error('数字，保留两位小数'))
	} else if (+value > 100000 || +value < 0) {
		callback(new Error('请输入正确格式(0.01-100000.00)'))
		return
	} {
		callback()
	}
}

export function qrCodeCardValidate (rule: any, value: string, callback: any) {
	if (!value) {
		callback()
	} else if (isNaN(+value)) {
		callback(new Error('请输入数字'))
	} else if (+value <= 0) {
		callback(new Error('请输入正数'))
	} else {
		callback()
	}
}

export function heightValidate (rule: any, value: string, callback: any) {
	if (!value) {
		callback()
		return
	}
	if (+value > 300 || +value < 0 || !String(value).match(/^\d+$/)) {
		callback(new Error('0-300'))
		return
	}
	callback()
}

export function isPositiveInteger (value?: string | number) {
	if (!value) return false
	return Number.isInteger(+value) && +value > 0
}

export function positiveInteger (rule: any, value: string, callback: any) {
	if (value === undefined || value === '') {
		callback()
		return
	}
	if (isPositiveInteger(value)) {
		callback()
		return
	}
	
	callback(new Error('请填写正整数'))
}

export function weightValidate (rule: any, value: string, callback: any) {
	if (!value) {
		callback()
		return
	}
	if (+value > 1000 || +value < 0 || isNaN(+value)) {
		callback(new Error('0-1000'))
		return
	}
	callback()
}

export function ageValidate (rule: any, value: any, callback: any) {
	if (!value) {
		callback()
		return
	}
	if (+value > 200 || +value < 0 || !String(value).match(/^\d+$/) || (value.length > 1 && value == 0)) {
		callback(new Error('0-200'))
		return
	}
	callback()
}
export function usernameValidate (rule: any, value: any, callback: any) {
	if (!value) {
		callback(new Error('请填写手机号或邮箱'))
		return
	}
	const isDontMatchPhone = validateInfo.phone.some(item => !item.pattern.test(value))
	const isDontMatchEmail = validateInfo.email.some(item => !item.pattern.test(value))
	if (isDontMatchEmail && isDontMatchPhone) {
		callback(new Error('请填写手机号或邮箱'))
		return
	}
	callback()
}


export function passwordValidate (rule: any, value: any, callback: any) {
	if (!value) {
		callback()
		return
	}
	if (String(value).length < 8 || String(value).length > 20) {
		callback(new Error('密码长度8-20位，包含数字、字母、符号'))
		return
	} else if (!String(value).match(/^[a-zA-Z\d\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]+$/)) {
		callback(new Error('密码长度8-20位，包含数字、字母、符号'))
		return
	}  else if (!String(value).match(/(?=.*[a-zA-Z])(?=.*\d)(?=.*[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])/)) {
		callback(new Error('密码长度8-20位，包含数字、字母、符号'))
		return
	} 
	callback()
}

export function coefficientValidate (rule: any, value: string, callback: any) {
	if (!value) {
		callback()
	} else if (isNaN(+value)) {
		callback(new Error('请输入数字'))
	} else if (+value < 0) {
		callback(new Error('请输入正数'))
	} else if (!/^\d+(\.\d{1,2})?$/.test(String(value))) {
		callback(new Error('数字，保留两位小数'))
	} else if (+value > 100 || +value < 0) {
		callback(new Error('请输入正确格式(0.00-100.00)'))
		return
	} {
		callback()
	}
}

export const conutReg = /(^1\/[237]$)|(^[1-9][0-9]{0,3}$)/

export const validateInfo = {
	birthday: [
		{
			validator: isLaterThenDodayValidate, trigger: 'change'
		}
	],
	positiveInteger: [
		{ validator: positiveInteger, trigger: 'blur' }
	],
	// 是否为数字
	number: [
		{ pattern: /^\d+$/, message: '请输入数字', trigger: 'blur' }
	],
	// 手机号（长度最大32个 数字 +号 -号 ,号）
	phone: [
		// { pattern: /^[\d|,|+|-]{0,32}$/, message: '请填写正确的手机号', trigger: 'blur' }
		{ pattern: /^1(([378][\d])|(4[01456789])|([59][012356789])|(6[2567]))[\d]{8}$/, message: '请填写正确的手机号', trigger: 'blur' }
	],
	// 手机校验3（只允许输入数字、特殊符号）
	landlinePhone: [
		{ pattern: /^[^\u4E00-\u9FA5a-zA-Z]+$/, message: '请填写正确的手机号', trigger: 'blur' }
	],

	// 邮箱 （长度最大64 ascii@253 ascii）
	email: [
		// eslint-disable-next-line no-control-regex
		{ pattern: /^[\x00-\xff]+@[\x00-\xff]+$/, message: '请填写正确的邮箱', trigger: 'blur' },
		// eslint-disable-next-line no-control-regex
		{ pattern: /^[\x00-\xff]{0,64}$/, message: '请填写64位以内字符', trigger: 'blur' }
	],
	// 姓名/昵称 （长度最大 64字节：21汉字 或 64 ascii）
	name: [
		{ validator: nameValidate, trigger: 'blur' }
		// {
		// 	// eslint-disable-next-line no-control-regex
		// 	pattern: /^[\x00-\xff]{0,64}$/, message: '请填写名称，最大64个字符', trigger: 'blur' }, {
		// 	pattern: /^[\u4E00-\u9FA5]{0,21}$/, message: '请填写名称，汉字最大21个字符', trigger: 'blur'
		// }
	],
	// 身高 （值区域：0 - 300 cm（默认0）
	height: [
		{ validator: heightValidate, trigger: 'blur' },
		{ pattern: /^\d{0,3}$/, message: '请填写数字', trigger: 'blur' }
	],
	// 体重 （值区域：0 - 1000.0 kg（默认0）
	weight: [
		{ validator: weightValidate, trigger: 'blur' },
		{ pattern: /^\d{0,4}\.?\d$/, message: '请填写数字(保留一位小数)', trigger: 'blur' }
	],
	// 年龄 （值区域：0 - 200（默认60）
	age: [
		{ validator: ageValidate, trigger: 'blur' },
		{ pattern: /^\d{0,3}$/, message: '请填写数字', trigger: 'blur' }
	],
	identity: [
		{ validator: identityValidate, trigger: 'blur' }
	],
	password: [
		// { validator: ageValidate, trigger: 'blur' },
		// { pattern: /^\w{4,20}$/, message: '请填写4-20位密码(字母、数字、下划线)', trigger: 'blur' },
		{ validator: passwordValidate, trigger: 'blur' }
	],
	username: [
		{ validator: usernameValidate, trigger: 'blur' }
	],
	price: [
		{ validator: priceValidate, trigger: 'blur' }
	],
	count: [
		{ pattern: /^[1-9][0-9]{0,3}$/, message: '请填写正整数', trigger: 'blur' }
	],
	duration: [
		{ pattern: /^[1-9][0-9]{0,3}$/, message: '请填写正整数(分钟)', trigger: 'blur' }
	],
	requency: [ // 频次
		{ pattern: conutReg, message: '请填写正整数、1/2、1/3、1/7', trigger: 'blur' }
	],
	socialSecurityNumber: [
		{ pattern: /^.{1,50}$/, message: '请填写正确的社保号码', trigger: 'blur' }
	],
	hourAndMinute:[
		{ pattern: /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/, message: '请输入正确格式(00:00)', trigger: 'blur' }
	],
	timeLong:[
		{ pattern: /^([0-9]|[1-5][0-9]|60)$/, message: '请输入正确格式(0-60)', trigger: 'blur' }
	],
	timeRest:[
		{ pattern: /^([0-9]|[1-2][0-9]|30)$/, message: '请输入正确格式(0-30)', trigger: 'blur' }
	],
	sort:[
		{ pattern: /^([1-9]|[1-9]\d+)$/, message: '请输入正整数', trigger: 'blur' }
	],
	projectRecord:[
		{ pattern: /^\d+$/, message: '请输入数字', trigger: 'blur' },
		{ pattern: /^([1-9]|[1-9][0-9]|100)$/, message: '请输入正确格式(1-100)', trigger: 'blur' }
	],
	coefficient:[
		{ validator: coefficientValidate, trigger: 'blur' }
	],
	remark:[
		{ validator: remarkValidate, trigger: 'blur' }
	],
	leasePrice:[
		{ validator: leasePriceValidate, trigger: 'blur' }
	],
	discount:[
		{ pattern: /^[0-9]{1}(\.[0-9])?$/, message: '请输入正确格式(0.1-9.9)', trigger: 'blur' }
	],
	memberPrice:[
		{ validator: memberPriceValidate, trigger: 'blur' },
	],
	qrCodeCard:[
		{ validator: qrCodeCardValidate, trigger: 'blur' },
	],
	cardNumber:[
		{ pattern: /^[0-9a-zA-Z]{0,20}$/, message: '请输入20位字母或数字', trigger: 'blur' }
	],
	freeClinicText: [
		{ validator: freeClinicTextValidate, trigger: 'blur' }
	],
	freeClinicTextarea: [
		{ validator: freeClinicTextareaValidate, trigger: 'blur' }
	],
	addressTextarea: [
		{ validator: addressTextValidate, trigger: 'blur' }
	],
}