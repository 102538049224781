import storage from 'store';
import { fetchDoctorList, fetchPostSmallTypeList, fetchPartList, fetchOfficeList, fetchUnitList, fetchProfessionList, fetchProjectTemplateList, fetchTherapistList, fetchMethodList, fetchRankList, fetchDepartmentList, fetchOrganizationList, fetchPostTypeList, fetchDutyList, fetchRoleList, fetchRelationList, fetchOfficeTypeList } from '@/api/organization';
import { fetchCaseTemplate, fetchPrescriptionTemplateList } from '@/api/outpatient';
import { fetchEquipmentList } from '@/api/order';
const organization = {
  namespaced: true,
  state: {
    doctorList: [],
    realDoctorList: [],
    therapistList: [],
    postSmallTypeList: [],
    partList: [],
    officeList: [],
    officeTypeList: [],
    unitList: [],
    professionList: [],
    projectTemplateList: [],
    methodList: [],
    rankList: [],
    departmentList: [],
    organizationList: [],
    postTypeList: [],
    dutyList: [],
    roleList: [],
    equipmentList: [],
    orgRelationList: [],
    caseTemplateList: [],
    prescriptionTemplateList: []
  },
  mutations: {
    changeDoctorList(state, list) {
      state.doctorList = list;
    },

    changeRealDoctorList(state, list) {
      state.realDoctorList = list;
    },

    changeTherapistList(state, list) {
      state.therapistList = list;
    },

    changePostSmallTypeList(state, list) {
      state.postSmallTypeList = list;
    },

    changePartList(state, list) {
      state.partList = list;
    },

    changeOfficeList(state, list) {
      state.officeList = list;
    },

    changeOfficeTypeList(state, list) {
      state.officeTypeList = list;
    },

    changeUnitList(state, list) {
      state.unitList = list;
    },

    changeProfessionList(state, list) {
      state.professionList = list;
    },

    changeProjectTemplateList(state, list) {
      state.projectTemplateList = list;
    },

    changeMethodList(state, list) {
      console.log('评定方法', list);
      state.methodList = list;
    },

    changeRankList(state, list) {
      state.rankList = list;
    },

    changeDepartmentList(state, list) {
      state.departmentList = list;
    },

    changeOrganizationList(state, list) {
      state.organizationList = list;
    },

    changePostTypeList(state, list) {
      state.postTypeList = list;
    },

    changeDutyList(state, list) {
      state.dutyList = list;
    },

    changeRoleList(state, list) {
      state.roleList = list;
    },

    changeEquipmentList(state, list) {
      state.equipmentList = list;
    },

    changeOrgRelationList(state, list) {
      state.orgRelationList = list;
    },

    changeCaseTemplateList(state, list) {
      state.caseTemplateList = list;
    },

    changePrescriptionTemplateList(state, list) {
      state.prescriptionTemplateList = list;
    }

  },
  actions: {
    async fetchDoctorList(context) {
      try {
        const {
          data
        } = await fetchDoctorList();
        context.commit('changeDoctorList', data.doctor);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchRealDoctorList(context) {
      try {
        const {
          data
        } = await fetchDoctorList(10);
        context.commit('changeRealDoctorList', data.doctor);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchTherapistList(context, postSmallType) {
      try {
        const {
          data
        } = await fetchTherapistList({
          postSmallType: postSmallType
        });
        console.log(200);
        context.commit('changeTherapistList', data.therapist);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchPostSmallTypeList(context) {
      try {
        const {
          data
        } = await fetchPostSmallTypeList();
        context.commit('changePostSmallTypeList', data.list);
        storage.set('changePostSmallTypeList', data.list);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchPartList(context) {
      try {
        const {
          data
        } = await fetchPartList();
        context.commit('changePartList', data.page.list);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchOrgOfficeList(context) {
      try {
        const {
          data
        } = await fetchOfficeList();
        context.commit('changeOfficeList', data.list);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchOrgOfficeTypeList(context) {
      try {
        const {
          data
        } = await fetchOfficeTypeList();
        context.commit('changeOfficeTypeList', data.page.list);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchOrgUnitList(context) {
      try {
        const {
          data
        } = await fetchUnitList();
        context.commit('changeUnitList', data.page.list);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchOrgProfessionList(context) {
      try {
        const {
          data
        } = await fetchProfessionList();
        context.commit('changeProfessionList', data.list);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchProjectTemplateList(context) {
      try {
        const {
          data
        } = await fetchProjectTemplateList();
        context.commit('changeProjectTemplateList', data.list);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchMethodList(context) {
      try {
        const {
          data
        } = await fetchMethodList();
        context.commit('changeMethodList', data.page.list);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchRankList(context) {
      try {
        const {
          data
        } = await fetchRankList();
        context.commit('changeRankList', data.page.list);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchDepartmentList(context) {
      try {
        const {
          data
        } = await fetchDepartmentList();
        context.commit('changeDepartmentList', data.page.list);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchOrganizationList(context) {
      try {
        const {
          data
        } = await fetchOrganizationList();
        context.commit('changeOrganizationList', data.page.list);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchPostTypeList(context) {
      try {
        const {
          data
        } = await fetchPostTypeList();
        context.commit('changePostTypeList', data.page.list);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchDutyList(context) {
      try {
        const {
          data
        } = await fetchDutyList();
        context.commit('changeDutyList', data.page.list);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchRoleList(context) {
      try {
        const {
          data
        } = await fetchRoleList();
        context.commit('changeRoleList', data.list);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchEquipmentList(context) {
      try {
        const {
          data
        } = await fetchEquipmentList('启用');
        context.commit('changeEquipmentList', data.list);
      } catch (err) {
        console.error(err);
      }
    },

    async fetchOrgRelationList(context) {
      try {
        const {
          data
        } = await fetchRelationList();
        context.commit('changeOrgRelationList', data.list);
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async fetchCaseTemplateList(context) {
      try {
        const {
          data
        } = await fetchCaseTemplate({
          name: '',
          page: 1,
          limit: 9999
        });
        context.commit('changeCaseTemplateList', data.page.list);
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async fetchPrescriptionTemplateList(context) {
      try {
        const {
          data
        } = await fetchPrescriptionTemplateList({
          name: '',
          page: 1,
          limit: 9999
        });
        context.commit('changePrescriptionTemplateList', data.page.list);
      } catch (err) {
        return Promise.reject(err);
      }
    }

  },
  getters: {
    getDoctorByRoles(state) {
      return roles => {
        return state.doctorList.filter(doctor => {
          if (Array.isArray(roles)) {
            return roles.some(role => String(role) === String(doctor.roleId));
          } else {
            return String(doctor.roleId) === String(roles);
          }
        });
      };
    },

    getPartListFormOptions(state) {
      return {
        list: {
          list: state.partList,
          value: 'id',
          label: 'name'
        }
      };
    },

    getOfficeListFormOptions(state) {
      return {
        list: {
          list: state.officeList,
          value: 'id',
          label: 'name'
        }
      };
    },

    getPostSmallTypeListFormOptions(state) {
      return {
        list: {
          list: state.postSmallTypeList,
          value: 'id',
          label: 'name'
        }
      };
    },

    getTherapistListFormOptions(state) {
      return postSmallType => {
        let list = state.therapistList;

        if (postSmallType) {
          list = list.filter(item => item.postSmallType === +postSmallType);
        }

        return {
          list: {
            list: list,
            value: 'userId',
            label: 'name'
          }
        };
      };
    },

    getUtilList: state => {
      return val => {
        if (!val) return '';
        const active = state.unitList.find(item => item.id === val);
        if (active) return active.name;
        return '';
      };
    }
  }
};
export default organization;