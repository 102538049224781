
import { defineComponent, provide, toRefs, readonly } from 'vue'

export default defineComponent({
  name: 'FrProvider',
  props: {
    frFormItemType: { type: String }
  },
  setup (props) {
    const { frFormItemType } = toRefs(props)

    provide('frFormItemType', readonly(frFormItemType))
  }
})
