import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_form_item = _resolveComponent("el-form-item");

  const _component_el_col = _resolveComponent("el-col");

  return _openBlock(), _createBlock(_component_el_col, _normalizeProps(_guardReactiveProps(_ctx.colProps)), {
    default: _withCtx(() => [_createVNode(_component_el_form_item, _mergeProps({
      ref: el => _ctx.formItemRef = el,
      label: _ctx.props.label,
      prop: _ctx.props.prop
    }, _ctx.formatedFormProps), {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
      _: 3
    }, 16, ["label", "prop"])]),
    _: 3
  }, 16);
}