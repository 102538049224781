import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, toHandlers as _toHandlers, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");

  const _component_el_select = _resolveComponent("el-select");

  return _openBlock(), _createBlock(_component_el_select, _mergeProps({
    key: _ctx.props.list.length,
    clearable: "",
    "model-value": _ctx.formatedModelActive
  }, _ctx.componentSelectProps, {
    placeholder: _ctx.computedPlaceholder
  }, _toHandlers(_ctx.selectEvents)), {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredList, item => {
      return _openBlock(), _createBlock(_component_el_option, _mergeProps({
        key: item && item[_ctx.props.list.value],
        label: item && item[_ctx.props.list.label],
        value: item && item[_ctx.props.list.value]
      }, _ctx.props.optionProps), null, 16, ["label", "value"]);
    }), 128))]),
    _: 1
  }, 16, ["model-value", "placeholder"]);
}