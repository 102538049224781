
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FrFormGroupTitle',
  props: {
    title: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  }
})
