import { Module } from 'vuex'
import { GlobalProps } from './'
import { MQUrl } from '@/config'
import { Client } from '@stomp/stompjs'
import { fetchEmailUnread } from '@/api/email'

export interface MessageData { id: number, userId: string, organizationId: string }
export interface MessageProps {
  message?: MessageData
  isConnect: boolean
  count?: number
  client?: Client
}

const message: Module<MessageProps, GlobalProps> = {
  namespaced: true,
  state: {
    message: undefined,
    isConnect: false,
    client: undefined,
    count: 0
  },
  mutations: {
    changeMsg (state, msg: MessageData) {
      state.message = msg
    },
    changeConnectState (state, isConnect) {
      state.isConnect = isConnect
    },
    changeCount (state, count) {
      state.count = count
    },
    changeClient (state, client?: Client) {
      state.client = client
    }
  },
  actions: {
    connectMQ (context) {
      // 如果之前有链接 先把链接关了
      if (context.state.client) {
        context.state.client.deactivate()
      }
      const client = new Client({
        brokerURL: MQUrl,
        connectHeaders: {
          login: 'admin',
          passcode: 'fFTAI!@#back'
        }
      })
      context.commit('changeClient', client)
      client.onConnect = async () => {
        context.commit('changeConnectState', true)
        if (context.rootGetters['app/getCurrentOrganizationId']) {
          await context.dispatch('fetchEmailUnread')
        }
        client.subscribe('/topic/topicToWeb', (msg) => {
          try {
            const res: { recipient: string, id: number } = JSON.parse(msg.body)
            if (res.recipient && res.id) {
              const [userId, organizationId] = res.recipient.split(':')
              if (userId === context.rootState.user.userId && organizationId === context.rootGetters['app/getCurrentOrganizationId']) {
                context.commit('changeMsg', {
                  id: res.id,
                  userId,
                  organizationId
                })
                const count = context.rootState.message.count || 0
                context.commit('changeCount', count + 1)
              }
            }
          } catch (err) {
            console.error(err)
          }
        })
      }
      client.onDisconnect = () => {
        context.commit('changeConnectState', false)
        context.commit('changeClient', null)
      }
      client.onStompError = (frame) => {
        console.error('MQ Error!', frame)
      }
      client.activate()
    },
    async fetchEmailUnread (context) {
      try {
        const { data } = await fetchEmailUnread()
        context.commit('changeCount', data.unReadMessageNum)
      } catch (err) {
        console.error(err)
      }
    }
  }
}
export default message
