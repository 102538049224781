import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2f582544"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "fr-drawer-container"
};
const _hoisted_2 = {
  class: "fr-drawer-content-header"
};
const _hoisted_3 = {
  class: "fr-drawer-content-body"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_drawer = _resolveComponent("el-drawer");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_drawer, _mergeProps({
    "close-on-click-modal": _ctx.closeOnClickModal,
    "model-value": _ctx.visible,
    "custom-class": 'fr-drawer-content ' + _ctx.customClass,
    "with-header": false,
    direction: "rtl",
    "destroy-on-close": ""
  }, _ctx.props || {}, _toHandlers(_ctx.events || {})), {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("i", {
      class: "el-icon-close",
      onClick: _cache[0] || (_cache[0] = //@ts-ignore
      (...args) => _ctx.closeHandle && _ctx.closeHandle(...args))
    }), _createElementVNode("p", null, _toDisplayString(_ctx.title), 1)]), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])]),
    _: 3
  }, 16, ["close-on-click-modal", "model-value", "custom-class"])]);
}