import { createStore } from 'vuex'
import app, { AppProps } from './app'
import user, { UserProps } from './user'
import organization, { OrganizationProps } from './organization'
import message, { MessageProps } from './message'
export interface GlobalProps {
  app: AppProps
  user: UserProps
  organization: OrganizationProps
  message: MessageProps
}

const store = createStore({
  modules: {
    app,
    user,
    organization,
    message
  }
})

store.subscribe((mutation) => {
  if (mutation.type === 'app/changeOrganization') {
    if (store.getters['app/getCurrentOrganizationId']) {
      store.dispatch('message/fetchEmailUnread', null, { root: true })
    }
  }
})

export default store
