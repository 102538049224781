import { resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");

  const _component_fr_form_item = _resolveComponent("fr-form-item");

  const _component_form_item = _resolveComponent("form-item");

  return _openBlock(), _createBlock(_component_form_item, {
    props: _ctx.props
  }, {
    default: _withCtx(() => [_createVNode(_component_fr_form_item, {
      mode: _ctx.props.mode,
      content: _ctx.value
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, _mergeProps({
        autocomplete: "new-password",
        "model-value": _ctx.computedValue
      }, _ctx.props.inputProps, {
        maxlength: _ctx.props.maxlength || '',
        placeholder: _ctx.computedPlaceholder
      }, _toHandlers(_ctx.formInputEvents)), null, 16, ["model-value", "maxlength", "placeholder"])]),
      _: 1
    }, 8, ["mode", "content"])]),
    _: 1
  }, 8, ["props"]);
}