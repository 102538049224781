<template>
  <el-page-header
    :class="['fr-page-header', !content && 'hintString']"
    :content="content"
    icon=""
    @back="goBack"
  >
    <template #title>
      <el-button
        type="text"
        plain
        size="mini"
      >
        <i class="iconfont icon-icon_fanhui" />
        返回
      </el-button>
    </template>
  </el-page-header>
</template>
<script>
import { defineComponent, toRefs } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'FrPageHeader',
  props: {
    content: {
      type: String,
      default: '详情页面'
    },
    page: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const router = useRouter()
    const { page, path } = toRefs(props)

    const goBack = () => {
      if (path.value) {
        if (page.value) {
          router.push({
            path: path.value,
            query: {
              page: page.value
            }
          })
        } else {
          router.push({
            path: path.value
          })
        }
      } else {
        router.go(-1)
      }
    }
    return {
      goBack,
      router
    }
  }
})
</script>
<style lang="scss" scoped>
@import "../../styles/var";
.fr-page-header {
  margin-left: -10px;
  :deep(.el-button) {
    line-height: 16px;
    padding: 0px 8px;
    line-height: 28px;
    font-size: $--font-size-base;
    box-sizing: border-box;
    &:hover, &:active, &:focus {
      background-color: transparent;
      border-color: transparent;
    }
    .iconfont {
      vertical-align: -2px;
      font-size: 20px;
    }
  }
  :deep(.el-page-header__left) {
    margin-right: $--space-large;
    &::after {
      right: -8px;
    }
  }
  :deep(.el-page-header__content) {
    line-height: 31px;
    font-size: $--font-size-base;
  }
  &.hintString {
    :deep(.el-page-header__left) {
      &::after {
        display: none;
      }
    }
  }
}
</style>
