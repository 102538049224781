import { loginRequest, dasLoginRequest, logout } from '../api/user';
import storage from 'store';
import { ElMessage } from "element-plus";
import { getQueryString, clearTimeOutLogin } from '@/utils/util';
import router from '@/router';
import { Base64 } from 'js-base64';
let logoutSended = false;
const user = {
  namespaced: true,
  state: {
    token: storage.get('token'),
    userId: storage.get('userId'),
    userName: storage.get('userName'),
    roleIdList: storage.get('roleIdList'),
    officeId: storage.get('officeId'),
    permissions: storage.get('permissions'),
    dasToken: storage.get('dasToken'),
    phone: storage.get('phone'),
    lastCheck: storage.get('lastCheck'),
    dasRoleIdList: storage.get('dasRoleIdList'),
    targetRoute: '',
    targetQuery: '',
    isShowNoPermissions: false,
    keepLogin: storage.get('keepLogin')
  },
  mutations: {
    updateToken(state, token) {
      state.token = token;
    },

    updateUserId(state, userId) {
      state.userId = userId;
    },

    updateUserName(state, userName) {
      state.userName = userName;
    },

    updateRoleIdList(state, roleIdList) {
      state.roleIdList = roleIdList;
    },

    updateOfficeId(state, officeId) {
      state.officeId = officeId;
    },

    updatePermissions(state, permissions) {
      state.permissions = permissions;
    },

    updateDasToken(state, token) {
      state.dasToken = token;
    },

    updatePhone(state, phone) {
      state.phone = phone;
    },

    updateLastCheck(state, lastCheck) {
      state.lastCheck = lastCheck;
    },

    updateTargetRoute(state, targetRoute) {
      state.targetRoute = targetRoute;
    },

    updateTargetQuery(state, targetQuery) {
      state.targetQuery = targetQuery;
    },

    updateIsShowNoPermissions(state, isShowNoPermissions) {
      state.isShowNoPermissions = isShowNoPermissions;
    },

    updateDasRoleIds(state, dasRoleIdList) {
      state.dasRoleIdList = dasRoleIdList;
    },

    updateKeepLogin(state, keepLogin) {
      state.keepLogin = keepLogin;
    }

  },
  getters: {
    getUserId(state) {
      return String(state.userId || '');
    },

    getUserName(state) {
      return String(state.userName || '');
    },

    getUserRoleName(state, getters, rootState) {
      const roleList = rootState.organization.roleList || [];

      if (roleList && roleList.length) {
        const roleNameList = (state.roleIdList || []).map(id => roleList.find(role => role.roleId === id));
        return roleNameList.map(role => role === null || role === void 0 ? void 0 : role.roleName).join(',');
      }

      return '';
    },

    getTargetRoute(state) {
      return String(state.targetRoute || '');
    },

    getTargetQuery(state) {
      return String(state.targetQuery || '{}');
    }

  },
  actions: {
    async login(context, event) {
      const param = event;

      try {
        const {
          data
        } = await loginRequest({
          email: param.email,
          isLogin: param.isLogin,
          password: Base64.encode(param.password),
          phone: param.phone,
          rememberMe: param.rememberMe,
          verificationCode: param === null || param === void 0 ? void 0 : param.verificationCode,
          loginRoleType: +(param === null || param === void 0 ? void 0 : param.loginRoleType)
        });

        if (data && data.code === 0) {
          storage.set('token', data.token);
          storage.set('userId', data.user.id);
          storage.set('userName', data.user.name);
          storage.set('roleIdList', data.user.roleIdList);
          storage.set('officeId', data.user.office);
          storage.set('keepLogin', data.user.keepLogin); // getWebSocketDataList()  // WebSocket直接请求接口获取量表下拉数据（缓存本地）

          context.commit('updateToken', data.token);
          context.commit('updateUserId', data.user.id);
          context.commit('updateUserName', data.user.name);
          context.commit('updatePhone', data.user.phone); // 获取角色

          context.commit('updateRoleIdList', data.user.roleIdList);
          context.commit('updateOfficeId', data.user.office);
          context.commit('updateKeepLogin', data.user.keepLogin);
          storage.set('isFirst', true);
          logoutSended = false; // 判断 param.rememberMe ===1

          if (param.rememberMe === 1) {
            storage.set('email', param.email);
            storage.set('phone', param.phone);
            storage.set('password', Base64.encode(param.password));
            storage.set('rememberMe', 1);
          } else {
            storage.remove('email');
            storage.remove('phone');
            storage.remove('password');
            storage.set('rememberMe', 0);
          }

          if (!context.rootState.message.isConnect) {
            context.dispatch('message/connectMQ', null, {
              root: true
            });
          } // context.commit('organizationId',data.organization.id)

        } else {
          // this.getCaptcha()
          ElMessage({
            message: data.msg,
            showClose: true,
            type: 'error'
          });
        }

        try {
          dasLoginRequest({
            email: param.email || '',
            password: param.password,
            phone: param.phone || '',
            rememberMe: 'true'
          }).then(({
            data
          }) => {
            const token = data.data.token && data.data.token.token;
            context.commit('updateDasToken', token);
            storage.set('dasToken', token);
            const dasRoleIdList = data.data.user && data.data.user.roleIds;
            context.commit('updateDasRoleIds', dasRoleIdList);
            storage.set('dasRoleIdList', dasRoleIdList);
          });
        } catch (err) {
          console.log('DAS登录失败', err);
        }
      } catch (err) {
        console.log(err);
        return Promise.reject(err);
      }
    },

    async logout(context, option = {
      request: true
    }) {
      console.log(router);

      try {
        // 1. 断开MQ
        if (context.rootState.message.client) {
          context.rootState.message.client.deactivate();
        } // 2. 发送登出请求


        if (context.rootState.user.token && !logoutSended && option && option.request) {
          logoutSended = true;
          await logout();
        } else {
          logoutSended = true;
        } // 3. 获取记住密码信息


        const email = storage.get('email');
        const phone = storage.get('phone');
        const password = storage.get('password');
        const rememberMe = storage.get('rememberMe'); // 4. 清空token 和 storage

        context.rootState.user.token = '';
        storage.clearAll(); // 5. 如果记住密码 还原信息

        if (rememberMe) {
          storage.set('email', email);
          storage.set('phone', phone);
          storage.set('password', password);
          storage.set('rememberMe', rememberMe);
        }

        context.commit('updateTargetRoute', '');
        context.commit('updateTargetQuery', '');
        const routerData = {
          path: '/login',
          query: {}
        };

        if (router.options.history.location.startsWith('/login?loginRoleType')) {
          const queryObj = getQueryString(router.options.history.location);
          routerData.query = queryObj && queryObj;
        }

        clearTimeOutLogin();
        router.push(routerData);
      } catch (err) {
        console.log(err);
      }
    }

  }
};
export default user;