import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/Login/LoginIndex.vue";
import Home from "../views/HomePage.vue";
import DefaultView from "../components/DefaultView.vue";
import UserReset from "../views/Login/UserReset.vue";
import GaugeDefaultView from "../views/Gauge/GaugeDefaultView.vue";
import store from "@/store";
import { frisUrlPrefix } from "@/config";

const getDefaultRoute = (routeList, {
  parentUrl,
  url,
  fallbackUrl
}) => {
  const parentRoute = routeList.find(route => route.url === parentUrl);
  if (!parentRoute) return;

  if (parentRoute.list.some(item => item.url === url)) {
    return url;
  }

  return fallbackUrl;
};

const routes = [{
  path: "/",
  redirect: "/home"
}, {
  path: "/home",
  name: "Home",
  component: Home
}, {
  path: "/login",
  name: "Login",
  meta: {
    hideSlider: true
  },
  component: Login
}, {
  path: "/user-reset",
  name: "UserReset",
  meta: {
    hideSlider: true
  },
  component: UserReset
}, {
  path: "/screen",
  name: "screen",
  meta: {
    hideSlider: true
  },
  component: () => import("@/views/BigScreen/ScreenContent.vue")
}, {
  path: "/my-center",
  name: "MyCenter",
  component: () => import("@/views/MyCenter/MyCenter.vue")
}, {
  path: "/recovery",
  name: "Recovery",
  component: DefaultView,
  children: [{
    path: "list/:type?",
    name: "RecoveryList",
    props: true,
    component: () => import("../views/Recovery/RecoveryList/RecoveryList.vue")
  }, // {
  //   path: 'method/:methodId/:id?',
  //   name: 'RecoveryMethodIng',
  //   props: true,
  //   component: () => import('../views/Recovery/RecoveryList/RecoveryMethodIng.vue')
  // },
  // {
  //   path: 'report/:methodId/:id?',
  //   name: 'RecoveryReport',
  //   props: true,
  //   component: () => import('../views/Recovery/RecoveryList/RecoveryReport.vue')
  // },
  {
    path: "meeting/list/:userType?",
    name: "MeetingList",
    props: true,
    component: () => import("../views/Meeting/MeetingList.vue")
  }, {
    path: "meeting/detail/:id/:disable?",
    name: "MeetingDetail",
    meta: {
      defaultMenu: (path, routeList) => getDefaultRoute(routeList, {
        parentUrl: "/recovery",
        url: "/recovery/meeting/list/2",
        fallbackUrl: "/recovery/meeting/list"
      })
    },
    props: true,
    component: () => import("../views/Meeting/MeetingDetail.vue")
  }, {
    path: "project/:projectId/:isForceShow?",
    name: "RecoveryProjectDetail",
    props: true,
    meta: {
      defaultMenu: (path, routeList) => getDefaultRoute(routeList, {
        parentUrl: "/recovery",
        url: "/recovery/list/all",
        fallbackUrl: "/recovery/list"
      })
    },
    component: () => import("../views/Patient/PatientDetail/PatientProject/ProjectDetail/ProjectDetail.vue")
  }, {
    path: "recovery-detail/:projectId/:oneAndAllType?",
    name: "RecoveryDetail",
    props: true,
    meta: {
      defaultMenu: (path, routeList) => getDefaultRoute(routeList, {
        parentUrl: "/recovery",
        url: "/recovery/list/all",
        fallbackUrl: "/recovery/list"
      })
    },
    component: () => import("../views/Recovery/RecoveryDetail/RecoveryDetail.vue")
  }, {
    path: "view-report",
    name: "ViewReport",
    props: true,
    meta: {
      defaultMenu: (path, routeList) => getDefaultRoute(routeList, {
        parentUrl: "/recovery",
        url: "/recovery/list/all",
        fallbackUrl: "/recovery/list"
      })
    },
    component: () => import("../views/Recovery/RecoveryDetail/ViewReport.vue")
  }]
}, {
  path: "/patient",
  name: "Patient",
  component: DefaultView,
  children: [{
    path: "manage",
    name: "PatientManage",
    component: () => import("../views/Patient/PatientManage/PatientManage.vue")
  }, {
    path: "detail/:id/:treatmentId",
    name: "PatientDetail",
    props: true,
    meta: {
      defaultMenu: "/patient/manage"
    },
    component: () => import("../views/Patient/PatientDetail/PatientDetail.vue")
  }, {
    path: "project/:projectId/:isForceShow?",
    name: "ProjectDetail",
    props: true,
    meta: {
      defaultMenu: "/patient/manage"
    },
    component: () => import("../views/Patient/PatientDetail/PatientProject/ProjectDetail/ProjectDetail.vue")
  }, {
    path: "report/:patientId/:patientName",
    name: "PatientReport",
    props: true,
    meta: {
      defaultMenu: "/patient/manage"
    },
    component: () => import("../views/Patient/PatientManage/PatientReport/PatientReport.vue")
  }, {
    path: "assess-project/:projectId/:isForceShow?",
    name: "RecoveryAssessProjectDetail",
    props: true,
    meta: {
      defaultMenu: "/patient/manage"
    },
    component: () => import("../views/Patient/PatientDetail/PatientProject/AssessProjectDetail/AssessProjectDetail.vue")
  }, {
    path: "project/system-recommend-detail/:patientId/:treatmentId",
    name: "SystemRecommendDetail",
    props: true,
    meta: {
      defaultMenu: "/patient/manage"
    },
    component: () => import("../views/Patient/PatientDetail/PatientProject/PatientRecommend/SystemRecommendDetail.vue")
  }]
}, {
  path: "/email",
  name: "Email",
  redirect: "/my-news",
  component: DefaultView,
  children: [{
    path: "my-news",
    name: "MyNews",
    component: () => import("../views/Email/MyNews.vue")
  }, {
    path: "send-news/:emailId?",
    name: "SendNews",
    props: true,
    meta: {
      defaultMenu: "/email/send-news"
    },
    component: () => import("../views/Email/SendNews.vue")
  }, {
    path: "news-info/:emailId?",
    name: "NewsInfo",
    props: true,
    meta: {
      defaultMenu: "/email/my-news"
    },
    component: () => import("../views/Email/NewsInfo.vue")
  }]
}, {
  path: "/system",
  name: "System",
  component: DefaultView,
  children: [{
    path: "break",
    name: "Break",
    component: () => import("../views/System/BreakSetting.vue")
  }, {
    path: "version",
    name: "VersionSetting",
    component: () => import("../views/System/VersionSetting.vue")
  }, {
    path: "timedTask",
    name: "TimedTask",
    component: () => import("../views/System/TimedTask/TimedTask.vue")
  }, {
    path: "log",
    name: "SystemLogs",
    component: () => import("../views/System/SystemLogs.vue")
  }]
}, {
  path: "/manageTherapist",
  name: "ManageTherapist",
  component: DefaultView,
  children: [{
    path: "therapist",
    name: "Therapist",
    component: () => import("../views/ManageTherapist/Therapist/TherapistMenage.vue")
  }]
}, {
  path: "/visualWorking",
  name: "VisualWorking",
  component: DefaultView,
  children: [{
    path: "visualWorking",
    name: "VisualWorking",
    component: () => import("../views/BigScreen/VisualWorking/VisualWorking.vue")
  }]
}, {
  path: "/schedule",
  name: "Schedule",
  component: DefaultView,
  children: [{
    path: "doctor",
    name: "DoctorScheduleList",
    component: () => import("../views/Schedule/DoctorSchedule.vue")
  }, {
    path: "therapist/:master?",
    name: "TherapistScheduleList",
    props: true,
    component: () => import("../views/Schedule/TherapistSchedule.vue")
  }]
}, {
  path: "/mypatient",
  name: "MyPatient",
  component: DefaultView,
  children: [{
    path: "list",
    name: "MyPatientList",
    meta: {
      defaultMenu: "/mypatient/list"
    },
    component: () => import("../views/MyPatient/MyPatientList.vue")
  }, {
    path: "detail/:patientId/:treatmentId/:costType?",
    name: "MyPatientDetail",
    props: true,
    meta: {
      defaultMenu: "/mypatient/list"
    },
    component: () => import("../views/MyPatient/MyPatientDetail/MyPatientDetail.vue")
  }, {
    path: "template/:action",
    name: "MyPatientTemplate",
    props: true,
    meta: {
      defaultMenu: "/mypatient/list"
    },
    component: () => import("../views/MyPatient/MyPatientDetail/Case/SaveAsTemplate.vue")
  }, {
    path: "prescription/manage/:action?",
    name: "PrescriptionMange",
    meta: {
      defaultMenu: "/mypatient/list"
    },
    props: true,
    component: () => import("../views/MyPatient/MyPatientDetail/Prescription/PrescriptionTemplateManage.vue")
  }]
}, {
  path: "/maintainOffice",
  name: "MaintainOffice",
  component: DefaultView,
  children: [{
    path: "department",
    name: "Department",
    component: () => import("../views/MaintainOffice/Department/DepartmentManage.vue")
  }, {
    path: "duty",
    name: "Duty",
    component: () => import("../views/MaintainOffice/Duty/DutyManage.vue")
  }, {
    path: "postsmalltype",
    name: "PostSmallType",
    component: () => import("../views/MaintainOffice/PostSmallType/PostSmallType.vue")
  }, {
    path: "rank",
    name: "Rank",
    component: () => import("../views/MaintainOffice/Rank/RankManage.vue")
  }, {
    path: "officetype",
    name: "OfficeType",
    component: () => import("../views/MaintainOffice/OfficeType/OfficeType.vue")
  }, {
    path: "office",
    name: "Office",
    component: () => import("../views/MaintainOffice/Office/OfficeManage.vue")
  }, {
    path: "postType",
    name: "PostType",
    component: () => import("../views/MaintainOffice/PostType/PostType.vue")
  }]
}, {
  path: "/maintainSystem",
  name: "MaintainSystem",
  component: DefaultView,
  children: [{
    path: "organizationMaintain",
    name: "OrganizationMaintain",
    component: () => import("../views/MaintainSystem/OrganizationMaintain/OrganizationMaintain.vue")
  }, {
    path: "nationality",
    name: "Nationality",
    component: () => import("../views/MaintainSystem/Nationality/NationalityManage.vue")
  }, {
    path: "profession",
    name: "Profession",
    component: () => import("../views/MaintainSystem/Profession/ProfessionManage.vue")
  }, {
    path: "unit",
    name: "Unit",
    component: () => import("../views/MaintainSystem/Unit/UnitMenage.vue")
  }, {
    path: "relation",
    name: "Relation",
    component: () => import("../views/MaintainSystem/Relation/RelationManage.vue")
  }, {
    path: "time",
    name: "Time",
    component: () => import("../views/MaintainSystem/Time/TimeTemplate.vue")
  }, {
    path: "role",
    name: "Role",
    component: () => import("../views/MaintainSystem/Role/RoleManage.vue")
  }, {
    path: "menu",
    name: "Menu",
    component: () => import("../views/MaintainSystem/Menu/MenuManage.vue")
  }, {
    path: "user",
    name: "User",
    component: () => import("../views/MaintainSystem/User/UserMenage.vue")
  }, {
    path: "equipment-manage",
    name: "EquipmentManage",
    component: () => import("../views/MaintainSystem/Equipment/EquipmentManage.vue")
  }, {
    path: "registered",
    name: "Registered",
    component: () => import("../views/MaintainSystem/Registered/RegisteredManage.vue")
  }, {
    path: "method-payment",
    name: "MethodPayment",
    component: () => import("../views/MaintainSystem/MethodPayment/MethodPaymentManage.vue")
  }, {
    path: "member",
    name: "Member",
    component: () => import("../views/MaintainSystem/Member/MemberSet.vue")
  }, {
    path: "assessTable",
    name: "AssessTable",
    component: () => import("../views/MaintainSystem/AssessTable/AssessTable.vue")
  }, {
    path: "adminAccount",
    name: "AdminAccount",
    component: () => import("../views/MaintainSystem/AdminAccount/AdminAccountManage.vue")
  }]
}, {
  path: "/maintainTreat",
  name: "MaintainTreat",
  component: DefaultView,
  children: [{
    path: "equipment",
    name: "Equipment",
    component: () => import("../views/MaintainTreat/Equipment/EquipmentMenage.vue")
  }, {
    path: "equipment-type",
    name: "EquipmentType",
    component: () => import("../views/MaintainTreat/EquipmentType/EquipmentManage.vue")
  }, {
    path: "disease",
    name: "Disease",
    component: () => import("../views/MaintainTreat/Disease/DiseaseMenage.vue")
  }, {
    path: "functional",
    name: "Functional",
    component: () => import("../views/MaintainTreat/Functional/FunctionalMenage.vue")
  }, {
    path: "part",
    name: "Part",
    component: () => import("../views/MaintainTreat/Part/PartMenage.vue")
  }, {
    path: "template",
    name: "Template",
    component: () => import("../views/MaintainTreat/Template/ProjectTemplate.vue")
  }, {
    path: "projectLinkage",
    name: "ProjectLinkage",
    component: () => import("../views/MaintainTreat/ProjectLinkage/ProjectLinkage.vue")
  }, {
    path: "recoveryMethod",
    name: "RecoveryMethod",
    component: () => import("../views/MaintainTreat/RecoveryMethod/RecoveryMethod.vue")
  }, {
    path: "performanceSet",
    name: "PerformanceSet",
    component: () => import("../views/MaintainTreat/Performance/PerformanceSet.vue")
  }, {
    path: "therapeutic-regimen",
    name: "TherapeuticRegimen",
    component: () => import("../views/MaintainTreat/TherapeuticRegimen/TherapeuticRegimen.vue")
  }]
}, {
  path: "/dict",
  name: "Dict",
  component: () => import("../views/Dict/DictManage.vue")
}, {
  path: "/order",
  name: "Order",
  component: DefaultView,
  children: [{
    path: "my",
    name: "MyOrder",
    component: () => import("../views/Orders/My/MyOrder.vue")
  }, {
    path: "detail/:id/:page?/:path?",
    name: "OrderDetail",
    props: true,
    meta: {
      defaultMenu: (path, routeList) => getDefaultRoute(routeList, {
        parentUrl: "/order",
        url: "/order/patientOrder",
        fallbackUrl: "/order/my"
      })
    },
    component: () => import("../views/Orders/OrderDetail/OrderDetail.vue")
  }, {
    path: "patientOrder",
    name: "PatientOrder",
    component: () => import("../views/Orders/PatientOrder/PatientOrder.vue")
  }, {
    path: "expired",
    name: "ExpiredOrder",
    component: () => import("../views/Orders/ExpiredOrder.vue")
  }]
}, {
  path: "/dataStatistical",
  name: "DataStatistical",
  component: DefaultView,
  children: [{
    path: "officeWorkLoad",
    name: "OfficeWorkLoad",
    component: () => import("../views/Analysis/OfficeWorkLoad/OfficeWorkLoad.vue")
  }, {
    path: "equipmentUseLoad",
    name: "EquipmentUseLoad",
    component: () => import("../views/Analysis/EquipmentUseLoad/EquipmentUseLoad.vue")
  }, {
    path: "equipmentUseDetail",
    name: "EquipmentUseDetail",
    props: true,
    meta: {
      defaultMenu: "/dataStatistical/equipmentUseLoad"
    },
    component: () => import("../views/Analysis/EquipmentUseLoad/EquipmentUseDetail.vue")
  }, {
    path: "projectAnalysisLoad",
    name: "ProjectAnalysisLoad",
    component: () => import("../views/Analysis/ProjectAnalysisLoad/ProjectAnalysisLoad.vue")
  }, {
    path: "projectAnalysisDetail",
    name: "ProjectAnalysisDetail",
    props: true,
    meta: {
      defaultMenu: "/dataStatistical/projectAnalysisLoad"
    },
    component: () => import("../views/Analysis/ProjectAnalysisLoad/ProjectAnalysisDetail.vue")
  }, {
    path: "therapistWorkLoad",
    name: "TherapistWorkLoad",
    component: () => import("../views/Analysis/TherapistWorkLoad/TherapistWorkLoad.vue")
  }, {
    path: "therapistWorkDetail",
    name: "TherapistWorkDetail",
    props: true,
    meta: {
      defaultMenu: "/dataStatistical/therapistWorkLoad"
    },
    component: () => import("../views/Analysis/TherapistWorkLoad/TherapistWorkDetail.vue")
  }, // 治疗师工作量详情
  {
    path: "therapistWorkProjectDetail",
    name: "TherapistWorkProjectDetail",
    props: true,
    meta: {
      defaultMenu: "/dataStatistical/therapistWorkLoad"
    },
    component: () => import("../views/Analysis/TherapistWorkLoad/TherapistWorkProjectDetail.vue")
  }, {
    path: "treatmentCostLoad",
    name: "TreatmentCostLoad",
    component: () => import("../views/Analysis/TreatmentCostLoad/TreatmentCostLoad.vue")
  }, {
    path: "treatmentCostDetail",
    name: "TreatmentCostDetail",
    props: true,
    meta: {
      defaultMenu: "/dataStatistical/treatmentCostLoad"
    },
    component: () => import("../views/Analysis/TreatmentCostLoad/TreatmentCostDetail.vue")
  }, {
    path: "operational-data",
    name: "OperationalData",
    meta: {
      defaultMenu: "/dataStatistical/operational-data"
    },
    component: () => import("../views/Analysis/OperationalData/OperationalData.vue")
  }]
}, {
  path: "/shiftManage",
  name: "ShiftManage",
  component: () => import("../views/ShiftManage/ShiftSet/ShiftManage.vue")
}, {
  path: "/achievement",
  name: "Achievement",
  component: DefaultView,
  children: [{
    path: "therapist-achievement",
    name: "TherapistAchievement",
    props: true,
    component: () => import("../views/Achievement/TherapistAchievement.vue")
  }, {
    path: "doctor-achievement",
    name: "DoctorAchievement",
    props: true,
    component: () => import("../views/Achievement/DoctorAchievement.vue")
  }, {
    path: "doctor-projects/:id/:month",
    name: "DoctorAchievementProjects",
    props: true,
    meta: {
      defaultMenu: "/achievement/doctor-achievement"
    },
    component: () => import("../views/Achievement/DoctorProjects.vue")
  }, {
    path: "therapist-projects/:id/:month",
    name: "TherapistAchievementProjects",
    props: true,
    meta: {
      defaultMenu: "/achievement/therapist-achievement"
    },
    component: () => import("../views/Achievement/TherapistProjects.vue")
  }, {
    path: "doctor-detail/:templateId/:id/:month",
    name: "DoctorAchievementDetail",
    props: true,
    meta: {
      defaultMenu: "/achievement/doctor-achievement"
    },
    component: () => import("../views/Achievement/DoctorDetail.vue")
  }, {
    path: "therapist-detail/:templateId/:id/:month",
    name: "TherapistAchievementDetail",
    props: true,
    meta: {
      defaultMenu: "/achievement/therapist-achievement"
    },
    component: () => import("../views/Achievement/TherapistDetail.vue")
  }, {
    path: "therapist-wordload",
    name: "TherapistWorkload",
    props: true,
    component: () => import("../views/Achievement/TherapistWorkload.vue")
  }, {
    path: "doctor-wordload",
    name: "DoctorWorkload",
    props: true,
    component: () => import("../views/Achievement/DoctorWorkload.vue")
  }, {
    path: "user-wordload-detail/:type/:id/:name/:month",
    name: "UserWorkloadDetail",
    props: true,
    meta: {
      defaultMenu: path => {
        if (path.includes("/therapist")) {
          return "/achievement/therapist-wordload";
        } else if (path.includes("/doctor")) {
          return "/achievement/doctor-wordload";
        }

        return "";
      }
    },
    component: () => import("../views/Achievement/UserWorkloadDetail.vue")
  }]
}, {
  path: "/schedule",
  name: "Schedule",
  component: DefaultView,
  children: [{
    path: "doctor",
    name: "DoctorScheduleList",
    component: () => import("../views/Schedule/DoctorSchedule.vue")
  }, {
    path: "therapist/:master?",
    name: "TherapistScheduleList",
    props: true,
    component: () => import("../views/Schedule/TherapistSchedule.vue")
  }]
}, {
  path: "/outpatient",
  name: "Outpatient",
  component: DefaultView,
  children: [{
    path: "list",
    name: "OutpatientList",
    component: () => import("../views/Outpatient/OutpatientList.vue")
  }, {
    path: "detail/:patientId/:treatmentId",
    name: "OutpatientDetail",
    props: true,
    meta: {
      defaultMenu: "/outpatient/list"
    },
    component: () => import("../views/Outpatient/OutPatientDetail.vue")
  }, {
    path: "payment/:patientId/:businessId/:type/:treatmentId?",
    name: "OutPatientPayment",
    props: true,
    meta: {
      defaultMenu: "/outpatient/list"
    },
    component: () => import("../views/Outpatient/OutPatientPayment.vue")
  }, {
    path: "refund/:patientId/:businessId/:type/:id",
    name: "OutPatientRefund",
    props: true,
    meta: {
      defaultMenu: "/outpatient/list"
    },
    component: () => import("../views/Outpatient/OutPatientRefund.vue")
  }]
}, {
  path: "/organization",
  name: "Organization",
  component: DefaultView,
  children: [{
    path: "checkout",
    name: "OrganizationCheckout",
    component: () => import("../views/Organization/OrganizationCheckout.vue")
  }]
}, {
  path: "/performance",
  name: "Performance",
  component: DefaultView,
  children: [{
    path: "doctor",
    name: "DoctorPerformance",
    component: () => import("../views/Performance/DoctorPerformance/DoctorPerformance.vue")
  }]
}, // 量化表
{
  path: "/gauge/:projectId/:methodId/:patientId?/:treatmentId?",
  name: "Gauge",
  component: GaugeDefaultView,
  meta: {
    hideSlider: true
  },
  children: [{
    path: "table-bbs",
    name: "BBSTable",
    component: () => import("../views/Gauge/BBSTable.vue")
  }, {
    path: "table-asia",
    name: "ASIATable",
    component: () => import("../views/Gauge/ASIATable.vue")
  }, {
    path: "table-asia-sz",
    name: "ASIASZTable",
    component: () => import("../views/Gauge/ASIASZTable.vue")
  }, {
    path: "table-asia-xz",
    name: "ASIAXZTable",
    component: () => import("../views/Gauge/ASIAXZTable.vue")
  }, {
    path: "table-efr",
    name: "EFRTable",
    component: () => import("../views/Gauge/EFRTable.vue")
  }, {
    path: "table-hoffer",
    name: "HofferTable",
    component: () => import("../views/Gauge/HofferTable.vue")
  }, {
    path: "table-sensory",
    name: "SensoryTable",
    component: () => import("../views/Gauge/SensoryTable.vue")
  }, {
    path: "table-rom",
    name: "RomTable",
    component: () => import("../views/Gauge/RomTable.vue")
  }, {
    path: "table-ashworth",
    name: "AshworthTable",
    component: () => import("../views/Gauge/AshworthTable.vue")
  }, {
    path: "table-lung-fn",
    name: "LungFnTable",
    component: () => import("../views/Gauge/LungFnTable.vue")
  }, {
    path: "table-fugl-meyer",
    name: "FuglMeyerTable",
    component: () => import("../views/Gauge/FuglMeyerTable.vue")
  }, {
    path: "table-mmse",
    name: "MMSETable",
    component: () => import("../views/Gauge/MMSETable.vue")
  }, {
    path: "table-tinettic",
    name: "Tinettic",
    component: () => import("../views/Gauge/TinetticTable.vue")
  }, {
    path: "table-stms",
    name: "STMS",
    component: () => import("../views/Gauge/STMSTable.vue")
  }, {
    path: "table-water-swallow-test",
    name: "WaterSwallowTestTable",
    component: () => import("../views/Gauge/WaterSwallowTestTable.vue")
  }, {
    path: "table-self-rating-anxiety-scale",
    name: "SelfRatingAnxietyTableScale",
    component: () => import("../views/Gauge/SelfRatingAnxietyScaleTable.vue")
  }, {
    path: "table-self-rating-depression-scale",
    name: "SelfRatingDepressionScaleTable",
    component: () => import("../views/Gauge/SelfRatingDepressionScaleTable.vue")
  }, {
    path: "table-cardiac-assessment",
    name: "CardiacAssessment",
    component: () => import("../views/Gauge/CardiacAssessmentTable.vue")
  }, {
    path: "table-fugl-meyer-sport",
    name: "FuglMeyerSportTable",
    component: () => import("../views/Gauge/FuglMeyerSportTable.vue")
  }, {
    path: "table-mini-best",
    name: "MINIBESTTable",
    component: () => import("../views/Gauge/MINIBESTTable.vue")
  }, {
    path: "table-daily-life",
    name: "DailyLifeTable",
    component: () => import("../views/Gauge/DailyLifeTable.vue")
  }, {
    path: "table-ache-evaluate",
    name: "AcheEvaluateTable",
    component: () => import("../views/Gauge/AcheEvaluateTable.vue")
  }, {
    path: "table-frenchay",
    name: "FrenchayTable",
    component: () => import("../views/Gauge/FrenchayTable.vue")
  }, // 汉语失语症评定量表(ABC)-表格简化型
  {
    path: "table-logagnosia",
    name: "LogagnosiaTable",
    component: () => import("../views/Gauge/LogagnosiaAbc/LogagnosiaTable.vue")
  }, {
    path: "table-limb-circumference",
    name: "LimbCircumference",
    component: () => import("../views/Gauge/LimbCircumferenceTable.vue")
  }, {
    path: "table-glasgow",
    name: "Glasgow",
    component: () => import("../views/Gauge/GlasgowTable.vue")
  }, {
    path: "table-brunnstrom",
    name: "Brunnstrom",
    component: () => import("../views/Gauge/BrunnstromTable.vue")
  }, {
    path: "table-upper-limb",
    name: "UpperLimb",
    component: () => import("../views/Gauge/UpperLimbTable.vue")
  }, {
    path: "table-gmfm",
    name: "GMFM",
    component: () => import("../views/Gauge/GMFMTable.vue")
  }, {
    path: "table-child-autism",
    name: "ChildAutism",
    component: () => import("../views/Gauge/ChildAutismTable.vue")
  }, {
    path: "table-holden",
    name: "HoldenTable",
    component: () => import("../views/Gauge/HoldenTable.vue")
  }, {
    path: "table-asia-cq",
    name: "ASIACQTable",
    component: () => import("../views/Gauge/ASIACQTable.vue")
  }, // 功能独立性量表
  {
    path: "table-functional-independence-scale",
    name: "FunctionalIndependenceTable",
    component: () => import("../views/Gauge/FunctionalIndependenceTable.vue")
  }, // 完全性脊髓损伤康复目标预测
  {
    path: "table-complete-spinal-cord-injury-recovery",
    name: "CompleteSpinalCordInjuryRecoveryTable",
    component: () => import("../views/Gauge/CompleteSpinalCordInjuryRecoveryTable.vue")
  }, {
    path: "table-hamd",
    name: "HAMDTable",
    component: () => import("../views/Gauge/HAMDTable.vue")
  }, {
    path: "table-mcgill",
    name: "McGillPainQuestionnaireTable",
    component: () => import("../views/Gauge/McGillPainQuestionnaireTable.vue")
  }, {
    path: "table-bobath",
    name: "BobathTable",
    component: () => import("../views/Gauge/BobathTable.vue")
  }, // 简明健康状况调查表
  {
    path: "table-concis",
    name: "ConcisTable",
    component: () => import("../views/Gauge/ConcisTable.vue")
  }, {
    path: "table-coordination-function",
    name: "CoordinationFunctionTable",
    component: () => import("../views/Gauge/CoordinationFunctionTable.vue")
  }, // 威斯康辛步态量表
  {
    path: "table-wisconsin-scale",
    name: "TableWisconsinScaleTable",
    component: () => import("../views/Gauge/TableWisconsinScaleTable.vue")
  }, // 直腿坐位平衡（六级平衡）
  {
    path: "static-balance-of-straight-leg-sitting-position",
    name: "StaticBalanceOfStraightLegSittingPosition",
    component: () => import("../views/Gauge/StaticBalanceOfStraightLegSittingPosition.vue")
  }, {
    path: "table-scimt",
    name: "SCIMTable",
    component: () => import("../views/Gauge/SCIMTable.vue")
  }, // 语言发育迟缓检查(S-S法)
  {
    path: "table-language-retardation",
    name: "LanguageRetardationTable",
    component: () => import("../views/Gauge/LanguageRetardationSS/LanguageRetardationTable.vue")
  }, // 姿态评估
  {
    path: "attitude-evaluation",
    name: "AttitudeEvaluationTable",
    component: () => import("../views/Gauge/AttitudeEvaluationTable.vue")
  }, // 选择性功能动作评估（SFMA）
  {
    path: "table-sfma",
    name: "SFMATable",
    component: () => import("../views/Gauge/SFMATable.vue")
  }, {
    path: "table-walking-classification",
    name: "WalkingClassificationTable",
    component: () => import("../views/Gauge/WalkingClassificationTable.vue")
  }, {
    path: "table-rla",
    name: "RLATable",
    component: () => import("../views/Gauge/RLATable.vue")
  }, {
    path: "table-hemiplegic-gait",
    name: "HemiplegicGaitTable",
    component: () => import("../views/Gauge/HemiplegicGaitTable.vue")
  }, {
    path: "table-manual-evaluation",
    name: "ManualEvaluationTable",
    component: () => import("../views/Gauge/ManualEvaluationTable.vue")
  }, // 感觉统合综合发展能力评估表
  {
    path: "comprehensive-evaluation",
    name: "ComprehensiveEvaluationTable",
    component: () => import("../views/Gauge/ComprehensiveEvaluationTable.vue")
  }]
}, {
  path: "/dailyWorkload",
  name: "DailyWorkload",
  component: () => import("../views/DailyWorkload/DailyWorkload.vue")
}, {
  path: "/customerService",
  name: "CustomerService",
  component: DefaultView,
  children: [{
    path: "lease",
    name: "Lease",
    component: () => import("../views/CustomerService/Lease/LeaseManage.vue")
  }]
}, {
  path: "/pipeline",
  name: "Pipeline",
  component: DefaultView,
  children: [{
    path: "pipeline-manage",
    name: "PipelineManage",
    component: () => import("../views/Pipeline/ManagePipeline.vue")
  }, {
    path: "pipeline-detail/:patientId/:id/:status/:treatmentId?",
    name: "PipelineDetail",
    props: true,
    meta: {
      defaultMenu: "/pipeline/pipeline-manage"
    },
    component: () => import("../views/Pipeline/PipelineDetail.vue")
  }]
}, //  远程会诊
{
  path: "/telemedicine",
  name: "Telemedicine",
  component: DefaultView,
  children: [{
    // path: 'detail/:id/:treatmentId',
    path: "list",
    name: "TelemedicineList",
    props: true,
    component: () => import("../views/Telemedicine/TelemedicineList.vue")
  }, {
    // path: 'detail/:id/:treatmentId',
    path: "detail",
    name: "TelemedicineDetail",
    props: true,
    meta: {
      defaultMenu: "/telemedicine/list"
    },
    component: () => import("../views/Telemedicine/PatientDetail.vue")
  }, {
    path: "treat-project",
    name: "ProjectDetailTreat",
    props: true,
    meta: {
      defaultMenu: "/telemedicine/list"
    },
    component: () => import("../views/Telemedicine/PatientProject/ProjectDetailTreat.vue")
  }, {
    path: "assess-project",
    name: "ProjectDetailAssess",
    props: true,
    meta: {
      defaultMenu: "/telemedicine/list"
    },
    component: () => import("../views/Telemedicine/PatientProject/AssessProjectDetail/AssessProjectDetail.vue")
  }]
}, // 小程序管理
{
  path: "/frisApplet",
  name: "FrisApplet",
  component: DefaultView,
  children: [{
    path: "companyHomepage",
    name: "CompanyHomepage",
    props: true,
    component: () => import("../views/FrisApplet/Company/CompanyHomepage.vue")
  }, {
    path: "hospitalHomepage",
    name: "HospitalHomepage",
    props: true,
    component: () => import("../views/FrisApplet/Hospital/HospitalHomepage.vue")
  }]
}, // 签名板
{
  path: "/signature",
  name: "Signature",
  component: DefaultView,
  children: [{
    path: "list",
    name: "List",
    component: () => import("../views/Signature/SignatureList.vue")
  }, {
    path: "goSign/:patientName/:currentPatientCardId/:id",
    name: "GoSign",
    component: () => import("../views/Signature/SignOrder.vue")
  }]
}, // 设备管理
{
  path: "/DasEquipment",
  name: "DasEquipment",
  component: DefaultView,
  children: [{
    path: "gameDrill",
    name: "gameDrill",
    props: true,
    component: () => import("../views/DasEquipment/gameDrill/gameDrill.vue")
  }, {
    path: "equipmentInfo",
    name: "equipmentInfo",
    props: true,
    component: () => import("../views/DasEquipment/equipmentInfo/equipmentInfo.vue")
  }, {
    path: "visualHUD",
    name: "visualHUD",
    props: true,
    component: () => import("../views/DasEquipment/visualHUD/visualHUD.vue")
  }]
}, // 子量表系统
{
  path: "/SubFirsScale",
  name: "SubFirsScale",
  component: DefaultView,
  children: [{
    path: "platFormTemplate",
    name: "platFormTemplate",
    props: true,
    component: () => import("../views/SubFirsScale/platFormTemplate/platFormTemplate.vue")
  }, {
    path: "organizationTemplate",
    name: "organizationTemplate",
    props: true,
    component: () => import("../views/SubFirsScale/organizationTemplate/organizationTemplate.vue")
  }]
}, {
  path: "/manageTherapist",
  name: "ManageTherapist",
  component: DefaultView,
  children: [{
    path: "therapist",
    name: "Therapist",
    component: () => import("../views/ManageTherapist/Therapist/TherapistMenage.vue")
  }]
}, //  治疗记录
{
  path: "/treatmentRecord",
  name: "treatmentRecord",
  component: DefaultView,
  children: [{
    path: "treatmentIframe",
    name: "treatmentIframe",
    component: () => import("../views/TreatmentRecord/treatmentIframe.vue")
  }]
}, // fris驾驶舱
{
  path: '/frisEcharts',
  name: 'FrisEcharts',
  component: DefaultView,
  children: [{
    path: 'screenFris',
    name: 'ScreenFris',
    component: () => import('../views/FrisEcharts/ScreenFris.vue')
  }]
}, // fris驾驶舱
{
  path: '/frisEcharts',
  name: 'FrisEcharts',
  component: DefaultView,
  children: [{
    path: 'screenFris',
    name: 'ScreenFris',
    component: () => import('../views/FrisEcharts/ScreenFris.vue')
  }]
}];
const router = createRouter({
  history: createWebHashHistory(frisUrlPrefix),
  routes,

  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        top: 0
      };
    }
  }

});
const whiteList = ["/login", "/user-reset"];
router.beforeEach(to => {
  const targetRoute = to.path;

  if (targetRoute === "/telemedicine/detail" && to.query.isHiddenExpertTab) {
    store.commit("user/updateTargetRoute", targetRoute);
    store.commit("user/updateTargetQuery", JSON.stringify(to.query));
  } // 没有登录 并且不在白名单 就去登录


  if (!store.state.user.token && !whiteList.includes(to.path)) {
    return "/login";
  } // 登录以后没选机构，并且不在白名单


  if (store.state.user.token && !store.getters["app/getCurrentOrganizationId"] && ![...whiteList, "/organization/checkout", "/telemedicine/detail"].includes(to.path)) {
    return "/organization/checkout";
  }
});
export default router;