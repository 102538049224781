

import frisServer from '../utils/frisServer'
import { AxiosResponse } from 'axios'
import { IFrisListResponse, IFrisListData } from './api'
import store from '@/store/index'
import { IFrisBaseResponse } from '@/utils/frisServer'
import { IFrisPatientOrderListResponse } from '@/views/Orders/PatientOrder/usePatientOrderListFetch'
import { IFrisPatientProjectData } from '@/api/patient'
export interface IFrisProjectOrderData {
  comment: unknown
  confirmed: boolean
  createTime: string
  createUser: unknown
  equipmentId: string
  equipmentName: string
  expirationTime: string
  finishTime: string
  id: number
  money: number
  organizationId: number
  overTime: string
  patient: unknown
  patientId: string
  patientName: string
  patientType: string
  picUrl: unknown
  postSmallType: number
  projectId: number
  projectName: string
  rank: number
  referralStartTime: string
  remark: unknown
  rproject: unknown
  signUrl: unknown
  startTime: string
  status: number
  therapistId: string
  therapistName: string
  timeId: unknown
  timeQuantum: unknown
  trainee: unknown
  treatmentId: number
  updateTime: string

}

export function fetchProjectOrder (data: { page: number, limit: number, projectId: number }): Promise<AxiosResponse<IFrisListResponse<IFrisProjectOrderData>>> {
  return frisServer({
    url: `/sys/referral/list`,
    method: 'post',
    data
  })
}

export interface IFrisMyOrderData {
  comment: unknown
  confirmed: boolean
  createTime: string
  createUser: unknown
  equipmentId?: number
  equipmentName?: string
  expirationTime: string
  finishTime: unknown
  id: number
  money: number
  organizationId: unknown
  overTime: string
  patient: unknown
  patientId: string
  patientName: string
  patientType: unknown
  picUrl?: string
  postSmallType: unknown
  projectId: number
  projectName: string
  rank: unknown
  referralStartTime: unknown
  remark: unknown
  rproject: unknown
  signUrl?: string
  startTime: unknown
  status: number
  therapistId: unknown
  therapistName: string
  timeId: string | number
  timeQuantum: unknown
  trainee: unknown
  treatmentId: unknown
  updateTime: unknown
  updateUser: unknown
  duration: number
  startTherapistId: string
  endTherapistId: string
  startTherapist: string
  endTherapist: string
  treatmentTeamId: string
  originalTherapistId: string
}

export interface IFrisMyOrderParams {
  page: number
  limit: number
  expired: boolean
  status: string[]
  unsigned?: boolean
  filter: {
    patientIds?: string[]
    projectNames?: string[]
    startTime?: string
    endTime?: string
  }
}

export function fetchMyOrder (data: IFrisMyOrderParams): Promise<AxiosResponse<IFrisListResponse<IFrisMyOrderData>>> {
  return frisServer({
    url: `/sys/referral/myReferral`,
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}

export function empowerOrder (data: { id: number, therapistId: string, therapistName: string }) {
  return frisServer({
    url: `/sys/referral/update`,
    method: 'post',
    data
  })
}

export interface IFrisUpdateOrderState {
  therapistId: string
  therapistName: string
  force: string
}

export interface IFrisUpdateOrderStateAuthorize {
  therapistId: string
  therapistName: string
  force: string
}

export interface IFrisUpdateOrderStateSchedule {
  timeId: number
  timeQuantum: string
  startTime: string
  equipmentId?: number
  equipmentName?: string
  rank?: string
}

export interface IFrisUpdateOrderStateConfirm {
  sign: string
  pic: string
}

export interface IFrisUpdateOrderStateParams {
  organizationId?: string
	id?: number[]
	mode?: 'SINGLE' | 'BATCH'
	action: 'BEGIN' | 'END' | 'AUTHORIZE' | 'SCHEDULE' | 'CONFIRM' | 'REVERT' | 'EXECUTE' | 'WITHDRAW' | 'CANCEL_AUTHORIZE'
	param?: IFrisUpdateOrderStateAuthorize | IFrisUpdateOrderStateSchedule | IFrisUpdateOrderStateConfirm | IFrisUpdateOrderStateParams
}

export function updateOrderState (data: IFrisUpdateOrderStateParams) {
  return frisServer({
    url: `/sys/referral/handleState`,
    method: 'put',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      mode: 'SINGLE',
      param: {},
      ...data
    }
  })
}

export interface IFrischeckProjectPermissionParams {
  organizationId?: string
  id: number[]
  param: {
    therapistId: string
    therapistName: string
  }
}

export function checkProjectPermission (data: IFrischeckProjectPermissionParams) {
  return frisServer({
    url: `/sys/referral/checkProjectPermission`,
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}

export interface IFrisDoListTherapistParams {
  organizationId?: string
  therapistId: string
  therapistName?: string
}

export interface IFrisDoListEquipmentParams {
  organizationId?: string
  equipmentId: number | ''
  equipmentName?: string
  therapistId: string
}

export interface IFrisDoListPatientParams {
  organizationId?: string
  patientId: string
  patientName?: string
}

export interface IFrisReferralData {
  duration: number
  equipmentName: string
  equipmentNo: number
  equipmentShortName: string
  id: number
  patientName: string
  projectName: string
  rank: string
  specialFlag: number
  status: number
  therapistId: string
  therapistName: string
  timeId: string
  trainee: string
}

export interface IFrisDoListData {
  id: number
  organizationId: unknown
  referral: IFrisReferralData[]
  startTime: string
  timeQuantum: string
}

export function fetchDoList (data: IFrisDoListTherapistParams | IFrisDoListEquipmentParams | IFrisDoListPatientParams): Promise<AxiosResponse<IFrisListData<IFrisDoListData>>> {
  return frisServer({
    url: '/sys/referral/doList',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}

export interface IFrisEquipmentData {
  id: number
  name: string
  specialFlag: number
}

export function fetchEquipmentList (status: string): Promise<AxiosResponse<IFrisListData<IFrisEquipmentData>>> {
  return frisServer({
    url: '/sys/equipment/getEquipmentList',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      status
    }
  })
}

// 根据项目模板Id查询(默认的设备种类）  
export function fetchEquipmentByTemplateId (data:any): Promise<AxiosResponse<IFrisListData<IFrisEquipmentData>>> {
  return frisServer({
    url: '/sys/equipmentCategory/selectByTemplateId',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}

// 查询设备种类
export function fetchEquipmentCategoryList (): Promise<AxiosResponse<IFrisListData<IFrisEquipmentData>>> {
  return frisServer({
    url: '/sys/equipmentCategory/list',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
    }
  })
}

// 根据设备种类查询对应的【治疗设备】
export function fetchEquipmentList2 (data:any): Promise<AxiosResponse<IFrisListData<IFrisEquipmentData>>> {
  return frisServer({
    url: '/sys/equipment/getEquipmentList',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}

export interface IFrisReferralInfo {
  comment: unknown
  confirmed: boolean
  createTime: string
  createUser: unknown
  equipmentId: string
  equipmentName: string
  expirationTime: string
  finishTime: unknown
  id: number
  money: number
  organizationId: number
  overTime: string
  patient: unknown
  patientId: string
  patientName: string
  patientType: string
  picUrl: unknown
  postSmallType: number
  projectId: number
  projectName: string
  rank: number
  referralStartTime: unknown
  remark: unknown
  rproject: IFrisReferralRproject
  signUrl: unknown
  startTime: string
  status: number
  therapistId: string
  therapistName: string
  timeId: number
  timeQuantum: string
  trainee: unknown
  treatmentId: number
  updateTime: string
  updateUser: unknown
  treatmentTeamId: string
  originalTherapistId: string
  equipmentCategoryId?:number|string
  equipmentCategoryName?:string
}

export interface IFrisReferralInfoResponse {
  threadId: string
  referral: IFrisReferralInfo
}

export interface IFrisReferralRproject {
  assessId: string
  assessTime: unknown
  assessUser: string
  attendingDoctor: string
  code: string
  comment: string
  content: string
  contentType: unknown
  count: number
  createTime: string
  createUser: string
  createUserId: string
  dailyAmount: number
  duration: number
  endDate: unknown
  endUser: unknown
  endUserId: unknown
  equipmentId: string
  equipmentName: string
  hisItemNo: unknown
  hisOrderNo: unknown
  hisTreatId: unknown
  id: number
  methodId: unknown
  methodStr: unknown
  money: number
  name: string
  office: number
  organizationId: number
  part: string
  patientId: string
  patientName: string
  patientType: string
  payStatus: unknown
  postSmallType: number
  referralNum: number
  remark: string
  residue: unknown
  scores: unknown
  shortLong: number
  status: number
  templateId: number
  therapist: string
  therapistId: string
  time: string
  total: number
  totalAmount: unknown
  totalMoney: unknown
  trainee: unknown
  treated: number
  treatmentId: number
  type: number
  unit: number
  unitName: unknown
  unitPrice: number
  updateTime: string
  workDays: unknown
}

export function fetchReferralInfo (id: string | number): Promise<AxiosResponse<IFrisReferralInfoResponse>> {
  return frisServer({
    url: `/sys/referral/info/${id}`,
    method: 'get'
  })
}




export interface IFrisPatientOrder {
  page: number
  limit: number
  patientNames?: string[],
  timeIds?: number[],
  projectNames?: number[],
  equipmentNames?: string[],
  reType?: string
}
export interface IFetchPatientOrderInfo extends IFrisBaseResponse {
  equipmentIds: IFrisEquipmentIds[]
  patientIds: IFrisPatientIds[]
  projectTemplateIds: IFrisProjectTemplateIds[]
  timeIds: IFrisTimeIds[]
}
export interface IFetchPatientOrderData extends IFetchPatientOrderInfo {
  records: IFetchPatientOrderInfo
}

export function fetchPatientOrderList (params:Partial<IFrisPatientOrder>): Promise<AxiosResponse<IFrisPatientOrderListResponse<IFetchPatientOrderData>>> {
  return frisServer({
    url: '/sys/referral/selectReferralByPatient',
    method: 'post',
    data: {
      page: 1,
      limit: 10,
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    }
  })
}

export interface IFrisEquipmentIds{
  id: string
  name: string
}
export interface IFrisPatientIds{
  id: string
  name: string
}
export interface IFrisProjectTemplateIds{
  id: number
  name: string
}
export interface IFrisTimeIds{
  id: number
  time_quantum: string
}

export interface IFrisSelectReferral {
  equipmentIds: IFrisEquipmentIds[]
  patientIds: IFrisPatientIds[]
  projectTemplateIds: IFrisProjectTemplateIds[]
  timeIds: IFrisTimeIds[]
}
export interface IFrisSelectReferralData extends IFrisSelectReferral {
  result: IFrisSelectReferral
}

export function fetchPatientOrderFilterList (): Promise<AxiosResponse<IFrisSelectReferralData>> {
  return frisServer({
    url: '/sys/referral/selectReferralComboboxByPatient',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId']
    }
  })
}

export function fetchExportReferralByPatient (params:Partial<IFrisPatientOrder>): Promise<AxiosResponse> {
  return frisServer({
    url: '/sys/referral/exportReferralByPatient',
    method: 'post',
    // headers: {
    //   'Content-Type': 'application/vnd.ms-excel;charset=utf-8'
    // },
    responseType: 'blob',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    }
  })
}

export interface IFrisUploadSignOrPhoto {
  id: any
  pic?: string
  sign?: string
}

export function fetchUploadSignORPhoto (params:Partial<IFrisUploadSignOrPhoto>): Promise<AxiosResponse> {
  return frisServer({
    url: '/sys/referral/uploadReferralConfirmation',
    method: 'put',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    },
    // headers:{
    //   'Content-Type': 'multipart/form-data'
    // }
  })
}

export function saveOrderEquipment (params:any) {
  return frisServer({
    url: '/sys/referral/TreatmentEquipment',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    },
  })
}

export interface IFrisOverdueSelectDataInfo {
  id: string
  name: string
}
export interface IFrisOverduePatientSelectData {
  msg: string
  code: number
  threadId: string
  patientNamesList: IFrisOverdueSelectDataInfo[]
}
// 未完成工单下拉查询
export function fetchUnfinishPatientSelectList (params: { therapistId: string }): Promise<AxiosResponse<IFrisOverduePatientSelectData>> {
  return frisServer({
    url: '/sys/referral/incompletePatientSelectList',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    },
  })
}

// 已完成工单下拉查询
export function fetchFinishedPatientSelectList (params: { therapistId: string }): Promise<AxiosResponse<IFrisOverduePatientSelectData>> {
  return frisServer({
    url: '/sys/referral/completePatientSelectList',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    },
  })
}

// 过期工单下拉查询
export function fetchOverduePatientSelectList (params: { therapistId: string }): Promise<AxiosResponse<IFrisOverduePatientSelectData>> {
  return frisServer({
    url: '/sys/referral/overduePatientSelectList',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    },
  })
}

export interface IFrisOverdueProjectSelectData {
  msg: string
  code: number
  threadId: string
  projectNameList: IFrisOverdueSelectDataInfo[]
}
export function fetchOverdueProjectSelectList (params: { therapistId: string }): Promise<AxiosResponse<IFrisOverdueProjectSelectData>> {
  return frisServer({
    url: '/sys/referral/overdueProjectSelectList',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    },
  })
}

export interface IFrisOverdueProjectSelectData extends IFrisPatientProjectData {
  msg: string
  code: number
  threadId: string
  data: IFrisPatientProjectData
}

export function fetchProjectByExecutiveCode (params: { executiveCode: string }): Promise<AxiosResponse<IFrisOverdueProjectSelectData>> {
  return frisServer({
    url: '/sys/recoveryproject/selectProjectByExecutiveCode',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    },
  })
}

export function fetchCreateReferralByExecutiveCode (params: { executiveCode: string }): Promise<AxiosResponse> {
  return frisServer({
    url: '/sys/recoveryproject/createReferralByExecutiveCode',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    },
  })
}