
import frisServer, { IFrisBaseResponse } from '../utils/frisServer'
import { AxiosResponse } from 'axios'
import { pageListParams, IFrisListResponse, IFrisListData } from './api'

export function fetchMenuList (): Promise<AxiosResponse<IFrisBaseResponse>> {
  return frisServer({
    url: '/sys/menu/nav',
    method: 'get'
  })
}

export interface IFrisOrganizationData {
  code: unknown
  createTime: string
  createUser: unknown
  id: number
  name: string
  needSync: number
  sort: unknown
  status: unknown
  updateTime: string
  updateUser: unknown,
  organization: any
}

export interface IFrisOrganizationDataResponse extends IFrisBaseResponse {
  page: {
    currPage: number
    pageSize: number
    totalPage: number
    totalCount: number
    list: IFrisOrganizationData[]
  }
}

export function fetchOrganizationList (params: pageListParams = { page: 1, limit: 999999 }): Promise<AxiosResponse<IFrisOrganizationDataResponse>> {
  return frisServer({
    url: '/sys/organization/chooseOrganizationList',
    method: 'get',
    params: params
  })
}

// 获取验证码，暂无接口
export interface IVerificationCodeData {
  code: unknown
  createTime: string
}

export function getVerificationCode (params: any): Promise<AxiosResponse<IVerificationCodeData>> {
  return frisServer({
    url: '/sys/sendLoginVerificationCode',
    method: 'post',
    data: {
      ...params
    }
  })
}


export interface IlastLoginOrganization extends IlastLoginOrganizationData {
  code: unknown
  organization: IlastLoginOrganizationData
}

export interface IlastLoginOrganizationData {
  code: unknown
  createTime: unknown
  createUser: unknown
  id: number
  name: string
  needSync: number
  sort: unknown
  status: unknown
  updateTime: unknown
  updateUser: unknown
}

export function getlastLoginOrganization (): Promise<AxiosResponse<IlastLoginOrganization>> {
  return frisServer({
    url: `/sys/organization/lastLoginOrganization`,
    method: 'get',
  })
}

export interface IFrisDictTypeData {
  createtime: string
  id: number
  isCommon: number
  k: string
  organizationId: unknown
  pinYin: string
  type: string
  typeAndKey: unknown
  updatetime: string
  val: string
}

export function fetchProjectTypeList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "projectType"
    }
  })
}

export function fetchShortLongList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "shortLong"
    }
  })
}

export function fetchCostList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "costType"
    }
  })
}

export function fetchNursingLevelList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "nursingLevel"
    }
  })
}

export function fetchMarriageList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "marriage"
    }
  })
}

export function fetchSourceList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "source"
    }
  })
}

export function fetchSexList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "sex"
    }
  })
}

export function fetchDiseaseList (name: string): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/diseases/getDiseaseList',
    method: 'post',
    data: {
      limit: 50,
      page: 1,
      name
    }
  })
}

export function fetchFunctioningList (name: string): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/functioning/getFunctioningList',
    method: 'post',
    data: {
      limit: 50,
      page: 1,
      name
    }
  })
}

export function fetchNationList (): Promise<AxiosResponse<IFrisListData<string>>> {
  return frisServer({
    url: '/sys/patient/selectNationalityList',
    method: 'post'
  })
}

export function fetchUserStatusList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "userStatus"
    }
  })
}

export function fetchNoticeStatusList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "noticeStatus"
    }
  })
}

export function fetchIsReadList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "isRead"
    }
  })
}

export function fetchProjcetStatusList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "projcetStatus"
    }
  })
}

export function fetchAssessTypeList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "assessType"
    }
  })
}

export function fetchUnitList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "unit"
    }
  })
}

export function fetchRelationList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "relation"
    }
  })
}

export function fetchProfessionList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "profession"
    }
  })
}

export function fetchEqModelList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "eqMode"
    }
  })
}

export function fetchPayStatusList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "payStatus"
    }
  })
}

export function fetchOfficeList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "office"
    }
  })
}

export function downloadFile (link: string) {
  return frisServer({
    url: link,
    method: 'get',
    responseType: 'blob',
    timeout: 0
  })
}

export function changeOrganizationId (id: string) {
  return frisServer({
    url: `/sys/organization/chooseOrganization?id=${id}`,
    method: 'get'
  })
}

export function fetchPayTypeList (): Promise<AxiosResponse<IFrisListResponse<IFrisDictTypeData>>> {
  return frisServer({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      num: 10000,
      page: 1,
      type: "payType"
    }
  })
}