import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-f2fa4292"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "ver-code-box"
};
const _hoisted_2 = {
  key: 1,
  disabled: "false"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_input, {
    modelValue: _ctx.verCode,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.verCode = $event),
    class: "code-input",
    placeholder: "验证码",
    onInput: _ctx.returnVerCode
  }, null, 8, ["modelValue", "onInput"]), _createElementVNode("div", {
    class: _normalizeClass(["code-set-box", {
      'disabled': _ctx.getCodeStaus === 2 || !_ctx.canSendCode
    }])
  }, [_ctx.getCodeStaus === 1 ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    onClick: _cache[1] || (_cache[1] = $event => _ctx.getVerCode(1))
  }, " 获取验证码 ")) : _ctx.getCodeStaus === 2 ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.downTime) + " 后重新获取 ", 1)) : _ctx.getCodeStaus === 3 ? (_openBlock(), _createElementBlock("span", {
    key: 2,
    onClick: _cache[2] || (_cache[2] = $event => _ctx.getVerCode(3))
  }, " 重新获取 ")) : _createCommentVNode("", true)], 2)]);
}