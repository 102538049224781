import { App } from 'vue'
import FormInput from '@/components/core/FormInput.vue'
import FormItem from '@/components/core/FormItem.vue'
import FormRadio from '@/components/core/FormRadio.vue'
import FormSelector from '@/components/core/FormSelector.vue'
import FormDatePicker from '@/components/core/FormDatePicker.vue'
import FrFormGroupTitle from '@/components/core/FrFormGroupTitle.vue'
import FrFormItem from '@/components/core/FrFormItem.vue'
import FrPageHeader from '@/components/core/FrPageHeader.vue'
import FrProvider from '@/components/core/FrProvider.vue'
import FrRadio from '@/components/core/FrRadio.vue'
import FrSelector from '@/components/core/FrSelector.vue'
import SubmitBox from '@/components/core/SubmitBox.vue'
import FrDrawerContent from '@/components/core/FrDrawerContent.vue'
import FrTips from '@/components/core/FrTips.vue'
import FrSelectorComplete from '@/components/core/FrSelectorComplete.vue'
import { QuillEditor } from '@vueup/vue-quill'

export const components = [
  FormInput,
  FormItem,
  FormRadio,
  FormSelector,
  FormDatePicker,
  FrFormGroupTitle,
  FrFormItem,
  FrPageHeader,
  FrProvider,
  FrRadio,
  FrSelector,
  SubmitBox,
  FrDrawerContent,
  FrTips,
  FrSelectorComplete,
  QuillEditor
]


const install = (app: App): void => {
  components.forEach(component => {
    // eslint-disable-next-line
    // @ts-ignore
    app.component(component.name, component)
  })
}


export default {
  install
}
