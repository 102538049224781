import { AxiosResponse } from 'axios'
import frisServer from "@/utils/frisServer"
import { IFrisListResponse, IFrisListData } from './api'
import store from '@/store/index'
export interface IFrisRoleList {
  page: number
  limit: number
  roleName: string | null
}
export interface IFetchRoleInfo {
  createTime: string
  createUserId: string
  menuIdList: number[] | null
  remark: string
  roleId: number
  roleName: string
  rupsRoleId: string | null
}

export function getRoleList (params:Partial<IFrisRoleList>): Promise<AxiosResponse<IFrisListResponse<IFetchRoleInfo>>> {
  return frisServer({
    url:'/sys/role/list',
    method:'get',
    params:{
      page: 1,
      limit: 10,
      roleName: null,
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    }
  })
}

export interface IFetchRole {
  role:IFetchRoleInfo
}
export function fetchRoleInfo (params:number): Promise<AxiosResponse<IFetchRole>> {
  return frisServer({
    url:`/sys/role/info/${params}`,
    method:'get'
  })
}

export interface IFrisRoleSave {
  menuIdList: number[] | null
  remark: string
  roleId: number | null
  roleName: string
}
export function fetchRoleUpdate (params:IFrisRoleSave): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/role/update`,
    method: 'post',
    data: params
  })
}

export function fetchRoleSave (params:IFrisRoleSave): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/role/save`,
    method: 'post',
    data: params
  })
}

export interface IFrisRoleDelete {
  ids:number[]
}
export function fetchRoleDelete (params:number[]): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/role/delete`,
    method: 'post',
    data: params
  })
}


// menu
export interface IFrisMenuItemData {
  icon: string
  list?: IFrisMenuItemData[]
  menuId: number
  name: string
  open: unknown
  orderNum: number
  parentId: number
  parentName?: string
  perms?: string
  type: number
  url?: string
}

export function fetchMenuList (): Promise<AxiosResponse<IFrisMenuItemData[]>> {
  return frisServer({
    url: `/sys/menu/list`,
    method: 'get',
    params: {
      organizationId: store.getters['app/getCurrentOrganizationId']
    }
  })
}

export function fetchMenuSelectList (): Promise<AxiosResponse<any>> {
  return frisServer({
    url: `/sys/menu/select`,
    method: 'get',
    params: {
      organizationId: store.getters['app/getCurrentOrganizationId']
    }
  })
}

export interface IfrisMenuInfo extends IFrisMenuItemData{
  menu: IFrisMenuItemData
}

export function fetchMenuInfo (params:number): Promise<AxiosResponse<IfrisMenuInfo>> {
  return frisServer({
    url: `/sys/menu/info/${params}`,
    method: 'get'
  })
}


export interface IfrisMenuSave{
  menuId?: number
  type: number
  name: string
  parentId?: number
  url?: string
  perms?: string
  orderNum?: number
  icon?: string
}

export function fetchMenuSave (params:IfrisMenuSave): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/menu/save`,
    method: 'post',
    data: params
  })
}

export function fetchMenuUpdate (params:IfrisMenuSave): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/menu/update`,
    method: 'post',
    data: params
  })
}


export function fetchMenuDelete (params:number): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/menu/delete/${params}`,
    method: 'post'
  })
}



// user
export interface IFrisUserList {
  page: number
  limit: number
  name: string | null
  isTherapist?: number
}
export interface IFrisUserData {
  createTime: string
  createUserId: string
  menuIdList: number[] | null
  remark: string
  roleId: number
  roleName: string
  rupsRoleId: string | null
}

export function fetchUserList (params:Partial<IFrisUserList>): Promise<AxiosResponse<IFrisListResponse<IFrisUserData>>> {
  return frisServer({
    url:'/sys/user/list',
    method:'get',
    params:{
      page: 1,
      limit: 10,
      roleName: null,
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    }
  })
}
export interface IFrisAdminUserData {
  organizationId: number
  userId: string
  userName: string
  organizationName: string
  phone: string
  email: string
  createTime: string
}
export function fetchAdminUserList (params:Partial<IFrisUserList>): Promise<AxiosResponse<IFrisListResponse<IFrisAdminUserData>>> {
  return frisServer({
    url:'/sys/user/selectAdminUsers',
    method:'post',
    data:{
      page: 1,
      limit: 10,
      // organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    }
  })
}

export interface IFrisCheckOnlineUser {
  email?: string
  phone?: string
}
export function fetchCheckOnlineUser (params:IFrisCheckOnlineUser): Promise<AxiosResponse> {
  return frisServer({
    url:`/sys/user/checkOnlineUser`,
    method:'post',
    data:{
      organization: store.getters['app/getCurrentOrganizationId'],
      ...params
    }
  })
}

export interface IFrisVerCodeData {
  type: number,
  address: string
}
export function fetchVerCode (params:IFrisVerCodeData): Promise<AxiosResponse> {
  return frisServer({
    url:`/sys/user/sendVerificationCode`,
    method:'post',
    data:params
  })
}



export interface IFrisCheckVerificationCodeData {
  verificationCodeType: string
  phone?: string
  email?: string
  verificationCode: string
}
export function fetchCheckVerificationCode (params:IFrisCheckVerificationCodeData): Promise<AxiosResponse> {
  return frisServer({
    url:`/sys/user/checkVerificationCode`,
    method:'post',
    data:params
  })
}
export interface IFrisUserInfo {
  birthday: string
  checkColumn: number
  createTime: string
  createUser: string
  createUserId: unknown
  department: string
  downGradeFlag: unknown
  duty: string
  dutyArr: number[]
  email: string
  hisId: unknown
  id: string
  identity: unknown
  isChecked: number
  keepLogin: boolean
  name: string
  needCheck: unknown
  office: string
  organization?: number | string
  parentId: string
  password: string
  comfirmPassword?:string
  phone: string
  postSmallType: number[]
  postType: number[]
  rank: number
  resourceSys: unknown
  roleIdList: number[]
  roleIds: unknown
  sex: string
  status: string
  statusStr: string
  therapistRole: unknown
  updateTime: string
  updateUser: string
  verificationCode: unknown
  verificationCodeType: unknown
}
export interface IFrisUserInfoData {
  code: number
  msg: string
  threadId: string
  user:IFrisUserInfo
}
export function fetchUserInfo (params:string): Promise<AxiosResponse<IFrisUserInfoData>> {
  return frisServer({
    url:`/sys/user/info/${params}`,
    method:'get'
  })
}

export interface IFrisUserSave {
  menuIdList: number[] | null
  remark: string
  id: string | null
  userName: string
  organization?: string
}
export function fetchUserUpdate (params:IFrisUserInfo): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/user/update`,
    method: 'post',
    data: {
      organization: store.getters['app/getCurrentOrganizationId'],
      ...params
    }
  })
}

export interface IFrisUserUpdatePersonalInfo{
  id: string
  sex: string
  name: string
  birthday: string
}
// 修改个人信息单独接口
export function fetchUserUpdatePersonalInfo (params:IFrisUserUpdatePersonalInfo): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/user/updatePersonalInfo`,
    method: 'put',
    data: params
  })
}

export function fetchUserSave (params:IFrisUserInfo): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/user/save`,
    method: 'post',
    data: {
      organization: store.getters['app/getCurrentOrganizationId'],
      ...params
    }
  })
}

export interface IFrisUserDelete {
  ids:string[]
}
export function fetchUserDelete (params:string[]): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/user/delete`,
    method: 'post',
    data: params
  })
}

export interface IFrisUserResetPassword {
  id:string
}
export function fetchUserResetPassword (params:IFrisUserResetPassword): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/user/resetPassword`,
    method: 'post',
    data: params
  })
}


export interface IfrisTherapistUpAndDown {
  userId: string, // 用户id
  operate: string  // "up"为升级，"down"为降级
}

export function fetchTherapistUpAndDown (params:IfrisTherapistUpAndDown): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/user/upAndDownGrade`,
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    }
  })
}


// 停工设置
export interface IFrisStopListData {
  createTime: string
  id: number
  organizationId: number
  time: string
  type: number
}

export function fetchStopList (): Promise<AxiosResponse<IFrisListData<IFrisStopListData>>> {
  return frisServer({
    url: `/sys/config/stopList`,
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
    }
  })
}

export function shopJob (time: string,  type: number, id?: number) {
  return frisServer({
    url: `/sys/config/stopJob`,
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      time,
      id: id || '',
      type
    }
  })
} 


// 定时任务开始

export interface IFrisTimedTaskListData {
  beanName: string
}
export interface IFrisTimedTaskListInfo {
  beanName: string
  createTime: string
  cronExpression: string
  jobId: number
  params: string
  remark: string
  status: number
  time: unknown
}

export function fetchTimedTaskList (params:IFrisTimedTaskListData): Promise<AxiosResponse<IFrisListResponse<IFrisTimedTaskListInfo>>> {
  return frisServer({
    url: `/sys/schedule/list`,
    method: 'get',
    params: {
      page: 1,
      limit: 10,
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    }
  })
}


export function fetchTimedTaskInfo (id:number): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/schedule/info/${id}`,
    method: 'get'
  })
}

export interface IFrisTimedTaskSave {
  jobId?: number
  beanName: string
  cronExpression: string
  params: string
  remark: string
  status?: number
}
export function fetchTimedTaskSave (params:IFrisTimedTaskSave): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/schedule/save`,
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    }
  })
}

export function fetchTimedTaskUpdate (params:IFrisTimedTaskSave): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/schedule/update`,
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    }
  })
}

export function fetchTimedTaskDelete (params:number[]): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/schedule/delete`,
    method: 'post',
    data: params
  })
}

export function fetchTimedTaskPause (params:number[]): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/schedule/pause`,
    method: 'post',
    data: params
  })
}

export function fetchTimedTaskResume (params:number[]): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/schedule/resume`,
    method: 'post',
    data: params
  })
}

export function fetchTimedTaskRun (params:number[]): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/schedule/run`,
    method: 'post',
    data: params
  })
}

export interface IFrisTimedTaskLogListInfo {
  beanName: string
  createTime: string
  error: unknown
  jobId: number
  logId: number
  params: string
  status: number
  times: number
}
export interface IFrisParams {
  page: number
  limit: number
}
export function fetchTimedTaskLogList (params:IFrisParams): Promise<AxiosResponse<IFrisListResponse<IFrisTimedTaskLogListInfo>>> {
  return frisServer({
    url: `/sys/scheduleLog/list`,
    method: 'get',
    params: {
      // page: 1,
      // limit: 10,
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    }
  })
}

export function fetchTimedTaskLogInfo (id:number): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/scheduleLog/info/${id}`,
    method: 'get'
  })
}

// 定时任务结束

// 系统日志开始

export interface IFrisLogListParams {
  key: string
}
export function fetchLogList (params: IFrisLogListParams): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/log/list`,
    method: 'get',
    params: {
      page: 1,
      limit: 10,
      ...params
    }
  })
}
// 系统日志结束


export interface IFrisSignatureSavetParams {
  sign: string
  userId: string
}

export function fetchSignatureSave (data: IFrisSignatureSavetParams): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/signature/save`,
    method: 'post',
    data: {
      ...data
    }
  })
}

export function fetchGetSignature (): Promise<AxiosResponse> {
  return frisServer({
    url: `/sys/signature/getSignature`,
    method: 'get',
  })
}
