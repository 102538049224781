import axios, { AxiosResponse, AxiosError } from 'axios'
import storage from 'store'
import { frisBaseUrl } from '@/config'
import store from '@/store'

const frisServer = axios.create({
  baseURL: frisBaseUrl,
  timeout: 1000 * 30,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})
/**
 * 请求拦截
 */
frisServer.interceptors.request.use(
  (config) => {
    const token = storage.get('token')
    if (token && config.headers) {
      config.headers['token'] = token // 请求头带上token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * 响应拦截
 */

export interface IFrisBaseResponse {
  msg: string;
  code: number;
  [propsName: string]: any;
}



frisServer.interceptors.response.use(
  (response: AxiosResponse<IFrisBaseResponse>) => {
    if (response.data.code === undefined) {
      return response
    }
    if (response.data.code !== 0) {
      if (response.data.code === 401) {
       store.dispatch('user/logout', { request: false })
      }

      return Promise.reject(response)
    }
    return response
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

export default frisServer
