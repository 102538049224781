
import { ref, onMounted, defineComponent, onActivated, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { fetchHomeInfo } from "@/api/organization"
import { useRouter } from 'vue-router'
import { frisUrlPrefix } from '@/config'
import { getUserRole } from '@/utils/util'

export default defineComponent ({
  setup () {
    const router = useRouter()
    // mounted
    onMounted(() => {
      getMessage()
    })

    onActivated(() => {
      getMessage()
    })

    const getMessage = async () => {
      try {
        const { data } = await fetchHomeInfo()
        messageAmount.value = data.msgCount
        patientsAmount.value = data.nonprogrammable
        assessmentAmount.value = data.asCount
        completedNotAmount.value = data.undoneTotal
        completedAmount.value = data.doneTotal
        unconfirmPatient.value = data.notSpecified
      } catch (err) {
        if (err.data) {
          ElMessage({ message: err.data.msg, showClose: true, type: 'error' })
        }
      }
    }

    const messageAmount = ref<number>(0)
    const patientsAmount = ref<number>(0)
    const assessmentAmount = ref<number>(0)
    const completedNotAmount = ref<number>(0)
    const completedAmount = ref<number>(0)
    const unconfirmPatient = ref<number>(0)

    const isAdmin = getUserRole(1)

    // 自定义栏目
    let columnLIstanbul = computed(() => {
      if (isAdmin) {
        return [
          {
            id: 1,            
            amount: messageAmount.value, 
            title: '未读消息',
            class: 'unread-msg',
            iconUrl: '/icon/home/unread-msg.png',
          }
        ]
      } else {
        return [
          {
            id: 1,            
            amount: messageAmount.value, 
            title: '未读消息',
            class: 'unread-msg',
            iconUrl: '/icon/home/unread-msg.png',
          },
          {
            id: 2,            
            amount: patientsAmount.value, 
            title: '待安排患者',
            class: 'unplan-patient',
            iconUrl: '/icon/home/unplan-patient.png',
            permission: 'sys:cardUnplanPatient:show'
          },
          {
            id: 3,            
            amount: assessmentAmount.value, 
            title: '待完成评定',
            class: 'unfinish-judge',
            iconUrl: '/icon/home/unfinish-judge.png',
            permission: 'sys:cardUnfinishedAssess:show'
          },
          {
            id: 4,            
            amount: completedNotAmount.value, 
            title: '待完成工单',
            class: 'unfinish-order',
            iconUrl: '/icon/home/unfinish-order.png',
            permission: 'sys:cardUnfinishReferral:show'
          },
          {
            id: 5,            
            amount: completedAmount.value, 
            title: '已完成工单',
            class: 'finished-order',
            iconUrl: '/icon/home/finished-order.png',
            permission: 'sys:cardFinishedReferral:show'
          },
          {
            id: 6,
            amount: unconfirmPatient.value, 
            title: '未指定治疗师',
            class: 'unconfirm-patient',
            iconUrl: '/icon/home/unconfirm-patient.png',
            permission: 'sys:index:notSpecified'
          }
        ]
      }
    })

    // 根据id进行相应操作 // 或者后面给columnLIstanbul加个router 进行相应跳转
    const handleClick = (id:number) => {
      switch (id)
      {
        case 1:
          router.push('/email/my-news')
          break
        case 2:
          router.push('/order/my?action=unfinish')
          break
        case 3:
          router.push('/recovery/list')
          break
        case 4:
          router.push('/order/my?action=unfinish')
          break
        case 5:
          router.push('/order/my?action=finished')
          break
        case 6:
          router.push('/patient/manage')
          break

      }
    }

    return {
      getMessage,
      columnLIstanbul,
      handleClick,
      frisUrlPrefix,
      isAdmin
    }
  },
})
