<template>
  <div
    v-loading="isLoading"
    class="login-wrap"
  >
    <!-- 登录层 -->
    <div class="ms-login">
      <el-row>
        <el-col
          :xs="0"
          :sm="8"
          :md="8"
          :lg="8"
          :xl="8"
          class="ms-cover"
          :style="{ backgroundImage: `url(${getCoverImg})` }"
        >
          <div class="ms-radio">
            <img
              :src="getRadio"
              alt=""
            >
          </div>
          <div class="ms-logo mt-56 ml-48">
            <img
              :src="getLogoImg"
              alt=""
            >
          </div>
          <div
            class="ms-center" 
          >
            <img
              :src="getCenter"
              alt=""
            >
          </div>
          <div
            class="ms-bottom"
            :style="{ backgroundImage: `url(${getFooterImg})` }"
          >
            <img
              :src="getFooterText"
              alt=""
            >
          </div>
        </el-col>
        <el-col
          :xs="24"
          :sm="16"
          :md="16"
          :lg="16"
          :xl="16"
          :class="['ms-form', currentLocation ? 'records-ms-form' : '']"
        >
          <div class="login-container">
            <div
              id="content-panel"
              class="ms-content-panel"
            >
              <p class="ms-title">
                登录 FRIS！
              </p>

              <!-- :rules="rules" -->
              <!-- inline-message -->
              <el-form
                ref="login"
                :model="param"
                label-width="0px"
                class="ms-content save"
              >
                <p
                  v-show="warnTip"
                  class="warn-content"
                >
                  {{ warnTip }}
                </p>
                <el-form-item
                  prop="mobile"
                  style="margin-bottom: 24px"
                >
                  <el-input
                    v-model="param.mobile"
                    autocomplete="off"
                    placeholder="手机号/邮箱号"
                    @blur="checkMobile"
                    @keyup.enter="submitForm()"
                  />
                </el-form-item>
                <el-form-item
                  prop="password"
                  style="margin-bottom: 14px"
                >
                  <el-input
                    v-model="param.password"
                    type="password"
                    auto-complete="new-password"
                    placeholder="密码"
                    @blur="checkPassword"
                    @keyup.enter="submitForm()"
                  />
                </el-form-item>
                <el-form-item
                  v-if="loginRoleType !== '2'"
                  prop="verificationCode"
                >
                  <fr-form-item class="code-input-box">
                    <ver-code-module
                      class="ver-code-box"
                      :phone-or-email="param"
                      :cooldown-time="60000"
                      @inputVerCode="inputVerCode"
                      @sendCodeWarn="sendCodeWarn"
                    />
                  </fr-form-item>
                </el-form-item>
                <el-form-item style="margin-bottom: 64px">
                  <!-- `rememberMe` 为 1 或 0 -->
                  <el-checkbox
                    v-model="param.rememberMe"
                    :true-label="1"
                    :false-label="0"
                  >
                    记住密码
                  </el-checkbox>
                  <p
                    class="ms-forget"
                    @click="goUserReset()"
                  >
                    忘记密码
                  </p>
                </el-form-item>
                <div class="login-btn">
                  <el-button
                    class="colorful"
                    type="primary"
                    @click="submitForm()"
                  >
                    登录
                  </el-button>
                </div>
              </el-form>
              <div
                v-if="loginRoleType !== '0'"
                class="switch-login"
                @click="switchLogin"
              >
                {{ loginRoleType === '2' ? `管理员登录 >>` : `医疗人员登录 >>` }}
              </div>
            </div>
          </div>
          <put-on-records v-if="currentLocation" />
        </el-col>
      </el-row>
    </div>
  </div>

  <!-- 对话框 目前暂不封装 -->
  <el-dialog
    v-model="dialogVisible"
    :close-on-click-modal="false"
    custom-class="login-dialog"
    title="登录提示"
    :width="320"
    :modal="true"
    :append-to-body="true"
    :show-close="false"
    top="25vh"
  >
    <span>该账户已经在其他终端登录，是否退出并登录此设备</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button
          type="primary"
          @click="agreement()"
        >确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref, reactive, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { getlastLoginOrganization } from '../../api/app'
import { fetchRemoteDiagnosisTakeover } from '../../api/telemedicine'
import { batchDispatchOrganizationActions, batchDispatchAppActions } from '@/utils/util'
import storage from 'store'
import { validateInfo } from '@/utils/form'
import { Base64 } from 'js-base64'
import PutOnRecords from '@/components/PutOnRecords.vue'

import loginCover from '@/assets/login/login_cover.png'
import loginLogo from '@/assets/login/login_logo.png'
import loginCenter from '@/assets/login/login_center.png'
import loginRadio from '@/assets/login/login_radio.png'
import loginFooter from '@/assets/login/login_footer.png'
import loginFooterText from '@/assets/login/login_footer_text.png'

import loginAdminCover from '@/assets/login/login_admin_cover.png'
import loginAdminRadio from '@/assets/login/login_admin_radio.png'
import loginAdminCenter from '@/assets/login/login_admin_center.png'
import loginAdminLogo from '@/assets/login/login_admin_logo.png'
import loginAdminFooter from '@/assets/login/login_admin_footer.png'
import loginAdminFooterText from '@/assets/login/login_admin_footer_text.png'

import loginSuperAdminCover from '@/assets/login/login_super_admin_cover.png'
import loginSuperAdminRadio from '@/assets/login/login_super_admin_radio.png'
import loginSuperAdminCenter from '@/assets/login/login_super_admin_center.png'
import loginSuperAdminLogo from '@/assets/login/login_super_admin_logo.png'
import loginSuperAdminFooter from '@/assets/login/login_super_admin_footer.png'
import loginSuperAdminFooterText from '@/assets/login/login_super_admin_footer_text.png'

// import coveImg from '@/assets/login/cover.png'
// import adminCove from '@/assets/login/admin-cover.png'
// import superAdminCove from '@/assets/login/super-admin-cover.png'
// import footerImg from '@/assets/login/login-bottom-bg.png'
// import adminFooterImg from '@/assets/login/admin-login-bg.png'
// import superAdminFooterImg from '@/assets/login/super-admin-login-bg.png'

export default {
  name: 'LoginIndex',
  components: {
    PutOnRecords
  },
  setup () {
    // 配置
    const route = useRoute()
    const store = useStore()
    const router = useRouter()
    const isLoading = ref(false)
    const warnTip = ref('')
    const currentLocation = computed(() => {
      return router.options.history.location.startsWith('/login')
    })
    // 可视区域高度
    const clientHeight = document.documentElement.clientHeight
    // 登录相关   
    let param = reactive({
      mobile: '',
      password: '',
      phone: '',
      email: '',
      isLogin: 0,
      verificationCode: '',
      rememberMe: 1, // 1 为用户选择记住密码，将把账号密码存储在localstorage中.
      loginRoleType: route.query.loginRoleType || '2'
    })
    watch(() => route.query.loginRoleType, (newVal) => {
      param.loginRoleType = newVal || '2'
    })
    const loginRoleType = computed(() => param.loginRoleType)
    const getCoverImg = computed(() => {
      switch (loginRoleType.value) {
        case '0':
          return loginSuperAdminCover
        case '1':
          return loginAdminCover
        case '2':
          return loginCover
        default:
          return loginCover
      }
    })

    const getRadio = computed(() => {
      switch (loginRoleType.value) {
        case '0':
          return loginSuperAdminRadio
        case '1':
          return loginAdminRadio
        case '2':
          return loginRadio
        default:
          return loginRadio
      }
    })

    const getCenter = computed(() => {
      switch (loginRoleType.value) {
        case '0':
          return loginSuperAdminCenter
        case '1':
          return loginAdminCenter
        case '2':
          return loginCenter
        default:
          return loginCenter
      }
    })

    const getLogoImg = computed(() => {
      switch (loginRoleType.value) {
        case '0':
          return loginSuperAdminLogo
        case '1':
          return loginAdminLogo
        case '2':
          return loginLogo
        default:
          return loginLogo
      }
    })

    const getFooterImg = computed(() => {
      switch (loginRoleType.value) {
        case '0':
          return loginSuperAdminFooter
        case '1':
          return loginAdminFooter
        case '2':
          return loginFooter
        default:
          return loginFooter
      }
    })

    const getFooterText = computed(() => {
      switch (loginRoleType.value) {
          case '0':
            return loginSuperAdminFooterText
          case '1':
            return loginAdminFooterText
          case '2':
            return loginFooterText
          default:
            return loginFooterText
        }
    })

    const checkMobile = () => {
      let value = param.mobile
      if (!value) {
        return warnTip.value = '请填写手机号或邮箱'
      }
      const isDontMatchPhone = validateInfo.phone.some(item => !item.pattern.test(value))
      const isDontMatchEmail = validateInfo.email.some(item => !item.pattern.test(value))
      if (isDontMatchEmail && isDontMatchPhone) {
        return warnTip.value = '手机号或邮箱格式不正确'
      } else {
        return warnTip.value = ''
      }
    }

    const checkPassword = () => {
      let value = param.password
      if (!value) {
        return warnTip.value = '请填写密码'
      } else {
        return warnTip.value = ''
      }
    }

    const initAccount = () => {
      const rememberMe = +storage.get('rememberMe')
      // 如果不是0也不是1 说明之前没登录过
      if (rememberMe !== 0 && rememberMe !== 1) {
        param.rememberMe = 1
      } else {
        param.rememberMe = rememberMe
      }
      // 如果不记住密码 就不还原信息
      if (!rememberMe) return
      const mobile = storage.get('phone')
      const email = storage.get('email')
      const password = Base64.decode(storage.get('password'))
      if (mobile) {
        param.mobile = mobile
      }
      if (!mobile && email) {
        param.mobile = email
      }
      if (password) {
        param.password = password
      }
    }

    onMounted(() => {
      initAccount()
    })

    // 系统简单校验 
    const rules = {
      mobile: [
        { required: true, message: '请输手机号/邮箱', trigger: 'blur' },
        ...validateInfo.username
      ],
      verificationCode: [{ required: true, message: '验证码为必填项', trigger: 'change' }],
      password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      address: [{ required: true, message: '请输手机号/邮箱', trigger: 'blur' }],
      newPassword: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
    }
    // 执行登录
    const login = ref()
    // 切换登录方式
    const switchLogin = () => {
      warnTip.value = ''
      initAccount()
      if (loginRoleType.value === '2') {
        param.loginRoleType = '1'
      } else if (loginRoleType.value === '1') {
        param.loginRoleType = '2'
      }
    }
    const inputVerCode = (value) => {
      if (value) warnTip.value = ''
      param.verificationCode = value
    }

    const sendCodeWarn = (warnText) => {
      warnTip.value = warnText
    }

    const submitForm = async () => {
      if (!login.value) return
      try {
        await login.value.validate()
      } catch (err) {
        return
      }
      if (!param.mobile || !param.password) return warnTip.value = '有必填项未填写'
      if (loginRoleType.value !== '2' && !param.verificationCode) return warnTip.value = '验证码不能为空'
      const isNotMatchPhone = validateInfo.phone.some(item => !item.pattern.test(param.mobile))
      const isDontMatchEmail = validateInfo.email.some(item => !item.pattern.test(param.mobile))
      if (isDontMatchEmail && isNotMatchPhone) {
        return warnTip.value = '手机号或邮箱格式不正确'
      } else if (isNotMatchPhone) {
        param.email = param.mobile
        param.phone = undefined
      } else {
        param.phone = param.mobile
        param.email = undefined
      }

      try {
        isLoading.value = true
        await store.dispatch('user/login', param)
        await store.dispatch('app/fetchMenuList')
        // 登录成功
        try {
          await checkLink()
        } catch (e) {
          console.log(e)
        }
        await authentication()
        if (store.getters['app/getCurrentOrganizationId']) {
          batchDispatchOrganizationActions(store)
        }
        batchDispatchAppActions(store)
        ElMessage({ message: '登录成功', showClose: true, type: 'success' })
        if (storage.get('menuList').length === 1 && storage.get('menuList')[0].menuId === 486) {
          router.push(`/signature/list`)
        }
      } catch (err) {
        // 登录失败
        if (err.data) {
          if (err.data.code === 2) {
            // 此刻需要用户确定（顶掉其他端）
            dialogVisible.value = true
          } else {
            // showErrMsg(err, '登录')
            warnTip.value = err.data.msg
          }
        }
      } finally {
        isLoading.value = false
      }
    }

    const targetRoute = computed(() => store.getters['user/getTargetRoute'])

    const whiteList = ['/telemedicine/detail']

    const isPermissions = ref(100) // 0 正确、 1 远程会诊记录不存在、 -1 您没有访问权限、 100 没有要访问的地址

    const checkLink = async () => {
      if (whiteList.includes(targetRoute.value)) {
        const targetQuery = JSON.parse(store.getters['user/getTargetQuery']) || {}
        if (targetRoute.value === '/telemedicine/detail') {
          try {
            const {data} = await fetchRemoteDiagnosisTakeover({id: targetQuery.data})
            isPermissions.value = data.code
            if (data.code === 1) {
              ElMessage({ message: '远程会诊记录不存在', showClose: true, type: 'error' })
            }
          } catch (err) {
            console.error(err)
          }
        }
      }
    }


    const authentication = async () => {
      let roleId = store.state.user.roleIdList || []
      // 判断角色: 1=>管理员 12=>治疗师长身份 11=>治疗师身份
      let administrator = roleId.filter((item) => item === 1)[0]
      const jumpPassword = ref('')
      if (param.password === '123456') {    // 是否是初始密码
        jumpPassword.value = 'true'
      } else {
        jumpPassword.value = ''
      }
      if (administrator == undefined) {   // 如果不是管理员,请求默认的机构，然后直接登录
      const targetRoute = store.getters['user/getTargetRoute']
        try {
          const { data } = await getlastLoginOrganization()
          if (data && data.organization !== null) {
            let obj = data.organization
            // 非管理员
            store.commit('app/changeOrganization', obj.id)
            // 链接快速访问
            if (targetRoute === '/telemedicine/detail') {
              if (isPermissions.value === 0) {
                const targetQuery = JSON.parse(store.getters['user/getTargetQuery']) || {}
                router.push(targetRoute + '?treatmentId=' + targetQuery.treatmentId + '&patientId=' + targetQuery.patientId + '&activeName=' + targetQuery.activeName + '&data=' + targetQuery.data + '&isHiddenExpertTab=' + 1)
              } else {
                store.commit('user/updateIsShowNoPermissions', true)
                router.push('/')
              }           
            } else if (jumpPassword.value) {
              router.push({
                name: 'MyCenter',
                query: {
                  jp: 1
                }
              })
            } else {
              router.push('/')
            }
          } else {
            // 如果不存在上次登录的机构
            if (targetRoute === '/telemedicine/detail') {
              router.push({
                name: 'OrganizationCheckout',
                params: {
                  isPermissions: isPermissions.value
                }
              })
            } else {
              store.commit('app/changeOrganization', '')
              router.push({
                name: 'OrganizationCheckout',
                params: {
                  jp: jumpPassword.value,
                  isPermissions: isPermissions.value
                }
              })
            }
          }
        } catch (error) {
          store.commit('app/changeOrganization', '')
          router.push({
            name: 'OrganizationCheckout',
            params: {
              jp: jumpPassword.value,
              isPermissions: isPermissions.value
            }
          })
        }
      } else {
        // 管理员
        store.commit('app/changeOrganization', '')
        router.push({
          name: 'OrganizationCheckout',
          params: {
            jp: jumpPassword.value,
            isPermissions: isPermissions.value
          }
        })
      }
    }

    // 实现切换登录和忘记密码
    const goUserReset = () => {
      router.push({
        path: '/user-reset',
        query: {
          mobile: param.mobile
        }
      })
    }

    // 弹窗
    let dialogVisible = ref(false)
    const closeDialog = () => {
      dialogVisible.value = false
      isLoading.value = false
    }
    // 确定顶掉该账户在其他登录端的登录
    const agreement = () => {
      // 将isLogin的值设为 1
      param.isLogin = 1
      // 再次登录
      closeDialog()
      submitForm()
    }

    return {
      isLoading,
      param,
      rules,
      login,
      clientHeight,
      dialogVisible,
      router,
      submitForm,
      closeDialog,
      agreement,
      authentication,
      goUserReset,
      inputVerCode,
      sendCodeWarn,
      checkMobile,
      checkPassword,
      switchLogin,
      warnTip,
      getLogoImg,
      getRadio,
      getCenter,
      getCoverImg,
      getFooterImg,
      getFooterText,
      currentLocation,
      loginRoleType,
    }
  },
}

</script>

<style lang="scss" scoped>
@import "../../styles/var.scss";

.login-wrap {
  margin: -24px;
  height: 100vh;

  .ms-logo {
    z-index: 7;

    span {
      background: $--color-primary-light-3;
    }
    img {
      width: 60.3%;
      @media screen and (min-width: 1200px) {
        width: 52.3%;        
      }
    }
  }

  .ms-radio {
    img {
      position: absolute;
      right: 0;
      top: 0;
      width: 18.7%;
    }
  }

  .ms-center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    img {
      height: 100vh;
      display: block;
      margin: 0 auto;
    }
  }

  .ms-login {
    width: 100vw;
    overflow: hidden;

    .ms-cover {
      width: auto;
      position: relative;
      height: 100vh;
      background-size: cover;
      background-position: center;

      // .ms-logo {
      //   position: absolute;
      //   left: 17.3%;
      //   top: 11.5%;
      //   display: flex;

      //   img {
      //     height: 100%;
      //   }

      //   .ms-fris-icon {
      //     width: 32px;
      //     height: 32px;
      //   }

      //   .ms-fourier-icon {
      //     width: 120px;
      //     height: 32px;
      //   }

      //   span {
      //     margin: 0 24px;
      //     width: 1px;
      //     height: 32px;
      //   }
      // }

      .ms-bottom {
        background-size: 100% 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        img {
          width: 100%;
          @media screen and (min-width: 1200px) {
            width: 86%;
            display: block;
            margin: 0 auto;
          }
        }

        .author {
          font-size: $--font-size-extra-small;
          position: absolute;
          top: 55%;
          left: 0;
          right: 0;
          color: $--color-white-transparent-8;
          text-align: center;
          line-height: 17px;
          transform: translateY(-50%);
          word-wrap: normal;
        }
      }
    }

    .ms-form {
      background: $--color-white;
      height: 100vh;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      flex-flow: column;
      overflow-y: auto;

      .login-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ms-title {
        height: 33px;
        font-size: $--font-size-max;
        font-weight: 700;
        color: $--font-color-black;
        line-height: 33px;
        margin-bottom: 66px;
      }

      .warn-content {
        font-size: $--font-size-extra-small;
        color: $--color-danger;
        background: rgba(255, 67, 67, 0.1);
        border-radius: 4px;
        padding: 6px 0;
        width: 100%;
        text-align: center;
        position: absolute;
        top: -43px;
        line-height: 17px;
      }

      .ms-content-panel {
        padding-bottom: 40px;
        min-width: 50%;
        width: 320px;
        padding: 0;
        box-sizing: border-box;

        .ms-content {
          position: relative;
        }

        // flex: 1;
        .code-input-box {
          padding-left: 0;
        }

      }

      :deep(.ver-code-box) {
        .code-set-box span {
          background: unset;
        }

      }

      :deep(.el-checkbox__inner) {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
          position: relative;
          left: 0;
          top: -1px;
        }
      }
    }

    .records-ms-form {
      // min-height: 100%;
      // display: flex;
      // flex-flow: column;
      // justify-content: space-between;
    }
  }

  .ms-content-panel {
    .switch-login {
      color: $--color-primary;
      width: 100%;
      text-align: center;
      margin-top: 24px;
      cursor: pointer;
      font-size: $--font-size-base;
    }

    .ms-login {
      span {
        background: $--color-black-transparent-1;
      }
    }

    :deep(.el-form-item) {
      margin-bottom: 24px;

      .el-form-item__error {
        margin-top: 4px;
      }

      .el-input__inner {
        height: 42px;
        background: $--color-white;
        border-radius: 4px;
        // border: 1px solid $--border-color-light;
        border: 1px solid $--color-background-ivory;
        font-size: $--font-size-medium;
        color: $--font-color-black;
      }

      &.is-required::before {
        display: none;
      }

      .el-form-item__error.el-form-item__error--inline {
        line-height: 40px;
      }

      .el-checkbox__label {
        font-size: $--font-size-base;
        font-weight: 400;
        color: $--font-color-blue;
      }

      .ms-forget {
        position: absolute;
        right: 0;
        top: 0;
        font-size: $--font-size-base;
        font-weight: 400;
        color: $--font-color-grey;
        cursor: pointer;
      }
    }
  }
}

// 输入框 placeholder颜色
input:-ms-input-placeholder {
  color: $--font-color-grey;
}

/* Internet Explorer 10+ */

input::-webkit-input-placeholder {
  color: $--font-color-grey;
}

/* WebKit browsers */

input::-moz-placeholder {
  color: $--font-color-grey;
}

/* Mozilla Firefox 4 to 18 */

input:-moz-placeholder {
  color: $--font-color-grey;
}

/* Mozilla Firefox 19+ */
</style>

<style lang="scss">
@import "../../styles/var.scss";

.el-dialog.login-dialog {
  height: 230px;
  border-radius: $--border-radius-large;

  .el-dialog__header {
    text-align: center;
    padding: 32px 0 0 0;

    .el-dialog__title {
      font-size: $--font-size-large;
      font-weight: 700;
      color: $--font-color-black;
      line-height: 25px;
      height: 25px;
    }
  }

  .el-dialog__body {
    padding: 16px 32px 32px 32px;

    span {
      font-size: $--font-size-medium;
      font-weight: 400;
      color: $--font-color-grey;
      line-height: 22px;
    }
  }

  .el-dialog__footer {
    padding: 0 32px;
    text-align: center;

    .dialog-footer {
      .el-button:nth-child(1) {
        width: 96px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid $--color-primary;
        font-size: $--font-size-medium;
        font-weight: 400;
        color: $--color-primary;
      }

      .el-button:nth-child(2) {
        width: 96px;
        height: 40px;
        background: $--color-primary;
        border-radius: 4px;
        margin-left: 20px;
        font-size: $--font-size-medium;
        font-weight: 400;
        color: $--font-color-white;
      }
    }
  }
}
</style>