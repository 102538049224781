
import { ref, reactive, Ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import { sendResetPasswordCode, resetPassword } from '@/api/user'
import { showErrMsg, showSuccessMsg } from '@/utils/util'
import { validateInfo } from '@/utils/form'
import PutOnRecords from '@/components/PutOnRecords.vue'

export default {
  name: 'UserReset',
  components: {
    PutOnRecords
  },
  setup () {
    // 配置
    const router = useRouter()
    const resetForm = ref() as Ref<any>
    const route = useRoute()

    // 自定义表单校验
    const rulesForm = {
      regularEmail: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/,
      // regularPhone: /^1[3-9]\d{9}/,
      regularPhone: /^1(([378][\d])|(4[01456789])|([59][012356789])|(6[2567]))[\d]{8}$/,
      passwordTest: /^\w{4,16}$/, // 密码
    }

    const getCode = ref(false)

    onMounted(() => {
      console.log('route', route)
      formItem.address = route.query.mobile as string || ''
    })

    // 封装有一个校验手机和邮箱的方法 1指邮箱，2指手机，-1指输入有误
    const checkMobile = (rule: any, value: any) => {
      if (!value) return false
      if (rulesForm.regularEmail.test(value) || rulesForm.regularPhone.test(value)) {
        getCode.value = true
        return true
      }
      getCode.value = false
      return false
    }

    const validatorPassword = () => {
      if (!formItem.newPassword || !formItem.newPasswordAgain) return true
      if (formItem.newPassword === formItem.newPasswordAgain) return true
      return false
    }

    const passwordValidateRules = [
      ...validateInfo.password,
      { validator: validatorPassword, message: '两次输入密码不一致', trigger: 'blur' },
    ]

    // 系统简单校验 
    const rules = {
      address: [
        { required: true, message: '请输入手机号/邮箱' },
        { validator: checkMobile, message: '请输入有效的手机号或邮箱' }
      ],
      code: [{ required: true, message: '验证码为必填项', trigger: 'change' }],
      newPassword: [...passwordValidateRules, { required: true, message: '请输入新密码' }],
      newPasswordAgain: [...passwordValidateRules, { required: true, message: '请再次输入新密码' }],
    }

    const codeFormItem = ref() as Ref<any>
    const codeFocusHandle = () => {
      if (codeFormItem.value) {
        codeFormItem.value.clearValidate()
      }
    }

    // 忘记密码相关
    const formItem = reactive({
      type: '', // 1指邮箱, 2指手机
      address: '',
      newPassword: '',
      code: '',
      newPasswordAgain: ''
    })

    // 倒计时相关
    let endtime = 0
    // 是否允许获取验证码
    let isGetVerCode = ref<boolean>(true)


    const endTime = ref<number>()
    const remainTime = ref<number>()
    const timeOut = ref<any>()
    const hasSend = ref(false)
    // 获取验证码
    const getVerCode = async () => {
      try {
        await sendResetPasswordCode(formItem)
        isGetVerCode.value = false
        let nowtime = new Date().getTime()
        endTime.value = nowtime + 60000  // 定时100秒
        hasSend.value = true
        countDown()
      } catch (err) {
        if (err.data.msg) {
          ElMessage({
            message: err.data.msg,
            type: 'error',
            duration: 1500,
            showClose: true
          })
        } else {
          console.error(err)
        }
      }
    }

    // 倒计时
    const countDown = () => {
      let nowtime = new Date().getTime()
      if (endTime.value) {
        remainTime.value = Math.floor((endTime.value - nowtime) / 1000)
        if (remainTime.value <= 0) {
          clearTimeout(timeOut.value)  // 清除验证码的定时任务
          hasSend.value = false
          return
        }
        timeOut.value = setTimeout(() => {
          countDown()
        }, 500)
      }
    }

    // 实现切换登录和忘记密码
    const goLogin = () => {
      router.go(-1)
    }

    // 电话或邮箱验证
    let isPhone = ref<boolean>(true)
    const changeVerification = () => {
      isPhone.value = !isPhone.value
    }

    const passwordInputBlurHandle = () => {
      rules.newPassword = [...passwordValidateRules]
      rules.newPasswordAgain = [...passwordValidateRules]
      resetForm.value.validateField(['newPassword', 'newPasswordAgain'])
      rules.newPassword = [...passwordValidateRules, { required: true, message: '请输入新密码' }]
      rules.newPasswordAgain = [...passwordValidateRules, { required: true, message: '请再次输入新密码' }]
    }

    const submitForm = async () => {
      try {
        await resetForm.value.validate()
      } catch (err) {
        console.error(err)
        return
      }
      try {
        await resetPassword({
          address: formItem.address,
          password: formItem.newPassword,
          verificationCode: formItem.code
        })
        showSuccessMsg('重置密码')
        router.push('/login')
      } catch (err) {
        showErrMsg(err, '重置密码')
      }
    }


    return {
      passwordInputBlurHandle,
      codeFormItem,
      codeFocusHandle,
      resetForm,
      formItem,
      remainTime,
      rules,
      endtime,
      isGetVerCode,
      checkMobile,
      countDown,
      getVerCode,
      changeVerification,
      goLogin,
      submitForm,
      hasSend,
      validatorPassword,
      passwordValidateRules,
      router,
      getCode
    }
  },
}

