import { Module } from 'vuex'
import { GlobalProps } from './'
import { fetchSexList, fetchNationList, fetchMenuList, IFrisDictTypeData, fetchProjectTypeList, fetchShortLongList, fetchCostList, fetchNursingLevelList, fetchMarriageList, fetchSourceList, fetchUserStatusList, fetchNoticeStatusList, fetchIsReadList, fetchProjcetStatusList, fetchAssessTypeList, fetchUnitList, fetchRelationList, fetchProfessionList, fetchEqModelList, fetchPayStatusList, fetchOfficeList, fetchOrganizationList, IFrisOrganizationData, fetchPayTypeList } from "../api/app"
import storage from 'store'
import { mapDictType } from '@/utils/util'

export interface menuItem {
  menuId: number
  icon: string
  list?: menuItem[]
  name: string
  open?: boolean
  orderNum: number
  parentId: number
  parentName: string
  perms: string
  type: number
  url: string
}

export interface AppProps {
  collapse: boolean
  menuList: menuItem[]
  projectTypeList: IFrisDictTypeData[]
  shortLongList: IFrisDictTypeData[]
  costList: IFrisDictTypeData[]
  nursingLevelList: IFrisDictTypeData[]
  marriageList: IFrisDictTypeData[]
  sourceList: IFrisDictTypeData[]
  sexList: IFrisDictTypeData[]
  userStatusList: IFrisDictTypeData[]
  noticeStatusList: IFrisDictTypeData[]
  isReadList: IFrisDictTypeData[]
  projcetStatusList: IFrisDictTypeData[]
  assessTypeList: IFrisDictTypeData[]
  unitList: IFrisDictTypeData[]
  relationList: IFrisDictTypeData[]
  professionList: IFrisDictTypeData[]
  eqModelList: IFrisDictTypeData[]
  payStatusList: IFrisDictTypeData[]
  officeList: IFrisDictTypeData[]
  currentOrganizationId?: number  // 医院Id
  nationList: string[],
  selectableOrganizationList: IFrisOrganizationData[],
  payTypeList: IFrisDictTypeData[]
  sessionTimeout: boolean
}

const app: Module<AppProps, GlobalProps> = {
  namespaced: true,
  state: {
    collapse: false,
    menuList: storage.get('menuList'),
    projectTypeList: [],
    shortLongList: [],
    costList: [],
    nursingLevelList: [],
    marriageList: [],
    sourceList: [],
    sexList: [],
    currentOrganizationId: storage.get('currentOrganizationId'),
    nationList: [],
    userStatusList: [],
    noticeStatusList: [],
    isReadList: [],
    projcetStatusList: [],
    assessTypeList: [],
    unitList: [],
    relationList: [],
    professionList: [],
    eqModelList: [],
    payStatusList: [],
    officeList: [],
    selectableOrganizationList: [],
    payTypeList: [],
    sessionTimeout: false
  },
  mutations: {
    // 侧边栏折叠
    handleCollapse (state, data) {
      state.collapse = data
    },
    updateMenuList (state, menuList: menuItem[]) {
      state.menuList = menuList
    },
    changeOrganization (state, id: number) {
      state.currentOrganizationId = id
      storage.set('currentOrganizationId', id)
    },
    changeProjectTypeList (state, list: IFrisDictTypeData[]) {
      state.projectTypeList = list
    },
    changeShortLongList (state, list: IFrisDictTypeData[]) {
      state.shortLongList = list
    },
    changeCostList (state, list: IFrisDictTypeData[]) {
      state.costList = list
    },
    changeNursingLevelList (state, list: IFrisDictTypeData[]) {
      state.nursingLevelList = list
    },
    changeMarriageList (state, list: IFrisDictTypeData[]) {
      state.marriageList = list
    },
    changeSourceList (state, list: IFrisDictTypeData[]) {
      state.sourceList = list
    },
    changeSexList (state, list: IFrisDictTypeData[]) {
      state.sexList = list
    },
    changeNationList (state, list: string[]) {
      state.nationList = list
    },
    changeUserStatusList (state, list: IFrisDictTypeData[]) {
      state.userStatusList = list
    },
    changeNoticeStatusList (state, list: IFrisDictTypeData[]) {
      state.noticeStatusList = list
    },
    changeIsReadList (state, list: IFrisDictTypeData[]) {
      state.isReadList = list
    },
    changeProjcetStatusList (state, list: IFrisDictTypeData[]) {
      state.projcetStatusList = list
    },
    changeAssessTypeList (state, list: IFrisDictTypeData[]) {
      state.assessTypeList = list
    },
    changeUnit (state, list: IFrisDictTypeData[]) {
      state.unitList = list
    },
    changeRelationList (state, list: IFrisDictTypeData[]) {
      state.relationList = list
    },
    changeProfession (state, list: IFrisDictTypeData[]) {
      state.professionList = list
    },
    changeEqModelList (state, list: IFrisDictTypeData[]) {
      state.eqModelList = list
    },
    changePayStatusList (state, list: IFrisDictTypeData[]) {
      state.payStatusList = list
    },
    changeAppOfficeList (state, list: IFrisDictTypeData[]) {
      state.officeList = list
    },
    changeSelectableOrganizationList (state, list: IFrisOrganizationData[]) {
      state.selectableOrganizationList = list
    },
    changePayTypeList (state, list: IFrisDictTypeData[]) {
      state.payTypeList = list
    },
    changeSessionTimeout (state, data) {
      state.sessionTimeout = data
    },
  },
  getters: {
    getCurrentOrganizationId (state): string {
      return String(state.currentOrganizationId || '')
    },
    getCurrentOrganization (state) {
      if (!state.currentOrganizationId || !state.selectableOrganizationList || !state.selectableOrganizationList.length) {
        return
      }
      const organization = state.selectableOrganizationList.find(org => org.id === +(state.currentOrganizationId || -1))
      return organization
    },
    getProjectTypeList: (state) => mapDictType(state.projectTypeList),
    getShortLongList: (state) => mapDictType(state.shortLongList),
    getCostList: (state) => mapDictType(state.costList),
    getNursingLevelList: (state) => mapDictType(state.nursingLevelList),
    getMarriageList: (state) => mapDictType(state.marriageList),
    getSourceList: (state) => mapDictType(state.sourceList),
    getCostTypeName: (state) => {
      return (val: string) => {
        if (!val) return ''
        const active = state.costList.find(item => item.k === val)
        if (active) return active.val
        return ''
      }
    },
    getPayTypeList: (state) => {
      return (val: string) => {
        if (!val) return ''
        const active = state.payTypeList.find(item => item.k === val)
        if (active) return active.val
        return ''
      }
    },
  },
  actions: {
    async fetchMenuList (context) {
      try {
        const { data } = await fetchMenuList()
        storage.set('menuList', data.menuList)
        storage.set('permissions', data.permissions)
        context.commit('updateMenuList', data.menuList)
        context.commit('user/updatePermissions', data.permissions, { root: true })
        return data.menuList
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchProjectTypeList (context) {
      try {
        const { data } = await fetchProjectTypeList()
        context.commit('changeProjectTypeList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchShortLongList (context) {
      try {
        const { data } = await fetchShortLongList()
        context.commit('changeShortLongList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchCostList (context) {
      try {
        const { data } = await fetchCostList()
        context.commit('changeCostList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchNursingLevelList (context) {
      try {
        const { data } = await fetchNursingLevelList()
        context.commit('changeNursingLevelList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchMarriageList (context) {
      try {
        const { data } = await fetchMarriageList()
        context.commit('changeMarriageList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchSourceList (context) {
      try {
        const { data } = await fetchSourceList()
        context.commit('changeSourceList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchNationList (context) {
      try {
        const { data } = await fetchNationList()
        context.commit('changeNationList', data.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchSexList (context) {
      try {
        const { data } = await fetchSexList()
        context.commit('changeSexList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchUserStatusList (context) {
      try {
        const { data } = await fetchUserStatusList()
        context.commit('changeUserStatusList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchNoticeStatusList (context) {
      try {
        const { data } = await fetchNoticeStatusList()
        context.commit('changeNoticeStatusList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchIsReadList (context) {
      try {
        const { data } = await fetchIsReadList()
        context.commit('changeIsReadList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchProjcetStatusList (context) {
      try {
        const { data } = await fetchProjcetStatusList()
        context.commit('changeProjcetStatusList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchAssessTypeList (context) {
      try {
        const { data } = await fetchAssessTypeList()
        context.commit('changeAssessTypeList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchAppUnitList (context) {
      try {
        const { data } = await fetchUnitList()
        context.commit('changeUnit', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchAppRelationList (context) {
      try {
        const { data } = await fetchRelationList()
        context.commit('changeRelationList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchAppProfessionList (context) {
      try {
        const { data } = await fetchProfessionList()
        context.commit('changeProfession', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchEqModelList (context) {
      try {
        const { data } = await fetchEqModelList()
        context.commit('changeEqModelList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchPayStatusList (context) {
      try {
        const { data } = await fetchPayStatusList()
        context.commit('changePayStatusList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchAppOfficeList (context) {
      try {
        const { data } = await fetchOfficeList()
        context.commit('changeAppOfficeList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchOrganizationList (context) {
      try {
        const { data } = await fetchOrganizationList()
        context.commit('changeSelectableOrganizationList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchPayTypeList (context) {
      try {
        const { data } = await fetchPayTypeList()
        context.commit('changePayTypeList', data.page.list)
      } catch (err) {
        return Promise.reject(err)
      }
    }
  }
}

export default app
