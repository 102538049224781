
import frisServer from '../utils/frisServer'
import { AxiosResponse } from 'axios'
import store from '@/store/index'

export interface IFrisRemoteDiagnosisPatientsParams {
  type: number | string
}

export interface IFrisRemoteDiagnosisPatientsDataList {
  data: IFrisRemoteDiagnosisPatientsData[]
}

export interface IFrisRemoteDiagnosisPatientsData {
  remoteDiagnosisId: number
  initiateTime:string
  done: boolean 
  sex: number
  invitedDoctorName: string
  attendingDoctorName: string
  doctorName: string 
  functional: string 
  age: number 
  patientName: string 
  initiateOrgName: string 
  organizationId: number 
  type: string
  patientId: string
  treatmentId: string
  patientOrganizationId: number
}

export function fetchRemoteDiagnosisPatients (params: Partial<IFrisRemoteDiagnosisPatientsParams>): Promise<AxiosResponse<IFrisRemoteDiagnosisPatientsDataList>> {
  return frisServer({
    url: '/sys/remoteDiagnosis/patients',
    method: 'get',
    params: {
      ...params,
    }
  })
}

export interface IFrisRemoteDiagnosisRecordsParams {
  treatmentId: number | string
}

export interface IFrisRemoteDiagnosisRecordsDataList {
  data: IFrisRemoteDiagnosisRecordsData[]
}

export interface IFrisRemoteDiagnosisRecordsData {
  age: number
  attendingDoctorName: string
  doctorName: string
  done: boolean
  endTime: string
  functional: string
  initiateOrgName: string
  initiateTime: string
  invitedDoctorName: string
  organizationId: number
  patientName: string
  remoteDiagnosisId: number
  sex: number
  startTime: string
  type: string
}

export function fetchRemoteDiagnosisRecords (params: Partial<IFrisRemoteDiagnosisRecordsParams>): Promise<AxiosResponse<IFrisRemoteDiagnosisRecordsDataList>> {
  return frisServer({
    url: '/sys/remoteDiagnosis/records',
    method: 'get',
    params: {
      ...params,
    }
  })
}

export interface IFrisRemoteDiagnosisTakeoverParams {
  id: number | string
}

export interface IFrisRemoteDiagnosisTakeoverData {
  code: number
  data: string[]
  msg: string
}

export function fetchRemoteDiagnosisTakeover (params: Partial<IFrisRemoteDiagnosisTakeoverParams>): Promise<AxiosResponse<IFrisRemoteDiagnosisTakeoverData>> {
  return frisServer({
    url: '/sys/remoteDiagnosis/takeover',
    method: 'get',
    params: {
      ...params,
    }
  })
}

// 远程会诊接口
export function relationOrganization () {
  return frisServer({
   url: '/sys/relationOrganization/list',
   method: 'get',
   params: {
   organizationId: store.getters['app/getCurrentOrganizationId']
   }
  })
  }
  export function doctorPerformance (id:number) {
   return frisServer({
    url: '/sys/doctorPerformance/doctors',
    method: 'post',
    data:{organizationId:id}
   })
  }
  
  export interface IFrisRemoteDiagnosisParams {
   organizationId: number
   expertOrganizationId:number
   expertId: string 
   expertName:string
   treatmentId:string
   patientName:string
   patientId:string
   shares:string[]
  }
  
  export interface IFrisRemoteDiagnosisData {
   msg: number
  }
  
  // 发起后端接口
  export function remoteDiagnosis (params: IFrisRemoteDiagnosisParams): Promise<AxiosResponse<IFrisRemoteDiagnosisData>> {
   return frisServer({
    url: '/sys/remoteDiagnosis/initiate',
    method: 'post',
    data:params
   })
  }

  export interface IFrisreportMedicalRecordParams {
    organizationId?: number //
    mainAppeal:string
    nowHistory?:string
    pastHistory?:string
    allergyHistory?:string
    familyHistory?:string
    physiqueInspect?:string
    auxiliaryInspect?:string
    initDiagnosis:string
    treatmentProcess?:string
    treatmentAdvice?:string
    hospitalMedication?:string
    diseaseHistory?:string
    psychologyStatus?:string
    treatmentId?:number //
    patientId?:string  //
    id?:null
   }

   export interface IFrisreportMedicalRecordData {
    msg: string,
    reportMedicalRecordId: string
   }

  // 汇报病例新增
  export function reportMedicalRecord (params: IFrisreportMedicalRecordParams): Promise<AxiosResponse<IFrisreportMedicalRecordData>> {
    return frisServer({
     url: '/sys/reportMedicalRecord/save',
     method: 'post',
     data:params
    })
   }


   export interface IFrisgetReportMedicalRecordParams {
    treatmentId:string
    patientId:string
   }

   export interface IFrisgetReportMedicalRecordData {
    msg: string
    list: IFrisreportMedicalRecordParams[]
   }


  // 汇报病例查询
  export function getReportMedicalRecord (params: IFrisgetReportMedicalRecordParams): Promise<AxiosResponse<IFrisgetReportMedicalRecordData>> {
    return frisServer({
     url: '/sys/reportMedicalRecord/query',
     method: 'post',
     data:params
    })
   }