<template>
  <div
    :key="collapse"
    :class="['sidebar', collapse && 'collapsed']"
  >
    <div :class="['sidebar-cont', collapse && 'collapsed']">
      <div :class="['sidebar-container', collapse && 'collapsed']">
        <div
          class="sidebar-top"
          :class="popFlag && isNeedCheck ? '' : 'sidebar-top-border'"
        >
          <h1 v-if="!collapse">
            {{ title }}
          </h1>
          <i
            v-else
            class="iconfont icon-icon_zhankai"
            @click="toggleCollapse"
          />
          <span
            v-if="!collapse"
            class="sidebar-collapse"
            @click="toggleCollapse"
          >
            <i :class="`el-icon-arrow-${collapse ? 'right' : 'left'}`" />
          </span> 
          <div
            v-if="popFlag && isNeedCheck && hasClockInPermission('sys:user:clockIn')"
            class="sidebar-positions-clock"
            @click="chooseJobs"
          >
            <span v-if="!collapse">{{ roleName }}</span>
            <i class="iconfont icon-icon_yidong" />
          </div>
        </div>
        <el-menu
          :ref="el => menuEl = el"
          :key="counter"
          class="sidebar-el-menu"
          :default-active="currentRoute"
          :collapse="collapse"
          :collapse-transition="false"
          unique-opened
          router
        >
          <template v-for="item in menuRoutes">
            <template v-if="item.list">
              <el-sub-menu
                :key="item.menuId"
                :class="{ 'email': item.url === '/email' }"
                :index="String(item.menuId)"
                :url="item.url"
                popper-class="sidebar-menu-popper"
                :name="item.name"
              >
                <template #title>
                  <i :class="['iconfont', `icon-${item.icon}`]" />
                  <span>{{ item.name }}</span>
                  <span
                    v-if="item.url === '/email' && !collapse && messageCount > 0"
                    class="email-count"
                  >
                    <strong>{{ messageCount > 99 ? '99' : messageCount }}</strong>
                  </span>
                </template>
                <template v-for="subItem in item.list">
                  <el-sub-menu
                    v-if="subItem.list"
                    :key="subItem.menuId"
                    :index="String(subItem.menuId)"
                    popper-class="sidebar-menu-popper"
                    :name="subItem.name"
                  >
                    <template #title>
                      {{ subItem.name }}
                    </template>
                    <el-menu-item
                      v-for="threeItem in subItem.list"
                      :key="threeItem.menuId"
                      :class="getClass(threeItem)"
                      :index="formatUrl(threeItem.url)"
                      :name="threeItem.name"
                    >
                      {{ threeItem.name }}
                    </el-menu-item>
                  </el-sub-menu>
                  <el-menu-item
                    v-else
                    :key="subItem.menuId + 'item'"
                    :class="getClass(subItem)"
                    :index="formatUrl(subItem.url)"
                    :name="subItem.name"
                  >
                    {{ subItem.name }}
                  </el-menu-item>
                </template>
              </el-sub-menu>
            </template>
            <template v-else>
              <el-menu-item
                v-if="item.url !== '/organization/checkout' || (item.url === '/organization/checkout' && store.state.app.selectableOrganizationList && store.state.app.selectableOrganizationList.length > 1)"
                :key="item.menuId"
                :index="formatUrl(item.url)"
                :class="getClass(item)"
                :name="item.name"
              >
                <i
                  v-if="collapse"
                  :class="['iconfont', `icon-${item.icon}`]"
                />
                <template #title>
                  <i :class="['iconfont', `icon-${item.icon}`]" />
                  {{ item.name }}
                </template>
              </el-menu-item>
            </template>
          </template>
        </el-menu>
      </div>
      <user-avatars
        class="sidebar-user"
        :collapse="collapse"
      />
    </div>

    <submit-box
      v-model:visible="isVisible"
      title="请选择治疗的岗位"
      @submit="handlePunchCard"
      @update:visible="handleClose"
    >
      <fr-selector
        v-model:active="roleId"
        :props="{ ...punchPositionProps }"
      />
    </submit-box>
  </div>
</template>

<script>
import { computed, onMounted, watchEffect, ref, nextTick, watch} from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import UserAvatars from "./UserAvatars.vue"
import { ElMessage } from 'element-plus'
import { showErrMsg } from '@/utils/util'
import { fetchTodayCheck, fetchSelectTreatmentTeamList, fetchCheckincheck, fetchNeedCheckIn } from '@/api/organization'
import { dfsBy, hasPermission as hasClockInPermission } from '@/utils/util'
import storage from 'store'
import { cloneDeep, differenceWith } from 'lodash-es'

export default {
  components: {
    UserAvatars
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const counter = ref(1)
    const menuEl = ref()

    const currentRoute = computed(() => {
      if (route.path !== '/telemedicine/detail') return route.path
      return '/telemedicine/list'
    })

    const store = useStore()

    const collapse = computed(() => store.state.app.collapse)
    const toggleCollapse = () => {
      store.commit('app/handleCollapse', !collapse.value)
    }

    const title = computed(() => {
      const organization = store.getters['app/getCurrentOrganization'] || { name: '' }
      let name = organization.name.slice(0, 14)
      if (collapse.value) {
        return name.slice(0, 2)
      }
      return name
    })

    function hasPermission (permission, route) {  
      if (route && route.perms) {
        let flag = false
        for (let i = 0, len = permission.length; i < len; i++) {
          flag = route.perms === permission[i]
          if (flag) {
            return true
          }
        }
        return false
      }
      return true
    }

    function filterAsyncRouter (routerMap, roles) {
      if (!Array.isArray(routerMap)) return []
      const accessedRouters = routerMap.filter(route => {
        if (hasPermission(roles, route)) {
          if (route.list && route.list.length) {
            route.list = filterAsyncRouter(route.list, roles).sort((a, b) => a.orderNum - b.orderNum)
          }
          return true
        }
        return false
      })
      return accessedRouters
    }

    const menuRoutes = computed(() => filterAsyncRouter(store.state.app.menuList, store.state.user.permissions))

    watchEffect(async () => {

      if (collapse.value && counter.value) {
        // 修复二级菜单点击后不会消失的问题
        await nextTick()
        const menuPopper =  document.querySelectorAll('.sidebar-menu-popper')
        if (menuPopper && menuPopper.length) {
          menuPopper.forEach(el => el.addEventListener('click', () => {
            counter.value++
          }))
        }
      }
    })

    const organizationId = computed(() => store.getters['app/getCurrentOrganizationId'])

    watch(() => organizationId.value, async () => {
      if (organizationId.value !== '') {
        await getJobsList()
        await needCheckIn()
        await judgeAuthority()
      }
    })

    onMounted(async () => {
      const width = document.body.clientWidth
      if (width <= 1024) {
        store.commit('app/handleCollapse', true)
      }
      if (organizationId.value !== '') {
        await getJobsList()
        await needCheckIn()
        await judgeAuthority()
      }
    })

    const formatUrl = (url) => {
      if (url.startsWith('/')) {
        return url
      } else {
        return `/${url}`
      }
    }

    const currentActive = ref('')

    const routeList = computed(() => {
      const list = []
      dfsBy({ list: menuRoutes.value }, (node) => list.push(node.url), 'list')
      return list.filter(item => item)
    })

    const isRouteList = (url) => routeList.value.includes(url)

    const getClass = (item) => {
      const noneActiveList = [
        '/my-center'
      ]
      // 如果不是内页，则不显示active
      if (noneActiveList.some(route => route === currentRoute.value)) {
        return ''
      }
      if (isRouteList(item.url) && currentRoute.value === item.url) {
        currentActive.value = item.url
        return 'is-active'
      } else if (currentActive.value === item.url) {
        return 'is-active'
      }
      return ''
    }

    const messageCount = computed(() => store.state.message.count || 0)

    const initDefaultActive = async () => {
      const whiteList = ['/organization/checkout']
      const noPasses = whiteList.indexOf(currentActive.value)
      if (currentActive.value && noPasses) return
      if (route.meta.defaultMenu) {
        if (typeof route.meta.defaultMenu === 'function') {
          currentActive.value = route.meta.defaultMenu(currentRoute.value, menuRoutes.value)
        } else {
          currentActive.value = route.meta.defaultMenu
        }
      }
      if (currentActive.value && menuEl.value) {
        let menuId
        dfsBy({ list: menuRoutes.value }, (node) => {
          if (node.list && node.list.some(item => item.url === currentActive.value)) {
            menuId = node.menuId
          }
        }, 'list')
        if (menuId) {
          menuEl.value.open(menuId)
        }
      }
      findUndoMenu()
    }

    const findUndoMenu = () => {
      const totalRoutes = router.getRoutes().filter(route => {
        let shouldAdd = true
        // 如果是 DefaultView 则不算路由
        if (route.components.default?.name === 'DefaultView') {
          shouldAdd = false
        }
        const excludePrefixList = [
          '/maintainSystem',
          '/sys',
          '/maintainOffice',
          '/maintainTreat',
          '/gauge/table-',
          '/gauge/:projectId/:methodId',
        ]
        const excludeList = [
          '/recovery/meeting/list/:userType?',
          '/login',
          '/user-reset',
          '/screen',
          '/'
        ]
        if (excludePrefixList.some(item => route.path.startsWith(item))) {
          shouldAdd = false
        }
        if (excludeList.some(item => route.path === item)) {
          shouldAdd = false
        }
        // 去掉不需要的路由
        return shouldAdd
      })
      const routesNotInMenu = differenceWith(totalRoutes, [
        ...routeList.value,
        '/schedule/therapist/:master?',
        '/my-center',
        '/performance/doctor',
        '/email/send-news/:emailId?',
        '/recovery/list/:type?'
      ], (a, b) => a.path === b)
      let logMenu = cloneDeep(routesNotInMenu).map(item => item.path)
      routesNotInMenu.forEach(item => {
        // 通过配置点亮默认menu
        if (item.meta.defaultMenu) {
          logMenu = logMenu.filter(path => path !== item.path)
        }
      })
      console.log('undoMenuList', logMenu)
    }

    watch([menuRoutes, currentRoute], initDefaultActive)

    const roleIdList = computed(() => store.state.user.roleIdList) || []

    const checkRoleId = (id) => {
      return roleIdList.value.findIndex(item => item === id) !== -1
    }

    const popFlag = computed(() => {
      if (roleIdList.value && roleIdList.value.length > 0) {
        if ((checkRoleId(10) || checkRoleId(11) || checkRoleId(12)) && !checkRoleId(1)) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    })
    const roleName = ref('请选择')
    const role_name = ref('请选择')
    const punchPositionList = ref([])
    const roleId = ref()
    const isNeedCheck = ref(false)

    const needCheckIn = async () => {
      try {
        const { data } = await fetchNeedCheckIn()
        isNeedCheck.value = data.data
      } catch (e) {
        console.log(e)
      }
    }

    const judgeAuthority = async () => {
      const isFirst = storage.get('isFirst')
      try {
        const { data } = await fetchTodayCheck()
        if (data.data.id === null && popFlag.value && isNeedCheck.value && isFirst) {
          // 自动弹出
          chooseJobs()
        } else {
          roleName.value = data.data.name || '请选择'
          roleId.value = data.data.id
          store.commit('user/updateLastCheck', data.data.id)
        }
      } catch (e) {
        console.log(e)
      }
    }

    const getJobsList = async () => {
      try {
        const { data } = await fetchSelectTreatmentTeamList()
        roleId.value = data.data.defaultTeam.id
        punchPositionList.value = data.data.teamList
      } catch (err) {
        return Promise.reject(err)
      }
    }

    const getRoleName = () => {
      punchPositionList.value.forEach(item => {
        if (item.id === roleId.value) {
          role_name.value = item.name
        }
      })
    }

    watch(roleId.value, () => {
      getRoleName()
      roleName.value = role_name.value
    })
  
    const isVisible = ref(false)
    const punchPositionProps = {
      label: '',
      list: {
        list: computed(() => punchPositionList.value || []),
        label: 'name',
        value: 'id'
      }
    }

    const chooseJobs = async () => {
      isVisible.value = true
    }

    const handlePunchCard = async () => {
      getRoleName()
      const params = {
        treatmentTeamId: roleId.value, 
        name: roleName.value
      }
      try {
        await fetchCheckincheck(params)
        ElMessage({ message: '岗位打卡成功！', showClose: true, type: 'success' })
        roleName.value = role_name.value
        store.commit('user/updateLastCheck', params.treatmentTeamId)
      } catch (err) {
        showErrMsg(err, '岗位打卡')
      } finally {
        isVisible.value = false
        storage.set('isFirst', false)
      }
    }

    const handleClose = () => {
      storage.set('isFirst', false)
    }

    return {
      menuEl,
      getClass,
      currentActive,
      messageCount,
      store,
      formatUrl,
      title,
      toggleCollapse,
      menuRoutes,
      currentRoute,
      collapse,
      counter,
      isVisible,
      roleId,
      roleName,
      punchPositionProps,
      chooseJobs,
      handlePunchCard,
      handleClose,
      popFlag,
      isNeedCheck,
      hasClockInPermission
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/var.scss';
@import '../styles/mixins.scss';
.sidebar{
  width: 150px;
  height: 100vh;
  flex-shrink: 0;
  flex-grow: 0;
  transition: all .3s;
  z-index: 100;
  &.collapsed {
    width: 64px;
  }
  .sidebar-cont{
    height: 100vh;
    width: 150px;
    display: flex;
    flex-flow: column;
    background-color: $--color-primary;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-user-select: none;
    transform: translate3d(0, 0, 0);
    transition: all .3s;
    &.collapsed {
      width: 64px;
    }
    &::-webkit-scrollbar{
      display: none;
    }
  }
  .sidebar-container{
    box-sizing: border-box;
    background-color: $--color-primary;
    flex-shrink: 0;
    flex-grow: 0;
    transform: translate3d(0, 0, 0);
    transition: all .3s;
    z-index: 7;
    width: 150px;
    &.collapsed {
      width: 64px;
      .sidebar-top{
        h1 {
          width: 38px;
        }
      }
      :deep(.el-menu) {
        &.sidebar-el-menu{
          padding-left: 14px;
          width: 64px;
          padding-right: 14px;
          & > .el-menu-item, & > .el-sub-menu > .el-sub-menu__title {
            border-radius: $--border-radius-primary;
            padding-left: 0 !important;
            .iconfont{
              position: absolute;
              left: 8px;
            }
          }
        }
        .iconfont {
          font-size: $--font-size-extra-large;
        }
      }
      .el-sub-menu.is-active {
        background: $--color-white !important;
        border-radius: $--border-radius-primary;
        .iconfont {
          color: $--color-primary !important;
        }
      }
      .el-sub-menu .el-menu-item {
        min-width: auto;
      }
    }
    .sidebar-top{
      margin: 0 16px;
      padding: 27px 0;
      line-height: 20px;
      margin-bottom: 18px;
      color: $--color-white;
      text-align: center;
      position: relative;
      &.sidebar-top-border {
        border-bottom: 1px solid $--color-primary-light-3;
      }
      h1{
        font-size: $--font-size-medium;
        font-weight: 700;
        margin: 0;
        position: relative;
        width: 118px;
      }
      .sidebar-collapse{
        display: block;
        position: absolute;
        top: 19px;
        right: -16px;
        transform: translateX(50%);
        background-color: $--color-white-transparent-3;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        line-height: 32px;
        cursor: pointer;
        i {
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .sidebar-positions-clock {
        margin-top: 16px;
        margin-bottom: -27px;
        width: -webkit-fill-available;
        height: 36px;
        background: $--color-black-transparent-1;
        box-shadow: 0px 2px 6px 0px $--color-black-transparent-1;
        border-radius: $--border-radius-primary;
        border: 1px solid $--font-color-white;
        padding: 0 8px;
        display: flex;

        span {
          padding: 0 8px 0 0;
          width: -webkit-fill-available;
          font-weight: 400;
          color: $--color-white;
          line-height: 36px;
          font-size: $--font-size-base;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }

        i{
          font-size: $--font-size-large;
          display: block;
          width: 18px;
          height: 18px;
          margin: 10px 0;
          transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          /* Internet Explorer 9*/
          -moz-transform: rotate(90deg);
          /* Firefox */
          -webkit-transform: rotate(90deg);
          /* Safari 和 Chrome */
          -o-transform: rotate(90deg);
        }
      }

      .icon-icon_zhankai{
        font-size: 20px;
        cursor: pointer;
        padding: 20px;
        margin: -20px;
      }
    }
    :deep(.el-menu){
      border-right: none;
      background-color: $--color-primary;
      box-sizing: border-box;
      .el-sub-menu.email {
        .email-count{
          position: absolute;
          background-color: $--color-danger;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          right: 6px;
          z-index: 10;
          strong {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: $--color-white;
            font-size: $--font-size-extra-small;
          }
        }
      }
      &.sidebar-el-menu{
        padding-left: 8px;
      }
      .el-sub-menu__icon-arrow{
        right: 8px;
        top: 18px;
        font-size: $--font-size-base;
      }
      .el-sub-menu__title{
        display: flex;
        align-items: center;
      }
      .el-menu-item, .el-sub-menu > .el-sub-menu__title, .el-sub-menu__icon-arrow, .el-menu-item i.iconfont {
        color: $--color-white;
        &.is-active, &:hover, &:focus, &:active{
          color: $--color-primary;
          i.iconfont, & > .el-sub-menu__icon-arrow {
            color: $--color-primary;
          }
        }
      }
      & > .el-menu-item, & > .el-sub-menu > .el-sub-menu__title {
        border-radius: $--border-radius-primary 0px 0px $--border-radius-primary;
        height: 36px;
        padding-left: 36px !important;
        padding-right: 0;
        line-height: 36px;
        position: relative;
        & > .iconfont {
          position: absolute;
          top: 50%;
          left: 8px;
          transform: translateY(-50%);
          color: $--color-white;
          font-size: 20px;
        }
        &.is-active {
          background: $--color-white !important;
        }
      }
      & > .el-sub-menu.sec-action > .el-sub-menu__title, & > .el-menu-item.sec-action {
        color: $--color-primary !important;
        background: $--color-white !important;
        & > i, & > div > i {
          color: $--color-primary !important;
        }
      }
    }
  }
  .sidebar-user{
    &.collapsed {
      width: 64px;
    }
  }
}
</style>

<style lang="scss">
@import '../styles/mixins.scss';
.sidebar-menu-popper{
  .el-menu--popup{
    max-height: 90vh;
    overflow-y: auto;
    @include scroll-bar;
  }
}
</style>