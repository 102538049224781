import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-9306d5c4"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "home-container"
};
const _hoisted_2 = {
  class: "item-content"
};
const _hoisted_3 = {
  class: "grid"
};
const _hoisted_4 = {
  class: "amount"
};
const _hoisted_5 = {
  class: "title"
};
const _hoisted_6 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");

  const _component_el_row = _resolveComponent("el-row");

  const _directive_action = _resolveDirective("action");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "nav-list",
    gutter: 20
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnLIstanbul, item => {
      return _withDirectives((_openBlock(), _createBlock(_component_el_col, {
        key: item.id,
        xs: 12,
        sm: 8,
        md: 8,
        lg: 6,
        xl: 4,
        onClick: $event => _ctx.handleClick(item.id)
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: _normalizeClass(['outer', item.class]),
          style: _normalizeStyle(item.style)
        }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(item.amount), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1), _createElementVNode("img", {
          class: "item-icon",
          src: (_ctx.frisUrlPrefix || '') + item.iconUrl,
          alt: ""
        }, null, 8, _hoisted_6)])])], 6)]),
        _: 2
      }, 1032, ["onClick"])), [[_directive_action, item.permission]]);
    }), 128))]),
    _: 1
  })]);
}