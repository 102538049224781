import { ref, computed, onMounted, provide, watch } from "vue";
import { useStore } from "vuex";
import GlobalSidebar from "@/components/GlobalSidebar.vue";
import { useRouter, useRoute } from 'vue-router'; // import enLocale from 'element-plus/lib/locale/lang/en'

import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { batchDispatchOrganizationActions, batchDispatchAppActions, timeOutLogin } from '@/utils/util';
import PutOnRecords from '@/components/PutOnRecords.vue'; // session_timeout.png

export default {
  components: {
    GlobalSidebar,
    // [ElConfigProvider.name]: ElConfigProvider,
    PutOnRecords
  },

  setup() {
    const store = useStore();
    const collapse = computed(() => store.state.app.collapse);
    const appContentRef = ref(null);
    const router = useRouter();
    const route = useRoute();
    const locale = ref(zhCn);
    onMounted(() => {
      if (store.state.user.token) {
        batchDispatchOrganizationActions(store);
        batchDispatchAppActions(store);

        if (!store.state.message.isConnect) {
          store.dispatch('message/connectMQ');
        }
      }
    });

    const scrollTo = poisition => {
      appContentRef.value && appContentRef.value.scrollTo({ ...poisition,
        behavior: 'smooth'
      });
    };

    provide('scrollTo', scrollTo);
    provide('frFormItemType', 'form'); // const locale = ref(zhLocale)
    // const changeLocale = (type: 'zh-cn' | 'en') => {
    //   locale.value = type === 'zh-cn' ? zhLocale : enLocale
    // }
    // provide('changeLocale', changeLocale)

    onMounted(() => {
      showMessage.value = !!store.state.message.message;
    });
    watch([() => store.state.message.message, () => store.state.user.token, () => store.state.message.count], ([message, token, messageCount]) => {
      if (message && token && messageCount && messageCount > 0) {
        showMessage.value = true;
      } else {
        showMessage.value = false;
      }
    });
    const isShowSideBar = computed(() => !route.meta.hideSlider && store.getters['app/getCurrentOrganizationId']);
    const userId = computed(() => store.state.user.userId);
    const count = computed(() => store.state.message.count);

    const messageClickHandle = () => {
      if (count.value === 1 && store.state.message.message) {
        router.push(`/email/news-info/${store.state.message.message.id}`);
      } else {
        router.push(`/email/my-news`);
      }

      showMessage.value = false;
    };

    const showMessage = ref(false);
    const timefilter = ref();
    const timefilterTime = ref(0);
    const timeOutLoginFlag = ref(true);
    const sessionTimeoutVisible = computed(() => {
      const keepLogin = store.state.user.keepLogin;

      if (!keepLogin) {
        return store.state.app.sessionTimeout;
      } else {
        return false;
      }
    });
    const permissionsVisible = computed(() => store.state.user.isShowNoPermissions);

    const timefilterHadnle = () => {
      timefilter.value = setTimeout(() => {
        timefilterTime.value += 1;

        if (timefilterTime.value === 300) {
          timeOutLoginFlag.value = true;
        } else {
          timefilterHadnle();
        }
      }, 1000);
    };

    const resetTimeoutHndle = () => {
      if (!timeOutLoginFlag.value) return;
      const isLoginPage = window.location.href.indexOf('/login'); //  登录页

      const isUserResetPage = window.location.href.indexOf('/user-reset'); //  忘记密码页

      if (isLoginPage !== -1 || isUserResetPage !== -1) return;
      timefilterTime.value = 0;
      window.clearTimeout(timefilter.value);
      timefilterHadnle();
      timeOutLogin(3600000, 1000);
      timeOutLoginFlag.value = false;
    };

    const loginOutHandle = () => {
      store.commit('app/changeSessionTimeout', false);
      store.dispatch('user/logout');
    };

    const permissionsCloseHandle = () => {
      store.commit('user/updateIsShowNoPermissions', false);
    };

    return {
      isShowSideBar,
      userId,
      count,
      showMessage,
      collapse,
      appContentRef,
      messageClickHandle,
      locale,
      router,
      resetTimeoutHndle,
      sessionTimeoutVisible,
      permissionsVisible,
      loginOutHandle,
      permissionsCloseHandle
    };
  }

};