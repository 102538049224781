import { Ref, ref, computed, ComputedRef } from 'vue'
import { getFilteredList } from '@/utils/filter'

function useFilter<T, U extends keyof T> (list: Ref<T[]> | ComputedRef<T[]>, key: U) {
  const filterStr = ref<string>('')

  // const filteredList = ref([]) as Ref<T[]>

  const filterMethod = (val: string) => {
    filterStr.value = val
    // if (!val) {
    //   filteredList.value = list.value
    // } else {
    //   filteredList.value = getFilteredList(list.value, val, key)
    // }
  }

  const selectProps: {
    filterable: boolean | Ref<boolean>,
    filterMethod: (val: string) => void
  } = {
    filterable: true,
    filterMethod: filterMethod,
  }

  const filteredList = computed(() => {
    if (filterStr.value) {
      return getFilteredList(list.value, filterStr.value, key)
    } else {
      return list.value
    }
  })

  const clearFilterStr = () => {
    filterStr.value = ''
  }

  return {
    selectProps,
    filteredList,
    clearFilterStr
  }
}

export default useFilter