import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import storage from 'store'
import { action } from './directives'
// import { i18n } from './locales'
import elementPlus from './plugins/element'
import components from '@/components/core/install'
import './plugins/element-variables.scss'
import './styles/index.scss'
import './styles/tailwind.css'
import './plugins/iconfont.css'
import './font/font'
import print from 'vue3-print-nb'
import { exportVersionInfo } from './version'
import '@vueup/vue-quill/dist/vue-quill.core.css'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import '@vueup/vue-quill/dist/vue-quill.bubble.css'

exportVersionInfo()

const app = createApp(App)

app
  .use(store)
  .use(router)
  .use(elementPlus)
  .use(components)
  .use(print)

app.config.globalProperties.$ELEMENT = {
  size: storage.get('appSize') || 'medium'
}

// Action 权限指令
// 指令用法：
//  在需要控制 action 级别权限的组件上使用 v-action="sys:time:delete" , 如下：
//  <el-button v-action="add">添加用户</el-button>
//  <el-button v-action="delete">删除用户</el-button>
//  v-action 支持的用法：
//  v-action="'sys:time:delete'"
//  v-action="['sys:time:add', 'sys:time:delete']"
//  v-action.any="['sys:time:add', 'sys:time:delete']"
//  v-action="{ 'sys:time:delete': true, 'sys:time:add': false }"
app.directive('action', action)

app.mount('#app')
