import { isObject } from 'lodash-es'

export type IFrisHasPermissionParams = string | string[] | { [propName: string]: boolean }

export function hasPermission (permission: IFrisHasPermissionParams, permissionList: string[], arrayAction: 'any' | 'all' = 'all') {
  // 如果权限是空的 则返回有权限
  if (permission === undefined || permission === null || permission === '') return true
  // 如果没有权限列表 直接返回没有权限
  if (!permissionList.length) return false
  // 如果是字符串 直接查找是否存在
  if (typeof permission === 'string') {
    return permissionList.includes(permission)
  }
  // 如果是数组 需要判断是 any 或者 all
  if (Array.isArray(permission)) {
    if (arrayAction === 'any') {
      // 查找是否存在某个权限是有的 有直接返回
      return permission.some((per: string) => {
        return permissionList.includes(per)
      })
    } else if (arrayAction === 'all') {
      // 查找某个权限是否没有 如果没有则返回没有权限
      return !permission.some((per: string) => {
        return !permissionList.includes(per)
      })
    }
  }
  //
  if (isObject(permission)) {
    const per = Object.keys(permission)
    // eslint-disable-next-line
    // @ts-ignore
    return !per.some(p => !(permission[p] ? permissionList.includes(p) : !permissionList.includes(p)))
  }
}
