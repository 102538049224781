let frisBaseUrl: string
let dasBaseUrl: string
let dasClient: string
let MQUrl: string
let frisUrlPrefix: string
let subFrisBaseUrl: string // firs 子系统
let subFrisDomain: string // iframe 跳转域名

const FIRS_ASSETS_PREFIX = '' //  所有文件回显的前缀控制

if (typeof window.frisBaseUrl === 'string') {
  // 客户自定义 url
  frisBaseUrl = window.frisBaseUrl
} else if (process.env.VUE_APP_ENV === 'dev') {
  frisBaseUrl = 'http://127.0.0.1:8082/fftai-kf'
} else if (process.env.NODE_ENV === 'development') {
  frisBaseUrl = '/fftai-kf'
} else if (process.env.VUE_APP_ENV === 'qa') {
  frisBaseUrl = '//qafris.fftai.com/fftai-kf'
} else if (process.env.VUE_APP_ENV === 'trial') {
  frisBaseUrl = '//trialfris.fftai.com/fftai-kf'
} else if (process.env.VUE_APP_ENV === 'prod') {
  frisBaseUrl = '//fris.fftai.com/fftai-kf'
} else if (process.env.VUE_APP_ENV === 'uat') {
  frisBaseUrl = '//uatfris.fftai.com/fftai-kf'
} else if (process.env.VUE_APP_ENV === 'zhqa') {
  frisBaseUrl = '//101.132.112.36:8082/fftai-kf'
} else if (process.env.VUE_APP_ENV === 'uati') {
  frisBaseUrl = '//uatfris.fftai.com/fftai-kf'
} else if (process.env.VUE_APP_ENV === 'uatp') {
  frisBaseUrl = '//trialfris.fftai.com/fftai-kf'
} else {
  frisBaseUrl = '//devfris.fftai.com/fftai-kf'
  // console.error('未知的环境，无法获取frisBaseUrl')
}

if (typeof window.frisUrlPrefix === 'string') {
  // 客户自定义 url
  frisUrlPrefix = window.frisUrlPrefix || ''
} else {
  frisUrlPrefix = process.env.VUE_APP_URL_PREFIX || ''
}

if (typeof window.dasBaseUrl === 'string') {
  // 客户自定义 url
  dasBaseUrl = window.dasBaseUrl
} else if (process.env.VUE_APP_ENV === 'dev') {
  dasBaseUrl = 'http://127.0.0.1:8082/das-api'
} else if (process.env.NODE_ENV === 'development') {
  // 本地跑的
  dasBaseUrl = '//das.fftai.com/das-api'
} else if (process.env.VUE_APP_ENV === 'qa') {
  dasBaseUrl = '//qafris.fftai.com/das-api'
} else if (process.env.VUE_APP_ENV === 'trial') {
  dasBaseUrl = '//qafris.fftai.com/das-api'
} else if (process.env.VUE_APP_ENV === 'prod') {
  dasBaseUrl = '//das.fftai.com/das-api'
} else if (process.env.VUE_APP_ENV === 'uat') {
  dasBaseUrl = '//47.103.107.133:8084/das-api'
} else if (process.env.VUE_APP_ENV === 'uati') {
  dasBaseUrl = '//47.103.107.133:8088/das-api'
} else if (process.env.VUE_APP_ENV === 'uatp') {
  dasBaseUrl = '//47.103.107.133:8088/das-api'
} else {
  dasBaseUrl = '//devfris.fftai.com/das-api'
  // console.error('未知的环境，无法获取dasBaseUrl')
}

if (typeof window.dasClient === 'string') {
  // 客户自定义 url
  dasClient = window.dasClient
} else if (process.env.VUE_APP_ENV === 'dev') {
  dasClient = 'http://127.0.0.1:8082/das'
} else if (process.env.NODE_ENV === 'development') {
  dasClient = '//devfris.fftai.com/das'
} else if (process.env.VUE_APP_ENV === 'qa') {
  dasClient = '//qafris.fftai.com/das'
} else if (process.env.VUE_APP_ENV === 'trial') {
  dasClient = '//qafris.fftai.com/das'
} else if (process.env.VUE_APP_ENV === 'prod') {
  dasClient = '//das.fftai.com'
} else if (process.env.VUE_APP_ENV === 'uat') {
  dasClient = '//qafris.fftai.com/das'
} else if (process.env.VUE_APP_ENV === 'uati') {
  dasClient = '//qafris.fftai.com/das'
} else if (process.env.VUE_APP_ENV === 'uatp') {
  dasClient = '//qafris.fftai.com/das'
} else {
  dasClient = '//devfris.fftai.com/das'
  // console.error('未知的环境，无法获取dasClient')
}

if (typeof window.MQUrl === 'string') {
  // 客户自定义 url
  MQUrl = window.MQUrl
} else if (process.env.VUE_APP_ENV === 'dev') {
  MQUrl = 'wss://devfris.fftai.com/websocket/'
} else if (process.env.NODE_ENV === 'development') {
  MQUrl = 'wss://101.133.149.215:61614'
} else if (process.env.VUE_APP_ENV === 'qa') {
  MQUrl = 'wss://qafris.fftai.com/websocket/'
} else if (process.env.VUE_APP_ENV === 'trial') {
  MQUrl = 'wss://trialfris.fftai.com/websocket/'
} else if (process.env.VUE_APP_ENV === 'uat') {
  MQUrl = 'wss://uatfris.fftai.com/webSocket/'
} else if (process.env.VUE_APP_ENV === 'uati') {
  MQUrl = 'wss://uatfris.fftai.com/webSocket/'
} else if (process.env.VUE_APP_ENV === 'uatp') {
  MQUrl = 'wss://trialfris.fftai.com/websocket/'
} else if (process.env.VUE_APP_ENV === 'prod') {
  MQUrl = 'wss://fris.fftai.com/webSocket/'
} else {
  MQUrl = 'wss://101.133.149.215:61614'
  // console.error('未知的环境，无法获取MQUrl')
}

//   firs量表子系统 目前与firs
if (typeof window.subFrisBaseUrl === 'string') {
  // 客户自定义 url
  subFrisBaseUrl = 'https://subfris.rezkonn.com/dev/'

  subFrisDomain = 'https://qafris.fftai.com/subFris/'
  console.log('进来了1', subFrisBaseUrl)
} else if (process.env.NODE_ENV === 'development') {
  subFrisBaseUrl = '/scale/'
  console.log('进来了', subFrisBaseUrl)

  subFrisDomain = 'https://192.168.30.177:8085/'
} else if (process.env.VUE_APP_ENV === 'qa') {
  subFrisBaseUrl = 'https://subfris.rezkonn.com/dev/'

  subFrisDomain = 'https://qafris.fftai.com/subFris/'
} else if (process.env.VUE_APP_ENV === 'uat') {
  subFrisBaseUrl = 'https://subfris.rezkonn.com/uat/'

  subFrisDomain = 'https://uatfris.fftai.com/subFris/'
} else if (process.env.VUE_APP_ENV === 'prod') {
  subFrisBaseUrl = 'https://subfris.rezkonn.com/prod/'

  subFrisDomain = 'https://fris.rezkonn.com/subFris/'
} else {
  subFrisBaseUrl = 'https://subfris.rezkonn.com/dev/'

  subFrisDomain = 'https://qafris.fftai.com/subFris/'
  console.log('进来了2', subFrisBaseUrl)
}

// console.log('进来了7777', subFrisBaseUrl)

export {
  frisBaseUrl,
  dasBaseUrl,
  dasClient,
  MQUrl,
  frisUrlPrefix,
  subFrisBaseUrl,
  FIRS_ASSETS_PREFIX,
  subFrisDomain,
}
