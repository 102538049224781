
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FrDrawerContent',
  props: {
    title: {
      type: String,
      required: true
    },
    props: {
      type: Object
    },
    events: {
      type: Object
    },
    visible: {
      type: Boolean
    },
    customClass: {
      type: String
    },
    closeOnClickModal: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['update:visible'],
  setup (props, context) {

    const closeHandle = () => {
      context.emit('update:visible', false)
    }

    return {
      closeHandle
    }
  }
})
