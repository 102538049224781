<template>
  <el-select
    :key="props.list.length" 
    ref="selectRef"
    :multiple="multiple"
    filterable
    remote
    :model-value="modelActive"
    :remote-method="handleQuerySearchAsync"
    :placeholder="computedPlaceholder"
    :loading="loading"
    :suffix-transition="false"
    no-match-text=""
    no-data-text=""
    v-bind="props.selectProps"
    v-on="selectEvents"
    @change="handleChange"
  >
    <template #empty>
      <div
        class="item-row flex flex-row justify-between items-center empty-box"
        @click.stop="handlePromptClick"
      >
        <div
          class="empty"
          style="color: #999"
        >
          {{ emptyTextName }}
        </div>
        <div
          class="add-item"
          :style="`color: ${emptyAddBtnColor}`"
        >
          {{ emptyAddBtnName }}
        </div>
      </div>
    </template>
    <el-option
      v-for="item in _list"
      :key="item && item[props.list.value]"
      :label="item && item[props.list.label]"
      :value="isReturnObject ? (item && item) : (item && item[props.list.value])"
      :disabled="disabledSelectHandle(item && item[props.list[disabledKeyNmae]])"
    />
  </el-select>
</template>
<script>
import { computed, unref, defineComponent, toRefs, ref, onMounted, defineExpose, inject } from 'vue'
import { elFormKey } from 'element-plus'
import pinyinMatch from 'pinyin-match'
import { cloneDeep, get } from 'lodash-es'

export default defineComponent({
  name: 'FrSelectorComplete',
  props: {
    props: {
      type: Object,
      default: () => ({}),
      require: true
    },
    multiple: {
      type: Object,
      default: () => ({}),
      require: true
    },
    active: {
      type: Array,
      require: true
    },
    disableIdList: {
      type: Array
    },
    isReturnObject: {
      type: Boolean,
      default: false
    },
    action: {
      type: Object
    },
    actionParams: {
      type: Object
    },
    actionQueryName: {
      type: String,
      default: 'name'
    },
    actionDataName: {
      type: String,
      default: 'list'
    },
    emptyTextName: {
      type: String,
      default: '无数据'
    },
    emptyAddBtnName: {
      type: String,
      default: '新增'
    },
    emptyAddBtnColor: {
      type: String,
      default: '#217af9'
    },
    disabledKeyNmae: {
      type: String,
      default: 'value'
    }
  },
  emits: ['update:active', 'handleAdd'],
  setup (props, context) {
    const elForm = toRefs(inject(elFormKey))

    const { active: modelActive, props: selectorProps, action, disableIdList, actionParams, actionQueryName, actionDataName } = toRefs(props)

    const loading = ref(false)

    const _list = ref([])

    const handleQuerySearchAsync = async (queryString) => {
      // if (!queryString) return _list.value = []
      if (selectorProps.value.list.list) {
        _list.value = cloneDeep(selectorProps.value.list.list.filter((item) => {
          return pinyinMatch.match(item[selectorProps.value.list.label], queryString)
        }))
      } else {
        loading.value = true
        try {
          const { data } = await action.value({
            [actionQueryName.value]: queryString,
            ...actionParams.value
          })
          _list.value = get(data, actionDataName.value, [])
        } catch (err) {
          _list.value = []
        } finally {
          loading.value = false
        }
      }
    }

    onMounted(async () => {
      if (action.value) {
        try {
          const { data } = await action.value({
            ...actionParams.value
          })
          _list.value = unref(get(data, actionDataName.value)) || []
          console.log(data)
          console.log(actionDataName.value)
          console.log(get(data, actionDataName.value, []))
        } catch (err) {
          _list.value = []
        } 
      } else if (selectorProps.value.list.list) {
        _list.value = unref(selectorProps.value.list.list) || []
      } else {
        _list.value = []
      }

      // componentSelectProps.value = {
      //   ...useSelectorHook.selectProps,
      //   ...(selectorProps.value.selectProps || { class: 'long' }),
      // }
    })

    // const useSelectorHook = useSelector(_list, selectorProps.value.list.label)

    // 合并属性
    // let componentSelectProps = ref({
    //   ...useSelectorHook.selectProps,
    //   ...(selectorProps.value.selectProps || { class: 'long' }),
    // })
    
    // watchEffect(() => {
    //   componentSelectProps.value = {
    //     ...useSelectorHook.selectProps,
    //     ...(selectorProps.value.selectProps || { class: 'long' }),
    //   }
    // })

    // const originChange = useSelectorHook.chooseActive

    // useSelectorHook.selectEvents = {
    //   ...useSelectorHook.selectEvents,
    //   ...selectorProps.value.selectEvents,
    //   change: (val) => {
    //     context.emit('update:active', val)
    //     const change = (selectorProps.value.selectEvents && selectorProps.value.selectEvents.change) || originChange
    //     change(val, unref(selectorProps.value.list.list))
    //     useSelectorHook.clearFilterStr()
    //   }
    // }
    const handleChange = (val) => {
      context.emit('update:active', val)
    }

    const isDisabled = computed(() => {
      const customDisabled = selectorProps.value?.selectProps?.disabled
      if (customDisabled === true || customDisabled === false) {
        return customDisabled
      }
      return elForm && elForm.disabled && elForm.disabled.value || false
    })


    const computedPlaceholder = computed(() => {
      const placeholder =  '请选择' + (selectorProps.value?.label || '')
      return isDisabled.value ? '-' : placeholder
    })

    const disabledSelectHandle = (id) => {
      if (disableIdList.value && disableIdList.value.length) {
        return disableIdList.value.some(item => item === id)
      }
      return false
    }

    const selectRef = ref()

    const handlePromptClick = () => {
      const query = selectRef.value.query
      context.emit('handleAdd', query)
    }

    defineExpose({
      handleQuerySearchAsync
    })

    return {
      computedPlaceholder,
      selectRef,
      modelActive,
      handlePromptClick,
      _list,
      // ...useSelectorHook,
      handleQuerySearchAsync,
      // componentSelectProps
      disabledSelectHandle,
      handleChange
    }
  }
})

</script>
<style lang="scss">
  .empty-box {
    font-size: 14px;
    line-height: 20px;
    padding:16px;
    cursor: pointer;
  }
</style>