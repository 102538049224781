
import { defineComponent, PropType } from 'vue'

export interface IFrisRadioList {
  label: string
  value: string | number
}

export interface IFrisRadioProps {
  list: IFrisRadioList[],
  active: string | number
}

export default defineComponent({
  name: 'FrRadio',
  props: {
    list: {
      type: Array as PropType<IFrisRadioList[]>,
      required: true
    },
    active: {
      type: [String, Number],
      default: ''
    },
    disabled: { type: Boolean }
  },
  emits: ['update:active'],
  setup (props: IFrisRadioProps, context) {
    const changeActive = (item: string | number) => {
      context.emit('update:active', item)
    }

    return {
      changeActive
    }
  }
})
