
import frisServer, { IFrisBaseResponse } from '../utils/frisServer'
import { AxiosResponse } from 'axios'
import { IFrisListResponse } from './api'
import store from '@/store'

export interface IFrisOutPatientData {
  age: string
  attendingDoctor: unknown
  attendingDoctorId: unknown
  costType: number
  createTime: string
  hospitalNumber: string
  identityNumber: string
  name: string
  office: unknown
  outPatientStatus: number
  patientId: string
  payStatus: number
  sex: string
  socialSecurityNumber: unknown
  status: string
  treatmentId: string
  type: unknown
}

export function fetchOutpatientList (data?: { page: number, limit: number }): Promise<AxiosResponse<IFrisListResponse<IFrisOutPatientData>>> {
  return frisServer({
    url: '/sys/patient/queryOutPatientPage',
    method: 'post',
    data: {
      page: 1,
      limit: 10,
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}

export function fetchMyOutpatientList (data?: { page: number, limit: number }): Promise<AxiosResponse<IFrisListResponse<IFrisOutPatientData>>> {
  return frisServer({
    url: '/sys/patient/queryVisitDetailsPage',
    method: 'post',
    data: {
      page: 1,
      limit: 10,
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}

export function saveOutpatient (data: any) {
  return frisServer({
    url: '/sys/patient/saveOutPatient',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}

export interface IFrisOutpatientTreatmentInfo {
  allergicSpecificHistory?: string
  assistDisease: unknown
  assistDiseaseId?: string
  assistExamination: unknown
  attendingDoctor?: string
  attendingDoctorId?: string
  bedNumber: unknown
  costType: unknown
  createTime: string
  createUser: string
  createUserId: string
  diseaseHistory: unknown
  diseaseId?: string
  endDate: unknown
  endTime: unknown
  endUser: unknown
  functional: unknown
  functioningId: unknown
  handle: unknown
  headNurse: unknown
  hisPatientId: unknown
  hisTreatId: unknown
  hospitalNumber: string
  id: string
  mainSuit: unknown
  medicalOrder: unknown
  nursingLevel: unknown
  office: unknown
  organizationCode: unknown
  organizationId: number
  outPatient: number
  outPatientStatus: number
  part: unknown
  patientId: string
  patientName: unknown
  payStatus: number
  physicalExamination: unknown
  recoveryProjects: unknown
  roomNumber: unknown
  secondaryDisease: unknown
  secondaryDiseaseId?: string
  snapShot: string
  sourceId: number
  specialRemarks: unknown
  startDate: string
  startTime: unknown
  status: number
  type: unknown
  updateTime: string
  updateUserId: unknown
}

export interface IFrisOutpatientInfo {
  address: unknown
  age: number
  birthday: string
  checkColumn: unknown
  createTime: string
  createUser: string
  createUserId: string
  doctorIds: unknown
  doctorIdsName: unknown
  email: unknown
  headImage: unknown
  height: number
  hisId: string
  id: string
  identity: number
  identityNumber: string
  identityType: number
  isAuto: unknown
  isChecked: number
  linkman: unknown
  linkmanPhone: unknown
  marriage: number
  name: string
  nationality: string
  needOperate: number
  office: unknown
  onlineId: unknown
  organization: unknown
  organizationId: number
  password: unknown
  phone: unknown
  profession: string
  recoveryProjects: unknown
  relation: string
  resourceSys: unknown
  roleIds: unknown
  sex: string
  socialSecurityNumber: unknown
  sourceId: unknown
  sync: number
  sysUpdateTime: string
  treatment: IFrisOutpatientTreatmentInfo
  treatmentId: unknown
  updateTime: string
  updateUser: string
  weight: number
}

export interface IFrisOutpatientInfoResponse extends IFrisBaseResponse {
  patient: IFrisOutpatientInfo
  threadId: string
}

export function fetchOutpatientInfo (id: number | string): Promise<AxiosResponse<IFrisOutpatientInfoResponse>> {
  return frisServer({
    url: `/sys/patient/outPatient/${id}`,
    method: 'get'
  })
}

export function fetchOutpatientRecipeDetail (patientId: string, treatmentId: string): Promise<AxiosResponse<IFrisOutpatientInfoResponse>> {
  return frisServer({
    url: `/sys/treatment/queryRecipeDetail`,
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      patientId,
      treatmentId,
      type: 1
    }
  })
}

export interface IFrisOutpatientRegisterParams {
  attendingDoctor: string
  attendingDoctorId: string
  costType: string
  id: string
  office: string
}

export function registerOutPatient (params: IFrisOutpatientRegisterParams) {
  return frisServer({
    url: `/sys/patient/registerOutPatient`,
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...params
    }
  })
}

export function updateOutpatient (data: any) {
  return frisServer({
    url: '/sys/patient/updateOutPatient',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}

export function cancelRegister (id: number | string) {
  return frisServer({
    url: '/sys/patient/cancelRegister',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      id
    }
  })
}

export interface IFrisPaymentData {
  assessId: unknown
  assessTime: unknown
  assessUser: unknown
  attendingDoctor: unknown
  code: unknown
  comment: unknown
  content: unknown
  contentType: unknown
  count: number
  createTime: unknown
  createUser: unknown
  createUserId: unknown
  dailyAmount: unknown
  duration: unknown
  endDate: unknown
  endUser: unknown
  endUserId: unknown
  equipmentId: unknown
  equipmentName: unknown
  hisItemNo: unknown
  hisOrderNo: unknown
  hisTreatId: unknown
  id: string
  methodId: unknown
  methodStr: unknown
  money: number
  name: string
  office: unknown
  organizationId: unknown
  part: unknown
  patientId: string
  patientName: unknown
  patientType: unknown
  payStatus: number
  postSmallType: unknown
  referralNum: number
  remark: unknown
  residue: unknown
  scores: unknown
  shortLong: unknown
  status: unknown
  templateId: unknown
  therapist: unknown
  therapistId: unknown
  time: string
  total: number
  totalAmount: unknown
  totalMoney: number
  trainee: unknown
  treated: unknown
  treatmentId: string
  type: unknown
  unit: string
  unitName: unknown
  unitPrice: number
  updateTime: unknown
  workDays: unknown
}

export interface IFrisPaymentResponse extends IFrisBaseResponse {
  projects: IFrisPaymentData[]
  threadId: string
}

export function fetchPaymentList (id: string): Promise<AxiosResponse<IFrisPaymentResponse>> {
  return frisServer({
    url: '/sys/recoveryproject/getNeedPayList',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      id
    }
  })
}

export function payProject (id: string, recoveryProjects: { id: string }[]): Promise<AxiosResponse<IFrisPaymentResponse>> {
  return frisServer({
    url: '/sys/recoveryproject/pay',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      id,
      recoveryProjects
    }
  })
}

export function saveCase (data: any) {
  return frisServer({
    url: '/sys/patient/saveCase',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}

export function saveCaseTemplate (data: any) {
  return frisServer({
    url: '/sys/casetemplate/save',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}

export interface IFrisFetchCaseTemplateParam {
  name?: string
  page?: number
  limit?: number
}

export interface IFrisCaseTemplateData {
  allergicSpecificHistory: unknown
  assistDisease: unknown
  assistDiseaseId: unknown
  assistExamination: string
  createTime: unknown
  createUser: unknown
  createUserId: unknown
  diseaseHistory: string
  diseaseId: unknown
  duplicateName: number
  functional: unknown
  functioningId: unknown
  handle: string
  id: number
  mainSuit: string
  medicalOrder: string
  name: string
  organizationId: number
  physicalExamination: string
  secondaryDisease: unknown
  secondaryDiseaseId: unknown
  type: unknown
  updateTime: unknown
  updateUser: unknown
  updateUserId: unknown
}

export function fetchCaseTemplate (data: IFrisFetchCaseTemplateParam): Promise<AxiosResponse<IFrisListResponse<IFrisCaseTemplateData>>>  {
  return frisServer({
    url: '/sys/casetemplate/list',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}

export interface IFrisRecoveryProjectSaveData {
  count: number
  id: number | string
  money: number
  name: string
  organizationId: string
  packageId?: number
  postSmallType?: number
  templateId: number
  time: string
  total: number
  totalAmount: number
  totalMoney: number
  type: number
  unit: number
  unitPrice: number
  unitShow?: string
}

export interface IFrisOutPatientRecipe {
  allergicSpecificHistory: string
  attendingDoctor: string
  id: string
  organizationId?: string
  patientId: string
  patientName: string
  patientType: unknown
  recoveryProjects: IFrisRecoveryProjectSaveData[]
}

export function saveRecipe (data: IFrisOutPatientRecipe) {
  return frisServer({
    url: '/sys/treatment/savRecipe',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}

export interface IFrisPrescriptionTemplateData {
  createTime: string
  createUserId: string
  duplicateName: number
  id: number
  name: string
  organizationId: number
  packageDetails: IFrisRecoveryProjectSaveData[]
  updateTime?: string
  updateUserId?: string
}

export interface IFrisFetchPrescriptionTemplateParam {
  name?: string
  page?: number
  limit?: number
}

export function fetchPrescriptionTemplateList (data: IFrisFetchPrescriptionTemplateParam): Promise<AxiosResponse<IFrisListResponse<IFrisPrescriptionTemplateData>>> {
  return frisServer({
    url: '/sys/treatPackage/list',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}

export interface IFrisRecipeDetailProject {
  assessId: unknown
  assessTime: unknown
  assessUser: unknown
  attendingDoctor: string
  code: unknown
  comment: unknown
  content: unknown
  contentType: unknown
  count: number
  createTime: string
  createUser: string
  createUserId: string
  dailyAmount: unknown
  duration: unknown
  endDate: unknown
  endUser: unknown
  endUserId: unknown
  equipmentId: unknown
  equipmentName: unknown
  hisItemNo: unknown
  hisOrderNo: unknown
  hisTreatId: unknown
  id: string
  methodId: unknown
  methodStr: unknown
  money: number
  name: string
  office: unknown
  organizationId: number
  part: unknown
  patientId: string
  patientName: string
  patientType: unknown
  payStatus: number
  postSmallType: unknown
  referralNum: number
  remark: unknown
  residue: unknown
  scores: unknown
  shortLong: number
  status: number
  templateId: string
  therapist: unknown
  therapistId: unknown
  time: string
  total: number
  totalAmount: number
  totalMoney: number
  trainee: unknown
  treated: number
  treatmentId: string
  type: number
  unit: string
  unitName: unknown
  unitPrice: number
  updateTime: unknown
  workDays: unknown
}
export interface IFrisRecipeDetailPatient {
  address: string
  age: number
  birthday: string
  checkColumn: unknown
  createTime: string
  createUser: string
  createUserId: string
  doctorIds: unknown
  doctorIdsName: unknown
  email: string
  headImage: unknown
  height: number
  hisId: unknown
  id: string
  identity: number
  identityNumber: string
  identityType: number
  isAuto: unknown
  isChecked: number
  linkman: string
  linkmanPhone: string
  marriage: number
  name: string
  nationality: string
  needOperate: number
  office: unknown
  onlineId: unknown
  organization: unknown
  organizationId: number
  password: unknown
  phone: string
  profession: string
  recoveryProjects: IFrisRecipeDetailProject[]
  relation: string
  resourceSys: unknown
  roleIds: unknown
  sex: string
  socialSecurityNumber: string
  sourceId: unknown
  sync: number
  sysUpdateTime: string
  treatment: IFrisOutpatientTreatmentInfo
  treatmentId: unknown
  updateTime: string
  updateUser: string
  weight: number
}

export interface IFrisRecipeDetailResponse extends IFrisBaseResponse {
  threadId: string
  visitTime: string
  patient: IFrisRecipeDetailPatient
}

export function fetchRecipeDetail (patientId: string, treatmentId: string): Promise<AxiosResponse<IFrisRecipeDetailResponse>> {
  return frisServer({
    url: '/sys/treatment/queryRecipeDetail',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      type: 2,
      patientId,
      treatmentId
    }
  })
}

export function savePrescriptionTemplate (data: { duplicateName: number, name: string, packageDetails: Partial<IFrisRecoveryProjectSaveData>[] }): Promise<AxiosResponse<IFrisRecipeDetailResponse>> {
  return frisServer({
    url: '/sys/treatPackage/save',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}