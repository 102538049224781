
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'FrTips',
  props: {
    type: { type: String, default: 'default' },
    content: { type: String }
  },
  setup () {
    const isClosed = ref(false)

    return {
      isClosed
    }
  }
})
