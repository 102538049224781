
import frisServer, { IFrisBaseResponse } from '../utils/frisServer'
import { AxiosResponse } from 'axios'
import store from '@/store/index'
import { IFrisListResponse } from './api'

export interface IFrisEmailInfo {
  checkTime: unknown
  content: string
  createTime: string
  files: unknown
  id: number
  isEncrypt: number
  isRead: number
  isUrgent: number
  organizationId: number
  recipient: string
  recipientName: string
  recipients: unknown
  sender: string
  senderName: string
  title: string
}
export interface IFetchEmailListParams {
  page: number
  limit: number
  title: string
  recipient: string
  startTime: string
  endTime: string
  organizationId: number
}

export function fetchEmailList (data?: Partial<IFetchEmailListParams>): Promise<AxiosResponse<IFrisListResponse<IFrisEmailInfo>>> {
  return frisServer({
    url: '/sys/message/list',
    method: 'get',
    params: {
      page: 1,
      limit: 10,
      title: '',
      recipient: store.getters['user/getUserId'],
      startTime: '', // ‘0’是我的患者，‘1’是全部患者
      endTime:'',
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}

export function signEmailRead (data: number[]): Promise<AxiosResponse<IFrisBaseResponse>> {
  return frisServer({
    url: '/sys/message/updateSign',
    method: 'post',
    data: data
  })
}

export function deleteEmailRead (data: number[]): Promise<AxiosResponse<IFrisBaseResponse>> {
  return frisServer({
    url: '/sys/message/recycleDelete',
    method: 'post',
    data: data
  })
}


export interface IFrisEmailFileData {
  createTime: string
  createUser: string
  id: number
  mode: number
  modeId: number
  name: unknown
  path: string
  type: number
  suffix?: string
}

export interface IFrisEmailData extends IFrisEmailFileData {
  checkTime: unknown
  content: string
  createTime: string
  files: IFrisEmailFileData[]
  id: number
  isEncrypt: number
  isRead: number
  isUrgent: number
  organizationId: number
  recipient: string
  recipientName: string
  recipients: unknown
  sender: string
  senderName: string
  title: string
}

export interface IFrisEmailDataResponse extends IFrisBaseResponse {
  message: IFrisEmailData
}

export function emailInfo (data: number): Promise<AxiosResponse<IFrisEmailDataResponse>> {
  return frisServer({
    url: `/sys/message/info/${data}`,
    method: 'get'
  })
}

export interface IFrisSendFiles {
  name:string
  path:string
  status:string
  uid:number
}
export interface IFrisSendRec {
  id:string
  name:string
}
export interface IFrisSendEmailData {
  content: string
  files: File[]
  recipients: IFrisSendRec[]
  sender: string
  senderName: string
  title: string
}

export function emailSave (data: Partial<IFrisSendEmailData>): Promise<AxiosResponse<IFrisBaseResponse>> {
  return frisServer({
    url: '/sys/message/save',
    method: 'post',
    data: {
      content: '',
      files: [],
      recipients: [],
      sender: '',
      senderName: '',
      title: '',
      organizationId: store.getters['app/getCurrentOrganizationId'],
      ...data
    }
  })
}


export interface IFrisRecipientsDoctor {
  userId: string
  name: string
  typeKey?: string
}
export interface IFrisDoctorListResponse extends IFrisBaseResponse {
  doctor: IFrisRecipientsDoctor[]
}
export function fetchRecipientsDoctor (): Promise<AxiosResponse<IFrisDoctorListResponse>> {
  return frisServer({
    url: '/sys/user/getDoctor',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId']
    }
  })
}

export interface IFrisUnreadResponse extends IFrisBaseResponse {
  unReadMessageNum: number
  threadId: string
}

export function fetchEmailUnread (): Promise<AxiosResponse<IFrisUnreadResponse>> {
  return frisServer({
    url: '/sys/message/countUnreadMessage',
    method: 'post',
    data: {
      organizationId: store.getters['app/getCurrentOrganizationId'],
      recipient: store.state.user.userId
    }
  })
}