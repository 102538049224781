
import { defineComponent, inject, unref, computed } from 'vue'
export default defineComponent({
  name: 'FrFormItem',
  props: {
    content: {
      type: [String, Number],
      default: ''
    },
    mode: {
      type: String,
      default: undefined
    }
  },
  setup (props) {
    const useMode = computed(() => {
      const injectMode = unref(inject('frFormItemType'))
      return props.mode || injectMode || 'form'
    })
    return {
      useMode
    }
  }
})
