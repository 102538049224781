import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb03b7aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fr-form-item" }
const _hoisted_2 = {
  key: 0,
  class: "fr-form-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.useMode === 'form')
      ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
      : _createCommentVNode("", true),
    (_ctx.useMode === 'show')
      ? _renderSlot(_ctx.$slots, "show", { key: 1 }, () => [
          (_ctx.useMode === 'show')
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.content || '-'), 1))
            : _createCommentVNode("", true)
        ], true)
      : _createCommentVNode("", true)
  ]))
}