
import { computed, defineComponent, ref, Ref, toRefs } from "vue"
import { ElMessage } from 'element-plus'
import { showErrMsg } from '@/utils/util'
import { fetchVerCode, IFrisVerCodeData } from '@/api/system'
import { getVerificationCode } from '@/api/app'
import { validateInfo } from '@/utils/form'

export default defineComponent({
  props: {
    phoneOrEmail: {
      type: Object,
      required: true
    },
    cooldownTime: {
      type: Number,
      default: 100 * 1000,
    },
  },
  emits: ['inputVerCode', 'sendCodeWarn'],
  setup (props, context) {
    const { phoneOrEmail, cooldownTime } = toRefs(props)
    const codeLoading = ref(false)
    const getCodeStaus = ref(1)
    const setTimtEndtime = ref(0)
    const downTime = ref(0)
    const times = ref()
    const verCode = ref('')
    const paramsCode = ref({}) as Ref<IFrisVerCodeData>
    const canSendCode = computed(() => {
      let value = phoneOrEmail.value.mobile || phoneOrEmail.value.phone || phoneOrEmail.value.email
      if (!value) {
        return false
      }
      const isDontMatchPhone = validateInfo.phone.some(item => !item.pattern.test(value))
      const isDontMatchEmail = validateInfo.email.some(item => !item.pattern.test(value))
      if (isDontMatchEmail && isDontMatchPhone) {
        return false
      }
      return true
    })

    const getVerCode = async (codeNum: number) => {
      if (!canSendCode.value && codeLoading.value) return
      if (phoneOrEmail.value) {
         if (phoneOrEmail.value.mobile) {
          // 登录时判断是邮箱还是手机号
          const isNotMatchPhone = validateInfo.phone.some(item => !item.pattern.test(phoneOrEmail.value.mobile))
          if (isNotMatchPhone) {
            paramsCode.value.type = 1
            paramsCode.value.address = phoneOrEmail.value.mobile
          } else {
            paramsCode.value.type = 2
            paramsCode.value.address = phoneOrEmail.value.mobile
          }
        } else if (phoneOrEmail.value.phone) {
          paramsCode.value.type = 2
          paramsCode.value.address = phoneOrEmail.value.phone
        } else if (phoneOrEmail.value.email) {
          paramsCode.value.type = 1
          paramsCode.value.address = phoneOrEmail.value.email
        } else {
          return
        }
      }
      if (!paramsCode.value || !paramsCode.value.type || !paramsCode.value.address) return ElMessage({ message: "获取参数有误", showClose: true, type: 'warning' })

      try {
        let resultData
        codeLoading.value = true
        if (phoneOrEmail.value.mobile) {
          // 登录
          let { data } = await getVerificationCode(paramsCode.value)
          resultData = data
        } else {
          const { data } = await fetchVerCode(paramsCode.value)
          resultData = data
        }
        codeLoading.value = false
        if (resultData.code === 0) {
          const nowtime = new Date().getTime()
          setTimtEndtime.value = nowtime + cooldownTime.value  // 定时100秒
          if (codeNum === 1) {
            getCodeStaus.value = 2
            countDown()
          } else if (codeNum === 3) {
            window.clearTimeout(times.value)
            countDown()
            getCodeStaus.value = 2
          }
          ElMessage({ message: "验证码已发送", showClose: true, type: 'success' })
          context.emit('sendCodeWarn', '')
        } else {
          if (phoneOrEmail.value.mobile) {
            // 登录提示区分
            context.emit('sendCodeWarn', resultData.msg)
          } else {
            ElMessage({ message: resultData.msg, showClose: true, type: 'error' })
          }
        }

      } catch (err: any) {
        codeLoading.value = false
        if (phoneOrEmail.value.mobile) {
          // 登录提示区分
          context.emit('sendCodeWarn', err.data.msg)
        } else {
          showErrMsg(err, '获取验证码')
        }
      }
    }

    const countDown = () => {  // 验证码倒计时
      let nowtime = new Date().getTime()
      const num = setTimtEndtime.value - nowtime
      downTime.value = Math.ceil(num / 1000)

      if (downTime.value <= 0) {
        getCodeStaus.value = 3
        window.clearTimeout(times.value)  // 清除验证码的定时任务
        return
      }
      times.value = setTimeout(() => {
        countDown()
      }, 1000)
    }

    const returnVerCode = () => {
      context.emit('inputVerCode', verCode.value, paramsCode.value.type)
    }

    return {
      codeLoading,
      setTimtEndtime,
      verCode,
      getCodeStaus,
      downTime,
      getVerCode,
      returnVerCode,
      canSendCode
    }
  }
})
