import frisServer from '../utils/frisServer';
import dasServer from '../utils/dasServer';
export function loginRequest(params) {
  return frisServer({
    url: '/sys/login',
    method: 'post',
    data: { ...params
    }
  });
}
export function dasLoginRequest(params) {
  return dasServer({
    url: `/sys/login?email=${params.email}&phone=${params.phone}&password=${params.password}&rememberMe=true`,
    method: 'post',
    data: { ...params
    },
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    }
  });
}
export function logout() {
  return frisServer({
    url: `/sys/logout`,
    method: 'post',
    data: {}
  });
}
export function sendResetPasswordCode(data) {
  return frisServer({
    url: '/sys/sendResetPasswordCode',
    method: 'post',
    data
  });
}
export function resetPassword(data) {
  return frisServer({
    url: '/sys/resetPassword',
    method: 'post',
    data
  });
} // 驾驶舱数据关联

export function dasSelectUserPlaces(token) {
  return dasServer({
    url: '/place/selectUserPlaces',
    method: 'get',
    params: {
      token
    },
    headers: {
      'content-type': 'application/json;charset=UTF-8'
    }
  });
}