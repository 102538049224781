import { hasPermission } from '@/utils/permission'
import store from './store'
export const action = {
  mounted: function (el: any, binding: any) {
    function removeNode (el: any) {
      // 直接删除会导致虚拟dom出问题
      if (el) {
        el.style.display = 'none'
      }
      // el.parentNode && el.parentNode.removeChild(el) || (el.style.display = 'none')
    }
    const value = binding.value
    const isAny = binding.modifiers.any
    if (!hasPermission(value, store.state.user.permissions, isAny ? 'any' : undefined)) {
      removeNode(el)
    }
  }
}