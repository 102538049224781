export function exportVersionInfo () {
  try {
    exportBuildDate()
    exportGitInfo()
  } finally {
    // ignore
  }
}

function exportBuildDate () {
    // eslint-disable-next-line
    // @ts-ignore
    console.log(`🚀 build on ` + BUILD_DATE)
}

function exportGitInfo () {
  // eslint-disable-next-line
  // @ts-ignore
  console.log(`📶 Git Refs: ` + GIT_REFS)
  // eslint-disable-next-line
  // @ts-ignore
  console.log(`💬 Git Message ` + GIT_MESSAGE)
  // eslint-disable-next-line
  // @ts-ignore
  console.log(`✅ Git Hash ` + GIT_HASH)
}